import React, { useEffect, useState, useLayoutEffect } from 'react';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography, Avatar, ListItemIcon, Divider, Menu, IconButton,  CircularProgress, Modal, TextField, Button } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SecurityRoundedIcon from '@material-ui/icons/SecurityRounded';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';

import { selectToken } from '../features/externalState/externalSlice';


function getModalStyle() {
  const top = 30;
  const left = 30;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',

  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    justifyContent: 'center',
    width: '90%',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: '1px solid #0069ea',
    height: '40px',
    marginBottom: '20px',
    cursor: 'pointer'
  },
  button2: {
    display: 'flex',
    alignItems: 'center',
   marginLeft: 'auto',
    justifyContent: 'center',
    width: '20%',
    backgroundColor: '#0069ea',
    borderRadius: '4px',
    border: 'none',
    height: '50px',
   // marginBottom: '20px',
    cursor: 'pointer'
  },
  paper: {
    position: 'absolute',
    width: '30%',
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
  },
}));

export default function AuthSelect({ app, applabel, icon, custombutton, node, user, tenant, value, sendData, saveNode, sendAuth, sendLoggedOutToAuthorizer, breakpoint }) {
  const classes = useStyles();
  const [auth, setAuth] = useState('');
  const [authname, setAuthName] = useState(node.data.authname)
  
  // const [authname1, setAuthName] = useState('')
  const [auths, setAuths] = useState('');
  const [name, setName] = useState();
  const [newauth, setNewAuth] = useState();
  // const credentialId = useSelector((state) => state.credentialid.credentialid);
  // const tokenid = useSelector((state) => state.tokenid.tokenid);
  const tokenid = useSelector(selectToken)
  //console.log('custom button is: ', custombutton)
  //console.log('node data custombutton is: ', node.data.custombutton)


  useEffect(() => {
    handleLoad();
    // console.log('new auth hit from useEffect')
    // console.log('auths are: ', auths)
    
    // console.log('number of auths: ',number);
    if (auths.length > 0) {
      var number = auths.length - 1
      
     // console.log('newauth now exists')
      setAuth(auths ? auths[number].app + " - " + auths[number].name + '#' + number + 1 : null)
      // node.data.authname = auths ? auths.slice(-1)[0].app + " - " + auths.slice(-1)[0].name + '#' + number + 1 : null;
     // console.log('newauth 68: ', auths ? auths[number].app + " - " + auths[number].name + '#' + number + 1 : null)
      sendAuth(auths ? auths[number].app + " - " + auths[number].name + '#' + number + 1 : null);
      // saveNode(node);
    }
  }, [newauth])

 /*  useEffect(() => {
    if (name) {
      var configsh = {
        method: 'post',
        url: 'https://backend.workload.co/api/tenant/' + tenant + '/connection',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenid
        },
        data: {
          data: {
            app: applabel,
            login: name,
            status: 'OK'
          }
        }
      };
      axios(configsh).then(function (response) {
        console.log('data sent to scaffold is ,', configsh);
        console.log('response from scaffold ', response.data);
      })
    }
  }, [name]) */

  const handleChange = (event) => {
    setAuth(event.target.value);
    // node.data.authname = event.target.value;
    //console.log('newauth 100: ', event.target.value)
    sendAuth(event.target.value);

    // saveNode(node);
   // console.log('setAuth event is ', event.target.value)
    mixpanel.track('Auth Selected', {
      'app': app,
      'user': user
    });
  };

  const handleOId = (item, value) => {

    // dispatch(credentialid({ item: item, value: value }));
    //node.credential = item;
    //node.authname = value;
    // node.data.authname = value;
    // node.data.credential = item;
    // saveNode(node);
   // console.log('newauth 117: ', value)
    sendAuth(value);
    sendData(item);
  };


  const handleLoad = () => {

    var config = {
      method: 'get',
      url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?tenant=' + tenant + '&app=' + applabel,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios(config)
      .then(function (response) {
        //console.log('auths is: ', response.data);
       // console.log('auths lengths: ', response.data.length)
       tenant === null ? setAuths([]) : setAuths(response.data);

      }, [auths], console.log('auths are: ', auths))

      .catch(function (error) {
        console.log(error);
      });
  };

  let windowObjectReference = null;
  let previousUrl = null;


  const addAuth = (url, name) => {
    if (!user) {
      console.log('user is empty')
      sendLoggedOutToAuthorizer(true)
    }
    else {
      // remove any existing event listeners
      window.removeEventListener('message', receiveMessage);

      // window features
      const strWindowFeatures =
        'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

      if (windowObjectReference === null || windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
         or if such pointer exists but the window was closed */
        windowObjectReference = window.open(url, name, strWindowFeatures);
      } else if (previousUrl !== url) {
        /* if the resource to load is different,
         then we load it in the already opened secondary window and then
         we bring such window back on top/in front of its parent window. */
        windowObjectReference = window.open(url, name, strWindowFeatures);
        windowObjectReference.focus();
      } else {
        /* else the window reference must exist and the window
         is not closed; therefore, we can bring it back on top of any other
         window with the focus() method. There would be no need to re-create
         the window or to reload the referenced resource. */
        windowObjectReference.focus();
      }

      // add the listener for receiving a message from the popup
      window.addEventListener('message', event => receiveMessage(event), false);

      // assign the previous URL
      previousUrl = url;
    }
  };

  const receiveMessage = event => {
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    /* if (event.origin !== 'https://ec2-35-81-172-203.us-west-2.compute.amazonaws.com') {
      return;
    } */
    const { data } = event;
    // console.log( document.cookie.replace(/(?:(?:^|.*;s*)token*=s*([^;]*).*$)|^.*$/, '$1') )
    // console.log('popup window data is: '+data);
   // console.log('newauth 195 is: '+JSON.stringify(data))
  
    sendData(data)
    
    //set the selected auth connection to the newly created connection
    var config = {
      method: 'get',
      url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?tenant='+ tenant +'&id=' + data,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios(config)
      .then(function (response) {

        setNewAuth(response.data.oid);
        // console.log(response.data)
       // console.log('newauth 209 is: '+JSON.stringify(response.data.id))
       
        var authname = response.data[0].app + " - " + response.data[0].name;
        handleNewAuth(data, authname)
      }, [newauth])

      .catch(function (error) {
        console.log(error);
      });

     // console.log('newauth is: '+JSON.stringify(newauth))
    // setAuths(newauth);
    // if we trust the sender and the source is our popup
    /* if (data.source === 'success') {
      // get the URL params and redirect to our server to use Passport to auth/login
      const { payload } = data;
     const redirectUrl = `/auth/google/login${payload}`;
      window.location.pathname = redirectUrl;
    } */

  };
  const handleNewAuth = (data, authname) => {
    var config = {
      method: 'get',
      url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?user=' + user + '&app=' + applabel,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setAuths(response.data);
        
        // dispatch(credentialid({ item: data, value: authname }));
        // node.data.credential = data;
        // sendData(node.data.credential)
       // console.log('newauth 249: ', authname)
        setNewAuth(authname);
        
        // console.log('setAuths was hit')
        // node.data.authname = authname;
        //sendAuth(authname);
        // setAuthName(authname);
        // saveNode(node);
        setName(response.data.name)
        
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const spanRef = React.useRef();



  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      width: spanRef.current ? spanRef.current.offsetWidth : 583,
      marginTop: '8px'
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      {...props}
    />
  ));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
 

  const handleChooseClick = () => {
    setAnchorEl(spanRef.current);
  };

  const handleChooseClose = () => {
    setAnchorEl(null);
  };

  const handleMoreClick = (event) => {
    setAnchorEl2(spanRef.current);
  };

  const handleMoreClose = () => {
    setAnchorEl2(null);
  };
  const doDelete = async (e) => {
   
    try {
      let response = await axios.delete(
        "https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?id="+e
);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const [reallyDelete, setReallyDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const handleDeleteConnection = (e) => {
    if (reallyDelete == true && typeof e === 'string') {
      setDeleting(true)
      //do a delete api call to the credentials table using the credential ID in the query params...await resp to finsih
      console.log('credential ID is: ', e)
      doDelete(e)
      setAuthName('')
      saveNode(node)
      setDeleting(false)
      setReallyDelete(false)
      handleLoad()
      handleMoreClose()
    }
  }

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [namechange, setNameChange] = useState()
  const handleNameChange = (e) => {
    //console.log('name is now: ', e)
    setNameChange(e)
  }

  const doSubmit = async () => {
    try {
      let response = await axios.patch(
        "https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?id="+node.data.credential+"&key=name&value="+namechange
);
      console.log(response);
      setNewAuth(response.data.valueUpdated)
      setAuthName(response.data.valueUpdated)
      await handleOId(response.data.credentialId_updated, response.data.valueUpdated)
      setName(response.data.valueUpdated)
      saveNode(node); handleLoad();
     
    } catch (error) {
      console.error(error);
    }
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Edit connection name</h2>
      <p id="simple-modal-description">
      <TextField
      style={{width: '100%'}}
          required
          id="outlined-required"
          label="Name (required)"
          defaultValue={ authname ? authname.split('#')[0] : applabel}
          value={namechange}
          variant="outlined"
          helperText='Enter a name that will help you remember which account this connection belongs to.'
          onChange={(e) => handleNameChange(e.target.value)}
        />
      </p>
      <Button variant='contained' color='primary' style={{width: '100%'}} onClick={()=> {doSubmit(); handleClose()}} >OK</Button>
    </div>
  );

  return (
    <div>
    {auths.length === 0 ? <div>
      
      <br /><div style={{display: 'flex', border:"1px solid silver", borderRadius: '5px', padding: '12px 12px 12px 12px', marginBottom: '12px'}} >
      <div style={{float: 'left',justifyContent: 'left',alignItems: 'center', display: 'flex',marginRight: 'auto', marginTop: '4px'}}>
        <Avatar style={{ width: breakpoint === 'small' ? '24px' : null, height: breakpoint === 'small' ? '24px' : null  }} src={icon} />
      <Typography variant='h6' style={{marginLeft: '18px', color: 'black', fontWeight: 'bold', fontSize: breakpoint !== 'large' ? '16px' : null }} >Connect {applabel}</Typography>
        </div>
        {custombutton ? <div style={{ flex: 1, alignItems: 'center',alignContent: 'center', cursor: 'pointer'}}><img style={{marginLeft: '6px', width: '100%'}} src={custombutton} onClick={() => addAuth('https://editor.workload.co:5000/auth/' + app + '?uid=' + user + '&t=' + tenant)} /></div> 
        : <button size="small" id='new-auth-button' labelId='new-auth-button-label'
        className={classes.button2} color="primary"
        variant="contained"

        onClick={() => addAuth('https://editor.workload.co:5000/auth/' + app + '?uid=' + user + '&t=' + tenant)}
        startIcon={<SecurityRoundedIcon />}><Typography variant='h6' style={{ color: 'white', fontWeight: 'bold', fontSize: breakpoint !== 'large' ? '16px' : null }} >Sign in</Typography></button>}</div>
      <SecurityRoundedIcon style={{ color: 'gray', height: '18px', float: 'left', display: 'block' }} /><Typography style={{ fontSize: '14px', color: 'gray' }}>{applabel} is a secure partner with Workload. <a style={{ color: '#0069ea' }} href="https://workload.co/privacy" target="_blank">Your credentials are encrypted & can be removed at any time</a>. <a style={{ color: '#0069ea' }} href="https://automate.workload.co/connection" >Manage your connected accounts here</a>. </Typography></div>
      :<>
      <Typography><strong>{applabel} account:</strong> (required) </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        
      <div ref={spanRef} style={{display: 'flex', border:"1px solid silver", borderRadius: '5px', padding: '12px 12px 12px 12px', marginBottom: '12px'}} >
      <div style={{float: 'left',justifyContent: 'left',alignItems: 'center', display: 'flex',marginRight: 'auto', marginTop: '4px'}}>
        <Avatar style={{ width: breakpoint === 'small' ? '24px' : null, height: breakpoint === 'small' ? '24px' : null  }} src={icon} />
      <Typography variant='h6' style={{marginLeft: '24px', color: 'black', fontWeight: 'bold', fontSize: breakpoint !== 'large' ? '16px' : null }} >{authname ? authname.split('#')[0] : `Connect ${applabel}`}</Typography>
        </div><button size="small" id='change-auth-button' labelId='change-auth-button-label'
        className={classes.button2} color="primary"
        variant="contained"

        onClick={handleChooseClick}
        startIcon={<SecurityRoundedIcon />}><Typography variant='h6' style={{ color: 'white', fontWeight: 'bold', fontSize: breakpoint !== 'large' ? '16px' : null }} >{authname ? 'Change' : 'Choose'}</Typography></button>
      {authname ?  <IconButton onClick={handleMoreClick} style={{float: 'right', color: 'black'}} aria-label="delete">
  <MoreHorizRoundedIcon />
</IconButton> : null }
</div>
<Menu
  id="simple-menu"
  style={{marginTop: '8px'}}
  anchorEl={anchorEl2}
  keepMounted
  open={Boolean(anchorEl2)}
  onClose={handleMoreClose}
  getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
>
  <MenuItem onClick={() => {handleMoreClose(); handleOpen()}}><ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>Edit connection name</MenuItem>
          <MenuItem onClick={() => {handleMoreClose(); addAuth('https://editor.workload.co:5000/auth/' + app + '?uid=' + user + '&t=' + tenant +'&update=true&c_id='+node.data.credential)}}><ListItemIcon>
            <DescriptionRoundedIcon fontSize="small" />
          </ListItemIcon>Update connection info</MenuItem>
         {reallyDelete === true ? <MenuItem onClick={(e) => {handleDeleteConnection(node.data.credential); setReallyDelete(false)}}>
         {deleting === true ? <CircularProgress size={20} color='secondary' style={{justifyContent: 'center', display: 'flex', alignItems: 'center', margin: '0 auto' }} /> :<ListItemIcon>
            <DeleteRoundedIcon fontSize="small" style={{color: 'red'}} /> 
          </ListItemIcon>}{deleting === true ? null : 'Really delete connection?'}</MenuItem> : 
          <MenuItem onClick={() => setReallyDelete(true)}><ListItemIcon>
          <DeleteRoundedIcon fontSize="small" />
        </ListItemIcon>Delete connection</MenuItem> 
}
</Menu>
        <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleChooseClose}
      >
        <Typography style={{padding: '10px', fontWeight: 700}} variant="h6" >Choose...</Typography>
        <Divider style={{marginBottom: '6px'}} />
          {auths && spanRef.current ? auths.map((item, i) => <MenuItem onClick={() => { handleOId(item._id.$oid, `${item.app}` + ' - ' + `${item.name}` + '#' + i + 1); handleChooseClose(); setAuthName(`${item.app}` + ' - ' + `${item.name}` + '#' + i + 1)}} id={item.id} key={i} value={`${item.app}` + ' - ' + `${item.name}` + '#' + i + 1}> <ListItemIcon style={{ float: 'left', display: 'block'}}>
            <Avatar style={{width: breakpoint === 'small' ? '18px' : '26px', height: breakpoint === 'small' ? '18px' : '26px' }} src={icon} />
          </ListItemIcon><Typography style={{ fontWeight: 'bold', fontSize: '20px',marginLeft: '8px', float:'left', display: 'block'}} >{item.app} - {item.name} #{i + 1}</Typography></MenuItem>) :
            <MenuItem id='nodata' key='nodata' disabled>+ Add a new connection</MenuItem>}<br /><br />
          {auths.length === 0 ? <div>
            <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'bold' }} >Workload needs permission to access your {applabel} account.</Typography>
            <br />{custombutton ? <div style={{ flex: 1, alignItems: 'center',alignContent: 'center', cursor: 'pointer'}}><img style={{marginLeft: '6px', width: '100%'}} src={custombutton} onClick={() => addAuth('https://editor.workload.co:5000/auth/' + app + '?uid=' + user + '&t=' + tenant)} /></div> 
        : <button size="small" id='new-auth-button' labelId='new-auth-button-label'
              className={classes.button} color="primary"
              variant="contained"

              onClick={() => addAuth('https://editor.workload.co:5000/auth/' + app + '?uid=' + user + '&t=' + tenant)}
              startIcon={<SecurityRoundedIcon />}><Avatar style={{ float: 'left', display: 'block', left: breakpoint === 'small' ? 20 : 40, position: 'absolute', width: breakpoint === 'small' ? '24px' : null, height: breakpoint === 'small' ? '24px' : null  }} src={icon} /><Typography variant='h6' style={{ color: 'white', fontWeight: 'bold', fontSize: breakpoint !== 'large' ? '16px' : null }} >Sign in to {applabel}</Typography></button>}
            <SecurityRoundedIcon style={{ color: 'gray', height: '15px', float: 'left', display: 'block' }} /><Typography style={{ fontSize: '14px', color: 'gray' }}>{applabel} is a secure partner with Workload. <a style={{ color: '#0069ea' }} href="https://workload.co/privacy" target="_blank">Your credentials are encrypted & can be removed at any time</a>. <a style={{ color: '#0069ea' }} href="https://automate.workload.co/connection" >Manage your connected accounts here</a>. </Typography></div>
            :
            <div>
             
              <br /><button size="small" id='new-auth-button' labelId='new-auth-button-label'
                className={classes.button} color="primary"
                variant="outlined"
                onClick={() => addAuth('https://editor.workload.co:5000/auth/' + app + '?uid=' + user + '&t=' + tenant)}
                startIcon={<SecurityRoundedIcon />}><Typography style={{ color: '#0069ea', fontSize: breakpoint !== 'large' ? '12px' : null }} >+ Add new {applabel} account</Typography></button>
              </div>
          }
     
         </StyledMenu>
        <FormHelperText><SecurityRoundedIcon style={{ color: 'gray', height: '15px', float: 'left', display: 'block' }} /><Typography style={{ fontSize: '14px', color: 'gray' }}>{applabel} is a secure partner with Workload. <a style={{ color: '#0069ea' }} href="https://workload.co/privacy" target="_blank">Your credentials are encrypted & can be removed at any time</a>. <a style={{ color: '#0069ea' }} href="https://automate.workload.co/connection" >Manage your connected accounts here</a>. </Typography></FormHelperText>
      </FormControl>
</>
        }
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
        </div>
  );
};

