import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DirectionsRunRoundedIcon from '@material-ui/icons/DirectionsRunRounded';
import OfflineBoltRoundedIcon from '@material-ui/icons/OfflineBoltRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';
import DeviceHubRoundedIcon from '@material-ui/icons/DeviceHubRounded';




function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }
  
  function generate(element) {
    return [0, 1, 2,3,4,5,6,7,8,9,10,11,12,13,14].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  }



const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 500,
    maxHeight: 720
  },
});

export default function IconLabelTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab icon={<OfflineBoltRoundedIcon />} label="TRIGGERS" {...a11yProps(0)} />
        <Tab icon={<DirectionsRunRoundedIcon />} label="ACTIONS" {...a11yProps(1)}  />
        <Tab icon={<BuildRoundedIcon />} label="TOOLS" {...a11yProps(2)}/>
      </Tabs>
      <TabPanel value={value} index={0}>
      <div className={classes.demo}>
      
      <List dense={dense}>
        {generate(
          <ListItem>
            <ListItemAvatar>
              <Avatar alt="HubSpot" src="https://www.workload.co/wp-content/uploads/2020/12/hubspot-logo.png"/>
            </ListItemAvatar>
            <ListItemText
              primary="HubSpot"
              secondary={secondary ? 'Triggers when HubSpot events occur' : null}
            />
            <ListItemSecondaryAction>
              <IconButton edge="start" aria-label="Drag to move">
                <DragIndicatorRoundedIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>,
        )}
      </List>
      
    </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className={classes.demo}>
      
      <List dense={dense}>
        {generate(
          
          <ListItem>
            <ListItemAvatar>
              <Avatar alt="Twilio" src="https://www.workload.co/wp-content/uploads/2020/12/twilio-logo.png"/>
            </ListItemAvatar>
            <ListItemText
              primary="Twilio"
              secondary={secondary ? 'Take action in the Twilio App' : null}
            />
            <ListItemSecondaryAction>
              <IconButton edge="start" aria-label="Drag to move">
                <DragIndicatorRoundedIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
         
        )}
      </List>
      
    </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <div className={classes.demo}>
      
      <List dense={dense}>
        {generate(
          
          <ListItem>
            <ListItemAvatar>
              <Avatar alt="IF">
              <DeviceHubRoundedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="IF"
              secondary={secondary ? 'Branch workflow based on conditions' : null}
            />
            <ListItemSecondaryAction>
              <IconButton edge="start" aria-label="Drag to move">
                <DragIndicatorRoundedIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
         
        )}
      </List>
      
    </div>
      </TabPanel>
    </Paper>
  );
}