import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {Card,CardMedia, CircularProgress, IconButton, Input, Typography, Divider, Button, Box, LinearProgress, TextField, Checkbox, RadioGroup, FormControlLabel, Radio, FormGroup, withStyles  } from "@material-ui/core";
import Inspector from 'react-json-inspector';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import axios from 'axios';



const Form = () => {
  function pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? darkColor : lightColor;
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center" style={{border: '1px solid silver', borderRadius: '8px', padding: '12px', marginTop: '4px', marginBottom: '12px'}}>
        <Box width="100%"  mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={65}>
          <Typography variant="body2" color="textSecondary">{progress} of {props.sections}</Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

  const [progress, setProgress] = React.useState(1);

  /* React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []); */

     var pathArray = window.location.pathname.split('/');
    // console.log('pathArray is: ', pathArray)
     var formId = pathArray[2]
     if (!formId) {
      //  console.log('no form id...', formId)
     }
     else {
      //  console.log('form id exists: ', formId)
     }


  const [djangoId, setDjangoId] = useState(null);
  const [loading, setLoading] = useState('initializing');
  const [webhook_url, setWebhookURL] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [scaffoldId, setScaffoldId] = useState(null);
  const [success, setSuccess] = useState()
     const getDjangoWorkflow = () => {
        var config = {
          method: 'get',
          url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap?scaffoldId=' + formId,
          headers: {
            'Content-Type': 'application/json'
          }
    
        };
        axios(config)
          .then(function (response) {
            var datalength = response.data.length;
           // console.log('length is ', datalength)
            if (datalength === 0) {
              //if the scaffoldmapper doesn't return anything, create a fresh workflow
              //console.log('saving new django, then posting to scaffolddjangomapper');
    
              setLoading('loaded');
            }
            else {
              //get the frontendWorkflow using id from the above function and return the workflow
            // console.log('get success to docdb for scaffolddjangomap', response.data);
              var idfromdjango = response.data[0].djangoId;
             // console.log('djangoid from mapper is ', idfromdjango);
              setDjangoId(idfromdjango)
              setScaffoldId(response.data[0].scaffoldId)
              setLoading('loaded');
              var data4 = {id: idfromdjango}
              let config_send = {
                method: 'get',
                url: 'https://editor.workload.co:5000/retrieveForm',
                params: data4
                };
            
                axios(config_send)
                .then((response) => {
              console.log('response: ', response.data)
                setElements(response.data.fields)
                setWebhookURL(response.data.webhook_url)
              }).catch((error) => {
                console.log(error);
                
                });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      useEffect(() => {
        if (loading != 'loaded') {
          getDjangoWorkflow()
         // console.log('getting djangoworkflow')
        } else {
    
        }
      }, [loading])

      const [elements, setElements] = useState()
      //console.log('workflow selector is: ', elements)
      const fields = elements
      const settings = fields?.[0].value
      const questions = fields?.[1].value
      const additional_sections = fields?.[2].value
      const confirmation_page = fields?.[3].value ? fields?.[3].value : false
      const [show_confirmation_page, setShowConfirmationPage] = useState(false)
      const results_page = fields?.[4].value ? fields?.[4].value : false
      const sections = additional_sections ? additional_sections.length +1 : null
      const header_image = settings?.header.image_url ? settings.header.image_url : null
      const settingscolor = settings?.color.theme ? settings.color.theme : '#0069ea' // this can be any color
      const settingsbackgroundcolor = settings?.color.background ? settings.color.background : '#F5F5F5' // this can be any color
      //console.log('fields is: : ', fields)
      //console.log('there are ', sections, 'sections')
      //console.log('settings is: ', settings)
      //console.log('sections is: ', additional_sections)
      //console.log('questions is: ', questions)
      const title = settings?.form.title ? settings.form.title : "Untitled form"
      const description = settings?.form.description ? settings.form.description : ""
     
      const [answers, setAnswers] = useState({})
      const handleSubmit = () => {
        //console.log('submitted answers payload: ', answers)
        //console.log('webhook to submit to is: ', webhook_url)
        let config_send = {
          method: 'post',
          url: 'https://editor.workload.co:5000/submitForm',
          data: {
            webhook_url: webhook_url,
            answers: answers
          }
          };
      
          axios(config_send)
          .then((response) => {
       // console.log('response: ', response.data)
              setSubmitting(false)
              setSuccess(true)
        }).catch((error) => {
          console.log(error);
          setSubmitting(false)
          setSuccess(false)
          setShowConfirmationPage(false)
          });
      }
      const CustomCheckbox = withStyles({
        root: {
          color: '#b0b0b0', // default gray color
          '&$checked': {
            color: settingscolor, // blue color when checked
          },
        },
        checked: {},
      })(Checkbox);

      const CustomRadio = withStyles({
        root: {
          color: '#b0b0b0', // default gray color
          '&$checked': {
            color: settingscolor, // blue color when checked
          },
        },
        checked: {},
      })(Radio);
      const [selectedFiles, setSelectedFiles] = useState([]);
      const [hover, setHover] = useState(false);
      const [uploadProgress, setUploadProgress] = useState({}); // Store upload progress for each file
      const [uploadError, setUploadError] = useState({}); // Store error state for each file
      const progressIntervals = {}; // To store intervals for each file
    
      const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        setSelectedFiles((prev) => [...prev, ...files]);
        files.forEach(uploadFileToS3); // Start the file upload for each file
      };
    
      const handleDragOver = (e) => {
        setHover(true);
        e.preventDefault();
      };
    
      const handleDragLeave = () => {
        setHover(false);
      };
    
      /* const handleFileSelect = (e) => {
        
        const files = Array.from(e.target.files);
        setSelectedFiles((prev) => [...prev, ...files]);
        files.forEach(uploadFileToS3); // Start the file upload for each file
        
      }; */

      const handleFileSelect = (e, question) => {
        const files = Array.from(e.target.files);
        setSelectedFiles((prev) => [...prev, ...files]);
      
        // Iterate through the files and add them to the `answers` object with custom keys
        files.forEach((file, index) => {
          const customKey = `${question.question}_${index + 1}`; // Use question + file index as key
          const fileUrl = `https://pubbuck11111.s3.us-west-2.amazonaws.com/${scaffoldId}/${file.name}`;
          
          setAnswers((prevState) => ({
            ...prevState,
            [customKey]: fileUrl // Store the file URL with the custom key
          }));
      
          // Start the file upload process for each file
          uploadFileToS3(file);
        });
      };
      
    
      const handleRemoveFile = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
      };
    
      const uploadFileToS3 = async (file) => {
        simulateProgress(file.name); // Start simulating progress for the file
    
        try {
          // Step 1: Get a pre-signed URL from your server
          const { data: { url } } = await axios.get('https://odkusrnpx8.execute-api.us-west-2.amazonaws.com/production/apps/uploadFile', {
            params: { filename: file.name, filetype: file.type, folder: scaffoldId }, // Intentionally removed filetype to trigger error
          });
    
          // Step 2: Upload the file to S3 using the pre-signed URL and track progress
          await axios.put(url, file, {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress((prevProgress) => ({
                ...prevProgress,
                [file.name]: percentCompleted,
              }));
    
              // If upload reaches 100%, clear the simulated progress interval
              if (percentCompleted === 100) {
                clearInterval(progressIntervals[file.name]);
              }
            },
          });
    
          // On success, ensure progress is set to 100%
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: 100,
          }));
        } catch (error) {
          console.error('Upload error:', error);
          // Stop progress and mark as error (for both pre-signed URL and actual upload errors)
          setUploadError((prevError) => ({
            ...prevError,
            [file.name]: true, // Mark the file as having an error
          }));
          clearInterval(progressIntervals[file.name]); // Stop the progress interval on error
        }
      };
    
      const simulateProgress = (fileName) => {
        let simulatedProgress = 0;
    
        progressIntervals[fileName] = setInterval(() => {
          if (simulatedProgress >= 95) {
            clearInterval(progressIntervals[fileName]); // Stop at 95%, wait for the actual upload completion
          } else {
            simulatedProgress += 5; // Increment progress by 5% each interval
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [fileName]: simulatedProgress,
            }));
          }
        }, 200); // Increment every 200ms
      };
    
      const BorderLinearProgress = withStyles((theme) => ({
        colorPrimary: {
          backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
          borderRadius: 5,
          backgroundColor: (props) => (props.error ? 'red' : 'green'), // Use red if there's an error
        },
      }))(LinearProgress);
    
      
   
    return (
      elements && settings && questions ? <div style={{backgroundColor: settings.color.background ? settings.color.background : 'whitesmoke', margin: 0, width: '100vw'}}>
        <div style={{display: 'flex', flexDirection: 'column', fontFamily: 'Roboto,RobotoDraft,Helvetica,Arial,sans-serif'}}>
       <div style={{margin: 'auto', maxWidth: '90vw', width: '640px'}}> 
       { header_image ? 
        <Card style={{marginBottom: 12, marginTop: 12, borderRadius: '8px', }}>
          <div style={{height: '162px', width: '100%'}}>
         <div style={{  
              backgroundImage: `url(`+header_image+`)`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              backgroundColor: settingsbackgroundcolor,
              width: '100%',
             
              
          }} />
          </div>
      
        </Card> : null }
        

       { !title && !description ? <Card style={{padding: 24,marginBottom: 12, marginTop: 12, borderTop: '12px solid', borderColor: settingscolor, borderRadius: '8px'}}>
        <div>
        <Typography style={{fontFamily: settings.text_style.header.font ? settings.text_style.header.font : "Roboto,Arial,sans-serif", fontSize: settings.text_style.header.size ? settings.text_style.header.size+"px": "33px", fontWeight: 'bold', lineHeight: 1.25  }}>{title}</Typography>
        <p style={{fontFamily: settings.text_style.text.font ? settings.text_style.text.font : "Roboto,Arial,sans-serif", fontSize: settings.text_style.text.size ? settings.text_style.text.size+"px": "14px", fontWeight: 'normal', color: 'rgb(95,99,104)'  }}>{success === true ? 'Your response has been recorded.' : description}</p>
        </div>{success === true ?  <><div style={{cursor: 'pointer'}} onClick={() => {setSuccess(null); setAnswers({}); setProgress(1)}}><Typography variant="body1" style={{color: '#0069ea',textDecoration: 'underline', fontFamily: settings.text_style.text.font ? settings.text_style.text.font : "Roboto,Arial,sans-serif", fontSize: settings.text_style.text.size ? settings.text_style.text.size+"px": '14px', fontWeight: 'normal'  }}>Submit another response</Typography></div></> :
       <> <Divider style={{margin: '20px 0px 20px 0px'}} />
        <Typography variant="body1" style={{color: 'red', fontFamily: settings.text_style.text.font ? settings.text_style.text.font : "Roboto,Arial,sans-serif", fontSize: settings.text_style.text.size ? settings.text_style.text.size+"px": '12px', fontWeight: 'normal'  }}>* Indicates required question</Typography>
        </>}
        </Card> :
        <Card style={{padding: 24,marginBottom: 12, marginTop: 12, borderTop: '12px solid', borderColor: settingscolor, borderRadius: '8px'}}>
        <div>
        <Typography style={{fontFamily: settings.text_style.header.font ? settings.text_style.header.font : "Roboto,Arial,sans-serif", fontSize: settings.text_style.header.size ? settings.text_style.header.size+"px": "33px", fontWeight: 'bold', lineHeight: 1.25  }}>{title}</Typography>
        <p style={{fontFamily: settings.text_style.text.font ? settings.text_style.text.font : "Roboto,Arial,sans-serif", fontSize: settings.text_style.text.size ? settings.text_style.text.size+"px": "14px", fontWeight: 'normal', color: 'rgb(95,99,104)'  }}>{success === true ? 'Your response has been recorded.' : description}</p>
        { results_page === true && success === true ?  <div style={{marginBottom: '18px'}}><Inspector data={answers} isExpanded={(keypath, query) => true} onClick={(e) => { console.log(e) }} style={{marginBottom: '40px'}} /></div> : null }
        </div>{success === true ?  <><div style={{cursor: 'pointer'}} onClick={() => {setSuccess(null); setAnswers({}); setProgress(1)}}><Typography variant="body1" style={{color: '#0069ea',textDecoration: 'underline', fontFamily: settings.text_style.text.font ? settings.text_style.text.font : "Roboto,Arial,sans-serif", fontSize: settings.text_style.text.size ? settings.text_style.text.size+"px": '14px', fontWeight: 'normal'  }}>Submit another response</Typography></div></> :
       <> <Divider style={{margin: '20px 0px 20px 0px'}} />
        <Typography variant="body1" style={{color: 'red', fontFamily: settings.text_style.text.font ? settings.text_style.text.font : "Roboto,Arial,sans-serif", fontSize: settings.text_style.text.size ? settings.text_style.text.size+"px": '12px', fontWeight: 'normal'  }}>* Indicates required question</Typography>
        </>}
        </Card>
        }

        { additional_sections && additional_sections.length > 0 && success !== true ? <LinearProgressWithLabel value={progress/sections *100} sections={sections} /> : null }
        
        {success === true || show_confirmation_page === true ? null : additional_sections && progress !== 1 ? null : questions ? questions.map((question) =>
        <Card key={'card_'+question.question} style={{padding: 24, marginBottom: 12, borderRadius: '8px'}}>
        <div style={{display: 'flex', marginBottom: '16px'}}>
        <Typography key={'typog_'+question.question} style={{fontFamily: settings.text_style.question.font ? settings.text_style.question.font : "Roboto,Arial,sans-serif", fontSize: settings.text_style.question.size ? settings.text_style.question.size+"px": "18px", fontWeight: 'bold', lineHeight: 1.5  }}>{question.question}<span style={{paddingLeft: '8px', color: 'red'}}>{question.required === true ? '*' : null}</span></Typography>
        </div>
        <div> { question.type === 'input' ?
        <TextField fullWidth variant="outlined" key={'input_'+question.question} placeholder="Your answer" onChange={(e) => setAnswers((prevState) => ({...prevState,[question.question]: e.target.value}))}/>
            : question.type === 'textarea' ?
        <TextField fullWidth variant="outlined" multiline rows={8} key={'input_'+question.question} placeholder="Your answer" onChange={(e) => setAnswers((prevState) => ({...prevState,[question.question]: e.target.value}))}/>
          : question.type === 'checkboxes' ? 
          <FormGroup>
         { question.checkbox_options.map(option => 
           <FormControlLabel
           control={
            <CustomCheckbox 
              name={option} 
              value={option} 
              checked={answers[question.question]?.includes(option) || false}
              onChange={(e) => setAnswers((prevState) => {
                const prevAnswers = prevState[question.question] || [];
                if (e.target.checked) {
                  // Add the option to the array if it's checked
                  return { ...prevState, [question.question]: [...prevAnswers, option] };
                } else {
                  // Remove the option from the array if it's unchecked
                  return { ...prevState, [question.question]: prevAnswers.filter(item => item !== option) };
                }
              })}
            />}
           label={option}
           
         />) }
          </FormGroup>
          : question.type === 'multiple_choice' ? 
          <RadioGroup>
          {question.multiple_choice_options.map(option => 
            <FormControlLabel 
            value={option} 
            control={<CustomRadio />} 
            label={option}
            onChange={(e) => setAnswers((prevState) => ({...prevState,[question.question]: e.target.value}))}/> )}
          </RadioGroup> 
          : question.type === 'file' ?
          <Box>
  <Box
    onDrop={(e) => handleDrop(e, question)}
    onDragOver={handleDragOver}
    onDragLeave={handleDragLeave}
    style={{
      border: '2px dashed #007bff',
      borderRadius: '8px',
      padding: '20px',
      textAlign: 'center',
      cursor: 'pointer',
      backgroundColor: hover ? '#0071ea' : '#f9f9f9',
    }}
  >
    <input
      type="file"
      multiple
      style={{ display: 'none' }}
      id="fileInput"
      onChange={(e) => { 
        handleFileSelect(e, question);
      }}
    />
    <CloudUploadOutlinedIcon style={{ color: 'gray', fontSize: '56px' }} /><br />
    <label htmlFor="fileInput">
      <Button variant="contained" color="primary" style={{ textTransform: 'none' }} component="span">
        Browse
      </Button>
    </label>
    <Typography style={{ color: 'gray' }} variant="h6">
      or drag a file here
    </Typography>
  </Box>

  <Box mt={2}>
    {selectedFiles.map((file, index) => (
      <Box
        key={index}
        style={{
          position: 'relative',
          border: '1px solid grey',
          borderRadius: '8px',
          padding: '10px 20px',
          textAlign: 'center',
          backgroundColor: '#f9f9f9',
          marginTop: '10px',
        }}
      >
        <Box display="flex" alignItems="center">
          <InsertDriveFileOutlinedIcon style={{ marginRight: '8px', color: 'gray' }} />
          <Typography style={{ flex: 1, color: 'gray' }} align="left">{file.name}</Typography>
          <Box>
            <IconButton onClick={() => handleRemoveFile(index)} style={{ marginLeft: 'auto' }}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
        {/* Show progress bar while uploading */}
        {(uploadProgress[file.name] !== undefined || uploadError[file.name]) && (
          <BorderLinearProgress
            variant="determinate"
            value={uploadProgress[file.name] || 0} // Use progress if available, otherwise 0
            error={uploadError[file.name] || false} // Pass error state
            style={{
              height: '8px',
              marginTop: '10px',
              backgroundColor: '#e0e0e0',
              borderRadius: 8,
            }}
          />
        )}
      </Box>
    ))}
  </Box>
</Box>

          :
          null}
        </div>
      
        </Card>
        ) : null}
         {success === true || show_confirmation_page === true ? null : additional_sections && progress >= 2 ? additional_sections[progress -2].section.map((question) =>
        <Card key={'card_'+question.question} style={{padding: 24, marginBottom: 12, borderRadius: '8px'}}>
        <div style={{display: 'flex', marginBottom: '16px'}}>
        <Typography key={'typog_'+question.question} style={{fontFamily: settings.text_style.question.font ? settings.text_style.question.font : "Roboto,Arial,sans-serif", fontSize: settings.text_style.question.size ? settings.text_style.question.size+"px": "18px", fontWeight: 'bold', lineHeight: 1.5  }}>{question.question}<span style={{paddingLeft: '8px', color: 'red'}}>{question.required === true ? '*' : null}</span></Typography>
        </div>
        <div> { question.type === 'input' ?
        <Input key={'input_'+question.question} placeholder="Your answer" style={{width: '50%'}} onChange={(e) => setAnswers((prevState) => ({...prevState,[question.question]: e.target.value}))}/>
            : question.type === 'textarea' ?
        <Input multiline key={'input_'+question.question} placeholder="Your answer" style={{width: '50%'}} onChange={(e) => setAnswers((prevState) => ({...prevState,[question.question]: e.target.value}))}/>
          :  question.type === 'checkboxes' ? 
          <FormGroup>
         { question.checkbox_options.map(option => 
           <FormControlLabel
           control={
            <CustomCheckbox 
              name={option} 
              value={option} 
              checked={answers[question.question]?.includes(option) || false}
              onChange={(e) => setAnswers((prevState) => {
                const prevAnswers = prevState[question.question] || [];
                if (e.target.checked) {
                  // Add the option to the array if it's checked
                  return { ...prevState, [question.question]: [...prevAnswers, option] };
                } else {
                  // Remove the option from the array if it's unchecked
                  return { ...prevState, [question.question]: prevAnswers.filter(item => item !== option) };
                }
              })}
            />}
           label={option}
           
         />) }
          </FormGroup>
          : question.type === 'multiple_choice' ? 
          <RadioGroup>
          {question.multiple_choice_options.map(option => 
            <FormControlLabel 
            value={option} 
            control={<CustomRadio />} 
            label={option}
            onChange={(e) => setAnswers((prevState) => ({...prevState,[question.question]: e.target.value}))}/> )}
          </RadioGroup> 
          : question.type === 'file' ?
          <Box>
  <Box
    onDrop={(e) => handleDrop(e, question)}
    onDragOver={handleDragOver}
    onDragLeave={handleDragLeave}
    style={{
      border: '2px dashed #007bff',
      borderRadius: '8px',
      padding: '20px',
      textAlign: 'center',
      cursor: 'pointer',
      backgroundColor: hover ? '#0071ea' : '#f9f9f9',
    }}
  >
    <input
      type="file"
      multiple
      style={{ display: 'none' }}
      id="fileInput"
      onChange={(e) => { 
        handleFileSelect(e, question);
      }}
    />
    <CloudUploadOutlinedIcon style={{ color: 'gray', fontSize: '56px' }} /><br />
    <label htmlFor="fileInput">
      <Button variant="contained" color="primary" style={{ textTransform: 'none' }} component="span">
        Browse
      </Button>
    </label>
    <Typography style={{ color: 'gray' }} variant="h6">
      or drag a file here
    </Typography>
  </Box>

  <Box mt={2}>
    {selectedFiles.map((file, index) => (
      <Box
        key={index}
        style={{
          position: 'relative',
          border: '1px solid grey',
          borderRadius: '8px',
          padding: '10px 20px',
          textAlign: 'center',
          backgroundColor: '#f9f9f9',
          marginTop: '10px',
        }}
      >
        <Box display="flex" alignItems="center">
          <InsertDriveFileOutlinedIcon style={{ marginRight: '8px', color: 'gray' }} />
          <Typography style={{ flex: 1, color: 'gray' }} align="left">{file.name}</Typography>
          <Box>
            <IconButton onClick={() => handleRemoveFile(index)} style={{ marginLeft: 'auto' }}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
        {/* Show progress bar while uploading */}
        {(uploadProgress[file.name] !== undefined || uploadError[file.name]) && (
          <BorderLinearProgress
            variant="determinate"
            value={uploadProgress[file.name] || 0} // Use progress if available, otherwise 0
            error={uploadError[file.name] || false} // Pass error state
            style={{
              height: '8px',
              marginTop: '10px',
              backgroundColor: '#e0e0e0',
              borderRadius: 8,
            }}
          />
        )}
      </Box>
    ))}
  </Box>
</Box> :
          null}
        </div>
      
        </Card>
        ) : null}

        { show_confirmation_page === true ?  <div style={{marginBottom: '8px'}}><Inspector data={answers} isExpanded={(keypath, query) => true} onClick={(e) => { console.log(e) }} style={{marginBottom: '40px'}} /></div> : null }
        {success === true ? null : questions ? 
        <div style={{marginTop: '8px'}}>
          {additional_sections && progress !== 1 ? <Button disabled={submitting === true ? true : false} variant="contained" style={{display: "block", float: "left", backgroundColor: 'white', textTransform: 'none', padding: '8px 24px', fontSize: '14px', fontFamily: settings.text_style.text.font ? settings.text_style.text.font : '"Google Sans",Roboto,Arial,sans-serif', color: settingscolor, marginRight: '12px'}} onClick={() => {setProgress(progress -1); setShowConfirmationPage(false)}}>Back</Button> : null}
          {additional_sections && progress < sections ? 
          <Button disabled={submitting === true ? true : false} variant="contained" style={{display: "block", float: "left", backgroundColor: 'white', textTransform: 'none', padding: '8px 24px', fontSize: '14px', fontFamily: settings.text_style.text.font ? settings.text_style.text.font : '"Google Sans",Roboto,Arial,sans-serif', color: settingscolor}} onClick={() => setProgress(progress +1)}>Next</Button> : 
          confirmation_page === true && show_confirmation_page === false ?
          <Button disabled={submitting === true ? true : false} variant="contained" style={{display: "block", float: "left", backgroundColor: 'white', textTransform: 'none', padding: '8px 24px', fontSize: '14px', fontFamily: settings.text_style.text.font ? settings.text_style.text.font : '"Google Sans",Roboto,Arial,sans-serif', color: settingscolor}} onClick={() => setShowConfirmationPage(true)}>Next</Button> : 
          show_confirmation_page === true && confirmation_page === true ?
          <Button disabled={submitting === true ? true : false} variant="contained" style={{display: "block", float: "left", backgroundColor: submitting ? 'gray' : settingscolor, textTransform: 'none', padding: '8px 24px', fontSize: '14px', fontFamily: settings.text_style.text.font ? settings.text_style.text.font : '"Google Sans",Roboto,Arial,sans-serif', color: pickTextColorBasedOnBgColorAdvanced(settingscolor, '#FFFFFF', '#000000') }} onClick={() => {setSubmitting(true); handleSubmit(); setShowConfirmationPage(false)}}>Submit</Button> :
          <Button disabled={submitting === true ? true : false} variant="contained" style={{display: "block", float: "left", backgroundColor: submitting ? 'gray' : settingscolor, textTransform: 'none', padding: '8px 24px', fontSize: '14px', fontFamily: settings.text_style.text.font ? settings.text_style.text.font : '"Google Sans",Roboto,Arial,sans-serif', color: pickTextColorBasedOnBgColorAdvanced(settingscolor, '#FFFFFF', '#000000') }} onClick={() => {setSubmitting(true); handleSubmit(); setShowConfirmationPage(false)}}>Submit</Button> }
          <Button onClick={() => {setAnswers({})}} variant="text" style={{display: "block", float: "right", color: pickTextColorBasedOnBgColorAdvanced(settingsbackgroundcolor, '#FFFFFF', '#000000'), textTransform: 'none', padding: '8px 24px', fontSize: '14px', fontFamily: settings.text_style.text.font ? settings.text_style.text.font : '"Google Sans",Roboto,Arial,sans-serif' }}>Clear form</Button>
        </div>
      : null}
    
        </div>
        
        
        </div>
        <div style={{justifyContent: 'center', alignText: 'center', display: 'flex', margin: '16px auto', maxWidth: '90vw', width: '640px'}}>
        <Typography style={{color: 'gray', fontSize: 14}}>Create free forms and send responses to thousands of popular business tools at workload.co</Typography>
        </div>
        <div onClick={() => window.open("https://www.workload.co/forms", "_blank")} style={{cursor: 'pointer', justifyContent: 'center', alignText: 'center', display: 'flex', margin: '16px auto', maxWidth: '90vw', width: '640px'}}>
        <img style={{width: '30%'}} src="https://cdn.workload.co/wp-content/uploads/cropped-new-workload-logo-high-res-900-×-193-px-2.png"></img>
        </div>
        </div>
        
        : null
    );
};

export default Form;