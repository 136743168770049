import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Panel.module.css';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { Paper, Chip, Accordion, CircularProgress, AccordionDetails, Typography, Stepper, Step, StepLabel, StepContent, StepButton,
     Button, Box, Divider, IconButton, InputLabel, MenuItem, FormControl, 
     Select, TextField, ListItemIcon, Avatar, ListItemText, Grid, AccordionSummary } from '@material-ui/core'
import Inspector from 'react-json-inspector';
import '../helpers/json-inspector.css';
import '../helpers/workload-tags.css'; //add styling for the tags in input fields
import '../helpers/workload-mode-single-line-style.css';// add .cm-custom-keyword class here
import 'codemirror/addon/search/searchcursor';
import { v4 as uuid } from 'uuid';
import { Alert, AlertTitle } from '@material-ui/lab';
import { alertName } from '../../redux/ducks/notification';
import { ptree } from '../../redux/ducks/pTree';
import { nodeid } from '../../redux/ducks/nodeID';
import { edgetarget } from '../../redux/ducks/edgetarget';
import Form from "@rjsf/material-ui"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {FieldsRender} from '../helpers/panel-functions'
import { FilterWidget } from '../helpers/panel-functions'; 
import {
    nodeAdded, edgeAdded, elementUpdated, nameUpdated, elementDeleted, fetchWorkflow, nodeEdgeAdded, saveWorkflow, elementSelected, testNode,
    selectWorkflowElements, selectElementById, selectWorkflowName, selectWorkflowId, selectParentTree, selectWorkflowTemplate, selectWorkflowActive, selectCurrentElement
} from '../../features/workflow/workflowSlice'
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    container: {
      position: 'relative',
      border: '1px solid #e0e0e0',
      borderRadius: 4,
      padding: theme.spacing(2),
      width: '90%',
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(4)
    },
    selectField: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    textField: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1),
      textTransform: 'none'
    },
    divider: {
      margin: `${theme.spacing(2)}px 0`,
    },
    
    filterHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
      },
    }));

function FilterPanel({ selectedEdge, deleteEdge, sendEdge, workflowID, parentTree, onPaneClick }) {
    const [activeStep, setStep] = useState(0);
    const edge = useSelector(selectElementById(selectedEdge.id))
    const [name, setName] = useState(edge.data.name);
    const [type, setType] = useState(edge.data.type)
    const [label, setLabel] = useState(edge.data.label);
    const [icon, setIcon] = useState(edge.data.icon);
    const [loading, setLoading] = useState(true);
    const [executions, setExecutions] = useState([]);
    const [nosave, setNoSave] = useState(0)
    const dispatch = useDispatch();
    const colors = [
        'primary',
        'secondary'
    ];
    const [fields, setFields] = useState(edge.data.fields)
    const [testData, setTestData] = useState(edge.data.testData)
//console.log('filter fields: ', fields)
//console.log('filter testData: ', testData)
    const [color, setColor] = useState(0);

    // React.useEffect(() => {
    //     const interval = setInterval(() => {
    //         setColor((v) => (v === 2 ? 0 : v + 1));
    //     }, 1500);
    // }, []);

    useEffect(() => {
        let isMounted = true;
        saveEdge();
        return () => { isMounted = false }
    }, [fields, name, type, label, icon])

    // console.log(edge)
    // dispatch(edgetarget(edge.target));

    const saveEdge = () => {
      //   console.log('saving node')
      //   console.log(edge)
     /*     sendEdge(Object.assign({},edge, {
             data: {
                 name: name,
                 type: type,
                 label: label,
                 icon: icon,
                 fields: fields,
                 testData: testData
             }
         })) */
         
        dispatch(elementUpdated(selectedEdge.id,
            {
                name: name,
                type: type,
                label: label,
                icon: icon,
                fields: fields,
                testData: testData
            },
            null))
    }

    const setNodeNameAuto = (e) => {
        // edge.data.name = label + ' - ' + e;
        setName(label + ' - ' + e);
        // saveEdge(edge);
        // console.log('name is now, ', edge.data.name)
    }

    // const sendAlert = () => {
    //     dispatch(alertName({ "severity": "success", "message": edge.data.name + " saved successfully" }))
    // }

    // const resetForm = () => {
    //     setStep(activeStep - 1)
    //     setTestData(null);
    // }
    const classes = useStyles();
    const [andFilters, setAndFilters] = useState([{ field: '', condition: '', text: '' }]);
    const [orGroups, setOrGroups] = useState([]);

   
    const handleAddAndFilter = () => {
      setAndFilters([...andFilters, { field: '', condition: '', text: '' }]);
    };
  
    const handleAddOrGroup = () => {
      setOrGroups([...orGroups, [{ field: '', condition: '', text: '' }]]);
    };
  
    const handleFilterChange = (group, index, key, value) => {
      if (group === 'and') {
        const updatedAndFilters = andFilters.map((filter, i) =>
          i === index ? { ...filter, [key]: value } : filter
        );
        setAndFilters(updatedAndFilters);
      } else {
        const updatedOrGroups = orGroups.map((orGroup, gIndex) =>
          gIndex === group
            ? orGroup.map((filter, i) =>
                i === index ? { ...filter, [key]: value } : filter
              )
            : orGroup
        );
        setOrGroups(updatedOrGroups);
      }
    };
  
    const handleAddFilterToOrGroup = (groupIndex) => {
      const updatedOrGroups = orGroups.map((orGroup, i) =>
        i === groupIndex ? [...orGroup, { field: '', condition: '', text: '' }] : orGroup
      );
      setOrGroups(updatedOrGroups);
    };
  
    const handleDeleteAndGroup = () => {
      setAndFilters([]);
    };
  
    const handleDeleteOrGroup = (groupIndex) => {
      const updatedOrGroups = orGroups.filter((_, i) => i !== groupIndex);
      setOrGroups(updatedOrGroups);
    };
  
    const handleDeleteFilter = (group, index) => {
        if (group === 'and') {
          const updatedAndFilters = andFilters.filter((_, i) => i !== index);
          setAndFilters(updatedAndFilters);
        } else {
          const updatedOrGroups = orGroups
            .map((orGroup, gIndex) =>
              gIndex === group ? orGroup.filter((_, i) => i !== index) : orGroup
            )
            .filter((orGroup) => orGroup.length > 0); // Remove empty "Or" groups
          setOrGroups(updatedOrGroups);
        }
      };
    return (
        <>
            <div key={edge} className={styles.Panel} style={{paddingBottom: '60px'}}>
                  <FieldsRender key={edge} fields={fields} setFields={setFields} parentTree={parentTree} nodeID={edge.id} />
  {/* <>
      {/* "And" Group Filters
      <Box className={classes.container} style={{ backgroundColor: 'white' }}>
        {/* <IconButton className={classes.deleteButton} onClick={handleDeleteAndGroup} size="small">
          <DeleteIcon fontSize="small" />
        </IconButton> 
        {andFilters.map((filter, index) => (
           <Box key={index} mb={2} className={classes.container}>
            Header row with left-aligned text and right-aligned delete button 
           <Box className={classes.filterHeader}>
             <Typography variant="subtitle2">
               {index === 0 ? "Only continue if" : "And"}
             </Typography>
             <Box display="flex" alignItems="center">
               <Typography color="secondary" variant="body2">
                 Remove rule
               </Typography>
               <IconButton onClick={() => handleDeleteFilter('and', index)} size="small" color="secondary">
                 <DeleteIcon fontSize="small" />
               </IconButton>
             </Box>
           </Box>
            <TextField
              select
              label="Choose field..."
              value={filter.field}
              onChange={(e) => handleFilterChange('and', index, 'field', e.target.value)}
              variant="outlined"
              className={classes.selectField}
            >
              <MenuItem value="field1">Field 1</MenuItem>
              <MenuItem value="field2">Field 2</MenuItem>
            </TextField>

            <TextField
              select
              label="Choose condition..."
              value={filter.condition}
              onChange={(e) => handleFilterChange('and', index, 'condition', e.target.value)}
              variant="outlined"
              className={classes.selectField}
            >
              <MenuItem value="equals">Equals</MenuItem>
              <MenuItem value="contains">Contains</MenuItem>
            </TextField>

            <TextField
              label="Enter text..."
              value={filter.text}
              onChange={(e) => handleFilterChange('and', index, 'text', e.target.value)}
              variant="outlined"
              className={classes.textField}
            />
          </Box>
        ))}

        <Button variant="outlined" color="primary" onClick={handleAddAndFilter} className={classes.button}>
          + And
        </Button>
      </Box>

      <Divider className={classes.divider} />

      {/* "Or" Group Filters 
      {orGroups.map((orGroup, groupIndex) => (
        <Box
          key={groupIndex}
          className={classes.container}
          style={{ backgroundColor: groupIndex % 2 === 0 ? '#f9f9f9' : 'white' }}
        >
         
          {orGroup.map((filter, index) => (
            <Box key={index} mb={2} className={classes.container}>
            <Box className={classes.filterHeader}>
              <Typography variant="subtitle2">
                {index === 0 ? "Or continue if" : "And"}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography color="secondary" variant="body2">
                  Remove rule
                </Typography>
                <IconButton onClick={() => handleDeleteFilter(groupIndex, index)} size="small" color="secondary">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
              <TextField
                select
                label="Choose field..."
                value={filter.field}
                onChange={(e) => handleFilterChange(groupIndex, index, 'field', e.target.value)}
                variant="outlined"
                className={classes.selectField}
              >
                <MenuItem value="field1">Field 1</MenuItem>
                <MenuItem value="field2">Field 2</MenuItem>
              </TextField>

              <TextField
                select
                label="Choose condition..."
                value={filter.condition}
                onChange={(e) => handleFilterChange(groupIndex, index, 'condition', e.target.value)}
                variant="outlined"
                className={classes.selectField}
              >
                <MenuItem value="equals">Equals</MenuItem>
                <MenuItem value="contains">Contains</MenuItem>
              </TextField>

              <TextField
                label="Enter text..."
                value={filter.text}
                onChange={(e) => handleFilterChange(groupIndex, index, 'text', e.target.value)}
                variant="outlined"
                className={classes.textField}
              />
            </Box>
          ))}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleAddFilterToOrGroup(groupIndex)}
            className={classes.button}
          >
            + And
          </Button>
        </Box>
      ))}

      <Button variant="outlined" color="primary" onClick={handleAddOrGroup} className={classes.button}>
        + Add "Or" rule group
      </Button>
    </>
 */}            </div>
            
        </>
    )
}

export default FilterPanel

