import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import React from 'react';

const defaultState = {
    id: "0",
    name: "Untitled Workflow",
    loading: 'unloaded',
    active: false,
    currentElementID: null,
    elements: [

    ]
}

const filterFields = [
    {
        "name": "Filter Name",
        "schema": {
            "type": "string"
        }
    },
    {
        "name": "Only continue if...",
        "schema": {
            "title": "Filter Setup",
            "type": "array",
            "items": {
                "title": "Conditions",
                "type": "array",
                "items": {
                    "title": "Only continue if...",
                    "type": "object",
                    "properties": {
                        "lhs": {
                            "title": "Choose Field...",
                            "type": "string"
                        },
                        "conditionType": {
                            "title": "Choose condition...",
                            "enum": [
                                "stringEqual",
                                "stringUnequal",
                                "listContains",
                                "listNotContain",
                                "greaterThan",
                                "lessThan",
                                "isTrue",
                                "isFalse",
                                "valueExists",
                                "valueNotExists"
                            ],
                            "enumNames":[
                                "(Text/Number) Equals",
                                "(Text/Number) Does not equal",
                                "(Text/Array) Contains",
                                "(Text/Array) Does not contain",
                                "(Number) Greater than",
                                "(Number) Less Than",
                                "(Boolean) Is frue",
                                "(Boolean) Is false",
                                "Exists",
                                "Does not exist"
                            ],
                            "type": "string"
                        },
                        "rhs": {
                            "title": "Enter or select value...",
                            "type": "string"
                        }
                    }
                }
            }
        }
    },
    {
        "name": "Delay",
        "schema": {
            "title": "Delay Setup (seconds)",
            "type": "string"
        }
    }
]

const defaultWorkflow = (name) => {
    return {
        name: name ?? 'Untitled Workflow',
        description: '',
        active: false,
        nodesRun: 0,
        flowControl: [],
        nodes: {}
    }
}

const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) =>
    async ({ endpoint, method, headers, params, data }) => {
        try {
          //  console.log('trying axios call')
            const result = await axios({ url: baseUrl + endpoint, method, headers, params, data })
            return { data: result.data }
        } catch (axiosError) {
            let err = axiosError
            return {
                error: { status: err.response?.status, data: err.response?.data }
            }
        }
    }

export const workflowApi = createApi({
    reducerPath: 'workflowApi',
    baseQuery: axiosBaseQuery({
        baseUrl: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production'
    }),
    endpoints: build => ({
        getWorkflow: build.query({
            query: (id) => ({
                endpoint: '/djangocaller',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {},
                data: {
                    operation: 'getWorkflow',
                    id: id
                }
            }),
            transformResponse: response => response.data
        }),
        createWorkflow: build.query({
            query: (name) => ({
                endpoint: '/djangocaller',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {},
                data: {
                    operation: 'createWorkflow',
                    data: {
                        name: name,
                        active: false,
                        current_state: {
                            frontendWorkflow: [],
                            workflowTestbed: defaultWorkflow(name),
                            workflowTemplate: defaultWorkflow(name)
                        }
                    }
                }
            }),
            transformResponse: response => response.data
        }),
        saveWorkflow: build.query({
            query: (id, wf) => ({
                endpoint: '/djangocaller',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {},
                data: {
                    operation: 'saveWorkflow',
                    id: id,
                    data: wf
                }
            }),
            transformResponse: response => response.data
        }),
    })
})

export const fetchWorkflow = createAsyncThunk('workflow/fetchWorkflow', async (djangoId, { getState }) => {
  //  console.log('trying to fetch....')
    const loading = getState().workflow.loading
    if (loading == 'loading') {

       // console.log('fetching...')
        var djangoData = {
            operation: 'getWorkflow',
            id: djangoId
        }

        var config = {
            method: 'post',
            url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
            headers: {
                'Content-Type': 'application/json'
            },
            data: djangoData
        }
        const resp = await axios(config)
        return {
            id: djangoId,
            name: resp.data.name,
            elements: resp.data.current_state.frontendWorkflow,
            loading: 'loaded',
            active: resp.data.current_state.workflowTemplate.active
        }
    } else {
      //  console.log('loading != unloaded')
     //  console.log(loading)
        return
    }
})

export const saveWorkflow = createAsyncThunk('workflow/saveWorkflow', async (a, { getState }) => {
    const state = getState().workflow
   // console.log(state)

 //  console.log('saving...')
    var djangoData = {
        operation: 'updateWorkflow',
        id: state.id,
        data: {
            name: state.name,
            current_state: {
                frontendWorkflow: state.elements,
                workflowTestbed: { nodesRun: 0, flowControl: [], nodes: convertToBackendNodes(state.elements), globals: {} },
                workflowTemplate: { nodesRun: 0, active: state.active, flowControl: [], nodes: convertToBackendNodes(state.elements), globals: {} }
            }
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }
  //  console.log('post data to django: ', djangoData)
    const resp = await axios(config)
   // console.log('done saving...')
    return {
        id: state.id,
        name: resp.data.name,
        elements: resp.data.current_state.frontendWorkflow
    }
    
})

export const testNode = createAsyncThunk('workflow/testNode', async (nodeID, { getState }) => {
   
    const state = getState().workflow

  // console.log('saving...')
   var djangoData = {
       operation: 'updateWorkflow',
       id: state.id,
       data: {
           name: state.name,
           current_state: {
               frontendWorkflow: state.elements,
               workflowTestbed: { nodesRun: 0, flowControl: [], nodes: convertToBackendNodes(state.elements), globals: {} },
               workflowTemplate: { nodesRun: 0, active: state.active, flowControl: [], nodes: convertToBackendNodes(state.elements), globals: {} }
           }
       }
   }

   var config = {
       method: 'post',
       url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
       headers: {
           'Content-Type': 'application/json'
       },
       data: djangoData
   }
 //  console.log('post data to django: ', djangoData)
    await axios(config)

    console.log('testing...')
    var djangoData = {
        operation: 'testNode',
        data: {
            workflow_id: state.id,
            start_node: nodeID,
            test: true,
            start_node_data: { 'testing': true }
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }
    console.log('making the call to djangocaller...')
    const resp = await axios(config)
    console.log('testing is complete...')
    console.log('response from djangocaller is: ', resp)
    Object.keys(resp.data).forEach(key => {
        //console.log(key, Array.isArray(resp.data[key]));
        if (Array.isArray(resp.data[key]) === true && resp.data[key].length > 50 ) {
           
         //console.log('length of respdata: ', resp.data[key].length)
            resp.data['Message'] = "Results were truncated to show only 50 of "+resp.data[key].length
         //  console.log('respdatakey = ', resp.data[key])
          // console.log('respdatakey = ', resp.data['Message'])
           var new_arr = resp.data[key].slice(0, 50)
           //  console.log('new arr = ', new_arr)
             resp.data[key] = new_arr
        }
      });

  // check if resp.data is an array
  // if it is, use resp.data.slice(0, <max-size>)
    
    return {
        nodeID: nodeID,
        testData: resp.data
    }
})

export const createTrigger = createAsyncThunk('workflow/createTrigger', async (djangoId, { getState }) => {
    const state = getState().workflow

    console.log('creating trigger...')
    let currentNode = state.elements.find(item => item.type == 'trigger')
    //console.log('node is')
    //console.log(currentNode)
   

    var djangoData = {
        operation: 'createTrigger',
        data: {
            workflow_id: state.id,
            start_node: state.currentElementID ?? currentNode.id,
            triggerApp: currentNode.data.app,
            triggerResource: currentNode.data.triggerResource,
            fields: currentNode.data.fields,
            credential: currentNode.data.credential,
            pollingParams: currentNode.data.pollingParams,
            url: currentNode.data.url
        }
    }

    //console.log('here is what we sending to djangoCaller: ', djangoData)
   

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }

    const resp = await axios(config)
    return {
        nodeID: currentNode.id,
        triggerMessage: resp.data
    }
})

export const deleteTrigger = createAsyncThunk('workflow/deleteTrigger', async (djangoId, { getState }) => {
    const state = getState().workflow

    console.log('deletingtrigger...')
    console.log('state is: ', state)
    let currentNode = state.elements.find(item => item.type == 'trigger')
    console.log('node is')
    console.log(currentNode.id)
    
    if (currentNode.data.type === 'webhook' && currentNode.data.app !== 'schedule' && currentNode.data.app !== 'webhook' && currentNode.data.unsubscribe) {
   console.log('we passed this evalll')
    const webhook_obj = currentNode.data.triggerMessage
    console.log('webhook obj: ', webhook_obj)
    let id = ''
    if (webhook_obj.data) {
        id = webhook_obj.data.id
    }
    else {
         id = webhook_obj.id
    }
  
     
    
   
    var djangoData = {
        operation: 'getDD',
        data: {
            url: currentNode.data.url,
            app: currentNode.data.app,
            credential: currentNode.data.credential,
            action: 'deleteWebhook',
            fields: [{"name": "id", "in": "path", "value": id}]
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }

    const resp = await axios(config)
    return {
        nodeID: currentNode.id,
        triggerMessage: resp.data
    }
} else {
    return {
        nodeID: currentNode.id,
        triggerMessage: ""
    }
}
})

export const fetchTriggerChoices = createAsyncThunk('workflow/fetchTriggerChoices', async (djangoId, { getState }) => {
    const state = getState().workflow

  //  console.log('creating trigger...')
    var currentNode = state.elements.find(item => item.id == state.currentElementID)
   // console.log('node is')
   // console.log(currentNode)
    let replaced_params = []
    const result = currentNode.data.fields.filter(x => x.schema.type !== 'object');
   //console.log('result is: ', result)
    const result_body = currentNode.data.fields.filter(x => x.schema.type === 'object')


       if (result.length > 0) {

        result.forEach(result => replaced_params.push({'name':result.name,'in':result.in,'value': result.value}))
       }
       if (result_body.length > 0) {
      var keys = Object.keys(result_body[0].value)
      keys.forEach(element => replaced_params.push({'name':element,'in':'path','value': result_body[0].value[element]}) )
  


        }
       
       
      // console.log('replaced params are now: ', replaced_params)
    var djangoData = {
        operation: 'getDD',
        data: {
            url: currentNode.data.url,
            app: currentNode.data.app,
            credential: currentNode.data.credential,
            action: currentNode.data.performList,
            fields: replaced_params
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        //timeout: 40000,
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }
    let choices = []
    const resp = await axios(config)
    function extractArray(apiResponse) {
        // Check if the response is an array
        if (Array.isArray(apiResponse)) {
            return apiResponse;
        }
    
        // If the response is an object, iterate through its keys
        for (const key in apiResponse) {
            if (Array.isArray(apiResponse[key])) {
                return apiResponse[key];
            }
        }
    
        // Return an empty array if no array is found
        return [];
    }
   // console.log('resp await is: ', resp.data)
    const obj = resp.data
    choices = extractArray(obj)
   /*  const check_obj = Object.values(resp.data)
    if (Array.isArray(check_obj) === true && !currentNode.data.pollingParams) {
        console.log('condition 1')
        // console.log('this returned an array....')
        // console.log('choices final: ', choices.slice(0,5))
        // console.log(' state is: ', check_obj)
         choices = check_obj

         
     }
      else if (Object.keys(currentNode.data.pollingParams).length > 0){
        console.log('condition 2')
       // console.log('we are in this block 441 eval')
        if (currentNode.data.pollingParams.data_head === '0'){
            console.log('condition 3')
            choices = obj
        //   console.log('choices: ', choices)
        } else if (currentNode.data.pollingParams.data_head) {
            console.log('condition 4')
        choices = obj[currentNode.data.pollingParams.data_head]
     //   console.log('choices: ', choices)
        } else {
            console.log('condition 5')
     //   console.log('this is an ELSE...')
    const obj_values = Object.values(obj)
   // console.log('object values: ', obj_values)
    const obj_values_array_filter = obj_values.filter(x => Array.isArray(x) === true)
    //console.log('lets find the array::: ', obj_values_array_filter)
    //console.log('forEach: ', Object.values(obj_values))
    //if obj_values_array_filter is still empty, one last shot to find an array of data:
    
    if (obj_values_array_filter.length === 0) {
        console.log('condition 6')
      //  console.log('we did not find an array initially...looping through all values now...')
         let results = []
        for (let key in obj.data) {
            if (typeof obj.data[key] === "object") {
                console.log('condition 7')
              for (let nestedKey in obj.data[key]) {
            //    console.log('nestedObject: ', obj.data[key][nestedKey]);
                results.push(obj.data[key][nestedKey])
              }
            } else {
           //   console.log('else nestedObject: ', obj.data[key]);   
             
            }
          }
          choices = results
    } else {
        console.log('condition 8')
       // console.log('nope...')
        choices = obj_values_array_filter[0]
    }
   
   // console.log('obj values::: ', choices)
    }
} else {
    console.log('condition 9')
    //console.log('we don her someweh')
    const obj_values = Object.values(obj)
   //console.log('object values: ', obj_values)
  // console.log('object filtered for array values: ', obj_values.filter(obj => Array.isArray(obj) === true))
  // console.log('object choices values: ',  obj_values.filter(obj => Array.isArray(obj) === true)[0])
  
 if (Array.isArray(obj_values) === true) {
    
    console.log('condition 10')
    console.log('choices are: ', obj_values)
        choices = obj_values[0]
    }
     else {
        console.log('condition 11')
     const obj_values_array_filter = obj_values.filter(x => Array.isArray(x) === true)
    // console.log('lets find the array::: ', obj_values_array_filter)
     choices = obj_values_array_filter[0]
     }
}  */

    
    return {
        nodeID: state.currentElementID,
        triggerChoices: choices.slice(0,5)
    }
})

export const createWorkflow = createAsyncThunk('workflow/createWorkflow', async (scaffoldID, { getState }) => {
    const state = getState().workflow
  //  console.log('scffoldID is ', scaffoldID)
    const initElement = [
        {
            "id": "b9186a86-827e-431b-b34a-448cbfb4763d",
            "draggable": false,
            "data": {
                "name": "Click to Add Trigger",

                "type": "trigger",
            },
            "type": "trigger",
            "position": {
                "x": 220,
                "y": 40
            }
        },
        {
            "id": "26879b45-3bce-4614-bf9f-a7b46fa11bbd",
            "data": {
                "name": "Click to Add Action"
            },
            "type": "action",
            "position": {
                "x": 220,
                "y": 240
            }
        },
        {
            "id": "f2833d26-430a-4175-98ec-3eba1959f8e8",
            "data": {
                "text": "empty",
                "fields": filterFields
            },
            "type": "filter",
            "style": {
                "stroke": "gray",
                "strokeWidth": 2
            },
            "source": "b9186a86-827e-431b-b34a-448cbfb4763d",
            "target": "26879b45-3bce-4614-bf9f-a7b46fa11bbd",
            "animated": false
        }
    ]
    const initBackendNodes = [
        {
            "26879b45-3bce-4614-bf9f-a7b46fa11bbd": {
                "id": "26879b45-3bce-4614-bf9f-a7b46fa11bbd",
                "name": "Click to Add Action",
                "renderInfo": {
                    "x": 220,
                    "y": 240
                },
                "connections": {},
                "delay": 0,
                "inputFields": {},
                "outputFields": {}
            },
            "b9186a86-827e-431b-b34a-448cbfb4763d": {
                "id": "b9186a86-827e-431b-b34a-448cbfb4763d",
                "name": "Choose a Trigger",
                "renderInfo": {
                    "x": 220,
                    "y": 20
                },
                "connections": {
                    "26879b45-3bce-4614-bf9f-a7b46fa11bbd": [
                        [
                            {
                                "conditionType": "default"
                            }
                        ]
                    ]
                },
                "delay": 0,
                "inputFields": {},
                "outputFields": {}
            }
        }
    ]

    var djangoData = {
        operation: 'createWorkflow',
        data: {
            name: "Untitled Workflow (Draft)",
            active: false,
            current_state: {
                frontendWorkflow: initElement,
                workflowTestbed: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} },
                workflowTemplate: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} }
            }
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }

    const resp = await axios(config).catch(e => console.log(e))
    const wfID = resp.data.id
    config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap',
        headers: {
            'content-type': 'application/json'
        },
        data: {
            scaffoldId: scaffoldID,
            djangoId: wfID
        }
    }
    const resp2 = await axios(config).catch(e => console.log(e))
   // console.log(resp)
    //window.location.replace("https://editor.workload.co/?id=" + scaffoldID)
    return {
        initialElement: initElement,
        response: resp.data
    }
})

export const acceptTemplateNew = createAsyncThunk('workflow/acceptTemplateNew', async (template, { getState }) => {
    axios.get('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap?scaffoldId='+template.template_id)
    .then(function (response) {
      // handle success
      var djangoId = response.data[0].djangoId;
      var djangoData = {
        operation: 'getWorkflow',
        id: djangoId
    }
    axios.post('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller', djangoData)
    .then(function (response) {
      var workflow_data = response.data;
      var new_name = {'name': 'Template: '+response.data.name}
      var frontend_arr = workflow_data.current_state.frontendWorkflow
      frontend_arr.map((item) => {
       
        delete item.data.credential
        delete item.data.triggerMessage
        var testData = item.data.testData
      
        if (testData) {
         
            Object.keys(testData).forEach(key => {
                testData[key] = '';
              });
        }
    
      })
      Object.assign(workflow_data, new_name)
      Object.assign(workflow_data.current_state.workflowTemplate, {'active': false})
      Object.assign(workflow_data.current_state.frontendWorkflow, frontend_arr)
      
      var workflowData = {
        operation: 'createWorkflow',
        data: workflow_data
        }
        axios.post('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller', workflowData)
          .then(function (response) {
            const django_id = response.data.id
            var scaffoldmap = { 
              scaffoldId: template.scaffoldId,
              djangoId: django_id
            }
            axios.post('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap', scaffoldmap)
            .then(function (response) {  
                
                //console.log('response from djangomap: ', response)
                axios.get('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap?id='+response.data.credentialId)
             .then(function (response) {
                  //  console.log('response from scaffoldID: ', response)
                window.location.replace("https://editor.workload.co/?id=" + response.data[0].scaffoldId)
             })
               
            })
          })
      })
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  
    
})


export const acceptTemplateExist = createAsyncThunk('workflow/acceptTemplateExist', async (template, { getState }) => {
    axios.get('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap?scaffoldId='+template.template_id)
    .then(function (response) {
      // handle success
      var djangoId = response.data[0].djangoId;
      var djangoData = {
        operation: 'getWorkflow',
        id: djangoId
    }
    axios.post('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller', djangoData)
    .then(function (response) {
      var workflow_data = response.data;
      var new_name = {'name': 'Template: '+response.data.name}
      var frontend_arr = workflow_data.current_state.frontendWorkflow
      frontend_arr.map((item) => {
        
        delete item.data.credential
        delete item.data.triggerMessage
        var testData = item.data.testData
      
        if (testData) {
         
            Object.keys(testData).forEach(key => {
                testData[key] = '';
              });
        }
         
        
      })
      Object.assign(workflow_data, new_name)
      Object.assign(workflow_data.current_state.workflowTemplate, {'active': false})
      Object.assign(workflow_data.current_state.frontendWorkflow, frontend_arr)
      
      var workflowData = {
        operation: 'createWorkflow',
        data: workflow_data
        }
        axios.post('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller', workflowData)
          .then(function (response) {
            const django_id = response.data.id
        axios.get('https://backend.workload.co/api/auth/me', { headers: {'Authorization': 'Bearer '+template.token }})
        .then(function (response) {
          //  console.log('/me response: ', response.data)
            const currentTenant = response.data.tenants[0].tenant.id
            const currentUser = response.data.id
        var name = response.data.name
        var create_scaffold_workflow = JSON.stringify({ "data": { "name": name, "active": false, "icons": [] } });
        var headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+template.token
        }
        axios.post('https://backend.workload.co/api/tenant/' + currentTenant + '/workflow', create_scaffold_workflow, {
          headers: headers
        })
        .then(function (response) {
            const scaffoldID = response.data.id
          var scaffoldmap = { 
            scaffoldId: response.data.id,
            djangoId: django_id
          }
          axios.post('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap', scaffoldmap)
          .then(function (response) {
                  //  console.log('response from scaffoldID: ', response)
                window.location.replace("https://editor.workload.co/?u="+currentUser+"&t="+currentTenant+"&id=" + scaffoldID)
             })
               
            })
        }).catch(function (error) {
            // handle error
            console.log(error);
            window.location.replace('https://automate.workload.co')
          })
          })
      })
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  
    
})

export const createTemplate0 = createAsyncThunk('workflow/createTemplate0', async (template, { getState }) => {
    const state = getState().workflow
  //  console.log('scffoldID is ', template.id)
  //  console.log('app1=', template.app)

  //  console.log('creating workflow from template0')

    var getapp = {
        method: 'get',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/apps',
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const allapps = await axios(getapp).catch(e => console.log(e))
   // console.log(allapps);
    const filterIt = (data, searchKey) => data.filter(obj => Object.values(obj).includes(searchKey));
    const newdata = filterIt(allapps.data, template.app);
    var filternode = newdata[0];
  //  console.log('filternode on 317 is: ', filternode)

    const initElement = [
        {
            "id": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
            "data": {
                "name": "Click to Add Trigger"
            },
            "type": "trigger",
            "draggable": false,
            "position": {
                "x": 200,
                "y": 40
            }
        },
        {
            "id": "4199edb1-0808-4944-b3d8-506d29e23783",
            "data": {
                "icon": filternode.icon,
                "name": filternode.name + " - " + template.action,
                "type": template.app,
                "label": filternode.name,
                "hasauth": filternode.hasauth,
                "url": filternode.formDataEndpoint

            },
            "type": "action",
            "position": {
                "x": 200,
                "y": 200
            }
        },
        {
            "id": "reactflow__edge-ca10ec9f-dff8-475a-8cc8-ac4f74938bb9a-4199edb1-0808-4944-b3d8-506d29e23783undefined",
            "data": {
                "text": "empty",
                "fields": filterFields
            },
            "type": "filter",
            "style": {
                "stroke": "gray",
                "strokeWidth": 2
            },
            "source": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
            "target": "4199edb1-0808-4944-b3d8-506d29e23783",
            "animated": false,
            "sourceHandle": "a"
        }
    ]

    const initBackendNodes = [{
        "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9": {
            "id": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
            "name": "Click to Add Trigger",
            "inputFields": {},
            "outputFields": {},
            "delay": 0,
            "connections": {
                "4199edb1-0808-4944-b3d8-506d29e23783": [
                    [
                        {
                            "conditionType": "default"
                        }
                    ]
                ]
            }
        },
        "4199edb1-0808-4944-b3d8-506d29e23783": {
            "id": "4199edb1-0808-4944-b3d8-506d29e23783",
            "name": "Click to Add Action",
            "type": "action",
            "inputFields": {},
            "outputFields": {},
            "connections": {},
            "delay": 0
        }
    }]

    var djangoData = {
        operation: 'createWorkflow',
        data: {
            name: filternode.name + " -> Workflow (Draft) ",
            active: false,
            current_state: {
                frontendWorkflow: initElement,
                workflowTestbed: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} },
                workflowTemplate: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} }
            }
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }

    const resp = await axios(config).catch(e => console.log(e))
    const wfID = resp.data.id
    config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap',
        headers: {
            'content-type': 'application/json'
        },
        data: {
            scaffoldId: template.id,
            djangoId: wfID
        }
    }

   // console.log('made it here to 441 ', config)
   // console.log('made it here to 441 ', initElement)

    const resp2 = await axios(config).catch(e => console.log(e))
  //  console.log(resp)
  //  console.log('code made to fetchworkflow', wfID)
  //  console.log('code after to fetchworkflow')
    window.location.replace("https://editor.workload.co/?id=" + template.id)


})

export const createTemplate1 = createAsyncThunk('workflow/createTemplate1', async (template, { getState }) => {
    const state = getState().workflow
  //  console.log('scffoldID is ', template.id)
 //   console.log('app1=', template.app)
  //  console.log('action1=', template.action)
  //  console.log('creating workflow from template1')

    var getapp = {
        method: 'get',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/apps',
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const allapps = await axios(getapp).catch(e => console.log(e))
  //  console.log(allapps);
    const filterIt = (data, searchKey) => data.filter(obj => Object.values(obj).includes(searchKey));
    const newdata = filterIt(allapps.data, template.app);
    var filternode = newdata[0];
  //  console.log('filternode on 317 is: ', filternode)

    var getaction = {
        method: 'get',
        url: filternode.formDataEndpoint,
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const oneaction = await axios(getaction).catch(e => console.log(e))
  //  console.log(oneaction);
    var list = oneaction.data.actions
  //  console.log('list is: ', list)
    var list_obj = Object.entries(list);
   // console.log('list_obj is: ', list_obj)
    const filterAction = (data, searchKey) => data.filter(obj => Object.values(obj).includes(searchKey));
    const newaction = filterAction(list_obj, template.action);
    var filteraction = newaction[0];
   // console.log('filteraction on 331 is: ', filteraction[1].fields)
    const initElement = [{
        "id": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
        "data": {
            "name": "Click to Add Trigger"
        },
        "type": "trigger",
        "draggable": false,
        "position": {
            "x": 200,
            "y": 40
        }
    },
    {
        "id": "4199edb1-0808-4944-b3d8-506d29e23783",
        "data": {
            "icon": filternode.icon,
            "name": filternode.name + " - " + template.action,
            "type": template.app,
            "label": filternode.name,
            "hasauth": filternode.hasauth,
            "url": filternode.formDataEndpoint,
            "action": filteraction[0],
            "fields": filteraction[1].fields
        },
        "type": "action",
        "position": {
            "x": 200,
            "y": 200
        }
    },
    {
        "id": "reactflow__edge-ca10ec9f-dff8-475a-8cc8-ac4f74938bb9a-4199edb1-0808-4944-b3d8-506d29e23783undefined",
        "data": {
            "text": "empty",
            "fields": filterFields
        },
        "type": "filter",
        "style": {
            "stroke": "gray",
            "strokeWidth": 2
        },
        "source": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
        "target": "4199edb1-0808-4944-b3d8-506d29e23783",
        "animated": false,
        "sourceHandle": "a"
    }
    ]

    const initBackendNodes = [{
        "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9": {
            "id": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
            "name": "Click to Add Trigger",
            "inputFields": {},
            "outputFields": {},
            "delay": 0,
            "connections": {
                "4199edb1-0808-4944-b3d8-506d29e23783": [
                    [
                        {
                            "conditionType": "default"
                        }
                    ]
                ]
            }
        },
        "4199edb1-0808-4944-b3d8-506d29e23783": {
            "id": "4199edb1-0808-4944-b3d8-506d29e23783",
            "name": "Click to Add Action",
            "type": "lever",
            "inputFields": {},
            "outputFields": {},
            "connections": {},
            "delay": 0
        }
    }]

    var djangoData = {
        operation: 'createWorkflow',
        data: {
            name: filternode.name + " -> " + template.action,
            active: false,
            current_state: {
                frontendWorkflow: initElement,
                workflowTestbed: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} },
                workflowTemplate: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} }
            }
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }

    const resp = await axios(config).catch(e => console.log(e))
    const wfID = resp.data.id
    config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap',
        headers: {
            'content-type': 'application/json'
        },
        data: {
            scaffoldId: template.id,
            djangoId: wfID
        }
    }

  //  console.log('made it here to 441 ', config)
  //  console.log('made it here to 441 ', initElement)

    const resp2 = await axios(config).catch(e => console.log(e))
  //  console.log(resp)
   // console.log('code made to fetchworkflow', wfID)
   // console.log('code after to fetchworkflow')
    window.location.replace("https://editor.workload.co/?id=" + template.id)


})

export const createTemplate2 = createAsyncThunk('workflow/createTemplate2', async (template, { getState }) => {
    const state = getState().workflow
   // console.log('template is: ', template)
   // console.log('scffoldID is ', template.id)
   // console.log('app1=', template.app1)
  //  console.log('app2=', template.app2)
  //  console.log('creating workflow from template2')

    var getapp = {
        method: 'get',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/apps',
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const allapps = await axios(getapp).catch(e => console.log(e))
   // console.log(allapps);
    const filterIt = (data, searchKey) => data.filter(obj => Object.values(obj).includes(searchKey));
    const newdata = filterIt(allapps.data, template.app1);
    var filternode = newdata[0];
   // console.log('filternode on 511 is: ', filternode)
    const newdata2 = filterIt(allapps.data, template.app2);
    var empty_state = {app: '', formDataEndpoint: '', icon: '', name: '', auth: ''}
    var filternode2 = template.app2 === 'null' ? empty_state : newdata2[0];
  //  console.log('filternode on 514 is: ', filternode2)


    const initElement = [{
    "id": "b9186a86-827e-431b-b34a-448cbfb4763d",
    "data": {
        "app": filternode.app,
        "url": filternode.formDataEndpoint,
        "icon": filternode.icon,
        "name": "Click to Add Trigger",
        "type": "trigger",
        "label": filternode.name,
        "hasauth": filternode.auth,
        "triggerResource": ""
    },
    "type": "trigger",
    "draggable": false,
    "position": {
        "x": 220,
        "y": 40
    }
},
{
    "id": "26879b45-3bce-4614-bf9f-a7b46fa11bbd",
    "data": {
        "url": filternode2.formDataEndpoint,
        "icon": filternode2.icon,
        "name": "Click to Add Action",
        "type": filternode2.app,
        "label": filternode2.name,
        "hasauth": filternode2.auth
    },
    "type": "action",
    "position": {
        "x": 220,
        "y": 240
    }
},
{
    "id": "f2833d26-430a-4175-98ec-3eba1959f8e8",
    "data": {
        "text": "empty",
        "fields": filterFields
    },
    "type": "filter",
    "style": {
        "stroke": "gray",
        "strokeWidth": 2
    },
    "source": "b9186a86-827e-431b-b34a-448cbfb4763d",
    "target": "26879b45-3bce-4614-bf9f-a7b46fa11bbd",
    "animated": false
}
    ]

    const initBackendNodes = [{
        "b9186a86-827e-431b-b34a-448cbfb4763d": {
            "id": "b9186a86-827e-431b-b34a-448cbfb4763d",
            "name": filternode.name,
            "type": filternode.app,
            "inputFields": {},
            "outputFields": {},
            "delay": 0,
            "connections": {
                "26879b45-3bce-4614-bf9f-a7b46fa11bbd": [
                    [
                        {
                            "conditionType": "default"
                        }
                    ]
                ]
            }
        },
        "26879b45-3bce-4614-bf9f-a7b46fa11bbd": {
            "id": "26879b45-3bce-4614-bf9f-a7b46fa11bbd",
            "name": filternode2.name,
            "type": filternode2.app,
            "inputFields": {},
            "outputFields": {},
            "connections": {},
            "delay": 0
        }
    }]

    var djangoData = {
        operation: 'createWorkflow',
        data: {
            name: filternode.name + " -> " + filternode2.name,
            active: false,
            current_state: {
                frontendWorkflow: initElement,
                workflowTestbed: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} },
                workflowTemplate: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} }
            }
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }

    const resp = await axios(config).catch(e => console.log(e))
    const wfID = resp.data.id
    config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap',
        headers: {
            'content-type': 'application/json'
        },
        data: {
            scaffoldId: template.id,
            djangoId: wfID
        }
    }

  //  console.log('made it here to 441 ', config)
  //  console.log('made it here to 441 ', initElement)

    const resp2 = await axios(config).catch(e => console.log(e))
   // console.log(resp)
   // console.log('code made to fetchworkflow', wfID)
   // console.log('code after to fetchworkflow')
   if (template.cook === false) {
    window.location.replace("https://editor.workload.co/?id=" + template.id)
} 
else {
    axios.get('https://backend.workload.co/api/auth/me', { headers: {'Authorization': 'Bearer '+template.token }})
    .then(function (response) {
       // console.log('/me response: ', response.data)
        const currentTenant = response.data.tenants[0].tenant.id
        const currentUser = response.data.id
    var name = response.data.name
    var create_scaffold_workflow = JSON.stringify({ "data": { "name": "Untitled Workflow (Draft)", "active": false, "icons": [] } });
    var headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+template.token
    }
    axios.post('https://backend.workload.co/api/tenant/' + currentTenant + '/workflow', create_scaffold_workflow, {
      headers: headers
    })
    .then(function (response) {
        const scaffoldID = response.data.id
      var scaffoldmap = { 
        scaffoldId: response.data.id,
        djangoId: wfID
      }
      axios.post('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap', scaffoldmap)
      .then(function (response) {
              //  console.log('response from scaffoldID: ', response)
            window.location.replace("https://editor.workload.co/?u="+currentUser+"&t="+currentTenant+"&id=" + scaffoldID)
         })
           
        })
    }).catch(function (error) {
        // handle error
        console.log(error);
        window.location.replace('https://automate.workload.co')
      })
}


})


export const createTemplate4 = createAsyncThunk('workflow/createTemplate4', async (template, { getState }) => {
    const state = getState().workflow
  //console.log('scffoldID is ', template.id)
   // console.log('app1=', template.app1)
    //console.log('action1=', template.action1)
    //console.log('creating workflow from template1')

    var getapp = {
        method: 'get',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/apps',
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const allapps = await axios(getapp).catch(e => console.log(e))
   // console.log(allapps);
    const filterIt = (data, searchKey) => data.filter(obj => Object.values(obj).includes(searchKey));
    const newdata = filterIt(allapps.data, template.app1);
    var filternode = newdata[0];
   // console.log('filternode on 317 is: ', filternode)
  const newdata2 = filterIt(allapps.data, template.app2);
  //console.log('new data 2: ', newdata2)
  var empty_state = {app: '', formDataEndpoint: '', icon: '', name: '', auth: ''}
  var filternode2 = template.app2 === 'null' ? empty_state : newdata2[0];
  //console.log('filternode on 514 is: ', filternode2)

    var getaction = {
        method: 'get',
        url: filternode.formDataEndpoint,
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const oneaction = await axios(getaction).catch(e => console.log(e))
  //  console.log(oneaction);
    var list = oneaction.data.triggers
   // console.log('list is: ', list)
    var list_obj = Object.entries(list);
    //console.log('list_obj is: ', list_obj)
    const filterAction = (data, searchKey) => data.filter(obj => Object.values(obj).includes(searchKey));
    const newaction = filterAction(list_obj, template.action1);
    //console.log('new actions is: ', newaction)
    var filteraction = newaction[0];
   // console.log('filteraction is: ', filteraction)
   // console.log('filteraction on 331 is: ', filteraction[1].fields)

   var getaction2 = {
    method: 'get',
    url: filternode2.formDataEndpoint,
    headers: {
        'Content-Type': 'application/json'
    }
}

const oneaction2 = await axios(getaction2).catch(e => console.log(e))
  //console.log(oneaction);
var list2 = oneaction2.data.actions
 // console.log('list is: ', list)
var list_obj2 = Object.entries(list2);
 //console.log('list_obj is: ', list_obj2)
const filterAction2 = (data, searchKey) => data.filter(obj => Object.values(obj).includes(searchKey));
   const newaction2 = filterAction2(list_obj2, template.action2);
    var filteraction2 = newaction2[0];
   // console.log('filteraction2 is: ', filteraction2[1].fields)
    const initElement = [{
        "id": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
        "data":  {
            "app": filternode.app,
            "url": filternode.formDataEndpoint,
            "icon": filternode.icon,
            "name": "Click to Add ",
            "type": "trigger",
            "label": filternode.name,
            "hasauth": filternode.auth,
            "triggerResource": filteraction[0]
        },
        "type": "trigger",
        "draggable": false,
        "position": {
            "x": 200,
            "y": 40
        }
    },
    {
        "id": "4199edb1-0808-4944-b3d8-506d29e23783",
        "data": {
            "icon": filternode2.icon,
            "name": filternode2.name + " - " + template.action,
            "type": template.app2,
            "label": filternode2.name,
            "hasauth": filternode2.hasauth,
            "url": filternode2.formDataEndpoint,
            "action": filteraction2[0],
            "fields": filteraction2[1].fields
        },
        "type": "action",
        "position": {
            "x": 200,
            "y": 200
        }
    },
    {
        "id": "reactflow__edge-ca10ec9f-dff8-475a-8cc8-ac4f74938bb9a-4199edb1-0808-4944-b3d8-506d29e23783undefined",
        "data": {
            "text": "empty",
            "fields": filterFields
        },
        "type": "filter",
        "style": {
            "stroke": "gray",
            "strokeWidth": 2
        },
        "source": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
        "target": "4199edb1-0808-4944-b3d8-506d29e23783",
        "animated": false,
        "sourceHandle": "a"
    }
    ]

    const initBackendNodes = [{
        "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9": {
            "id": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
            "name": "Click to Add Trigger",
            "inputFields": {},
            "outputFields": {},
            "delay": 0,
            "connections": {
                "4199edb1-0808-4944-b3d8-506d29e23783": [
                    [
                        {
                            "conditionType": "default"
                        }
                    ]
                ]
            }
        },
        "4199edb1-0808-4944-b3d8-506d29e23783": {
            "id": "4199edb1-0808-4944-b3d8-506d29e23783",
            "name": "Click to Add Action",
            "type": "lever",
            "inputFields": {},
            "outputFields": {},
            "connections": {},
            "delay": 0
        }
    }]
   console.log('creating Workflow...')
    var djangoData = {
        operation: 'createWorkflow',
        data: {
            name: filternode.name + " -> " + template.action,
            active: false,
            current_state: {
                frontendWorkflow: initElement,
                workflowTestbed: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} },
                workflowTemplate: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} }
            }
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }

    const resp = await axios(config).catch(e => console.log(e))
    const wfID = resp.data.id
    config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap',
        headers: {
            'content-type': 'application/json'
        },
        data: {
            scaffoldId: template.id,
            djangoId: wfID
        }
    }

  //  console.log('made it here to 441 ', config)
  //  console.log('made it here to 441 ', initElement)

    const resp2 = await axios(config).catch(e => console.log(e))
  //  console.log(resp)
   // console.log('code made to fetchworkflow', wfID)
   // console.log('code after to fetchworkflow')
  // console.log('made to the cook...')
   if (template.cook === false) {
   // console.log('cook is true...redirecting to id=', template.id)
    window.location.replace("https://editor.workload.co/?id=" + template.id)

} 
else {
   // console.log('made it to cook = true')
    axios.get('https://backend.workload.co/api/auth/me', { headers: {'Authorization': 'Bearer '+template.token }})
    .then(function (response) {
       // console.log('/me response: ', response.data)
        const currentTenant = response.data.tenants[0].tenant.id
        const currentUser = response.data.id
    var name = response.data.name
    var create_scaffold_workflow = JSON.stringify({ "data": { "name": "Untitled Workflow (Draft)", "active": false, "icons": [] } });
    var headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+template.token
    }
    axios.post('https://backend.workload.co/api/tenant/' + currentTenant + '/workflow', create_scaffold_workflow, {
      headers: headers
    })
    .then(function (response) {
        const scaffoldID = response.data.id
      var scaffoldmap = { 
        scaffoldId: response.data.id,
        djangoId: wfID
      }
      axios.post('https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap', scaffoldmap)
      .then(function (response) {
             //  console.log('response from scaffoldID: ', response)
               console.log('made to replacing with full url...')
            window.location.replace("https://editor.workload.co/?u="+currentUser+"&t="+currentTenant+"&id=" + scaffoldID)
         })
           
        })
    }).catch(function (error) {
        // handle error
        console.log(error);
        window.location.replace('https://automate.workload.co')
      })
}

})

export const createTemplate5 = createAsyncThunk('workflow/createTemplate5', async (template, { getState }) => {
    const state = getState().workflow
   // console.log('scffoldID is ', template.id)

  //  console.log('creating workflow from template5')

    var getapp = {
        method: 'get',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/apps',
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const initElement = [{
        "id": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
        "data": {
            "name": "Click to Add Trigger"
        },
        "type": "trigger",
        "draggable": false,
        "position": {
            "x": 200,
            "y": 40
        }
    },
    {
        "id": "4199edb1-0808-4944-b3d8-506d29e23783",
        "data": {
            "name": "Click to Add Action"
        },
        "type": "action",
        "position": {
            "x": 200,
            "y": 200
        }
    },
    {
        "id": "reactflow__edge-ca10ec9f-dff8-475a-8cc8-ac4f74938bb9a-4199edb1-0808-4944-b3d8-506d29e23783undefined",
        "data": {
            "text": "empty",
            "fields": filterFields
        },
        "type": "filter",
        "style": {
            "stroke": "gray",
            "strokeWidth": 2
        },
        "source": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
        "target": "4199edb1-0808-4944-b3d8-506d29e23783",
        "animated": false,
        "sourceHandle": "a"
    }
    ]

    const initBackendNodes = [{
        "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9": {
            "id": "ca10ec9f-dff8-475a-8cc8-ac4f74938bb9",
            "name": "Click to Add Trigger",
            "inputFields": {},
            "outputFields": {},
            "delay": 0,
            "connections": {
                "4199edb1-0808-4944-b3d8-506d29e23783": [
                    [
                        {
                            "conditionType": "default"
                        }
                    ]
                ]
            }
        },
        "4199edb1-0808-4944-b3d8-506d29e23783": {
            "id": "4199edb1-0808-4944-b3d8-506d29e23783",
            "name": "Click to Add Action",
            "type": "lever",
            "inputFields": {},
            "outputFields": {},
            "connections": {},
            "delay": 0
        }
    }]

    var djangoData = {
        operation: 'createWorkflow',
        data: {
            name: "Untitled Workflow (Draft)",
            active: false,
            current_state: {
                frontendWorkflow: initElement,
                workflowTestbed: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} },
                workflowTemplate: { nodesRun: 0, flowControl: [], nodes: initBackendNodes, globals: {} }
            }
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }

    const resp = await axios(config).catch(e => console.log(e))
    const wfID = resp.data.id
    config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap',
        headers: {
            'content-type': 'application/json'
        },
        data: {
            scaffoldId: template.id,
            djangoId: wfID
        }
    }

   // console.log('made it here to 441 ', config)
  //  console.log('made it here to 441 ', initElement)

    const resp2 = await axios(config).catch(e => console.log(e))
  //  console.log(resp)
  //  console.log('code made to fetchworkflow', wfID)
  //  console.log('code after to fetchworkflow')
    window.location.replace("https://editor.workload.co/?id=" + template.id)


})

export const pollForTrigger = createAsyncThunk('workflow/pollForTrigger', async (nodeID, { getState }) => {

})



const workflowSlice = createSlice({
    name: 'workflow',
    initialState: defaultState,
    reducers: {
        nodeAdded: {
            reducer(state, action) {
                state.elements.push(action.payload)
            },
            prepare(nodeType, x, y) {
                return {
                    payload: {
                        id: uuidv4(),
                        type: nodeType,
                        position: {
                            x: x,
                            y: y
                        },
                        data: {
                            name: 'Click to Add Action'
                        }
                    }
                }
            }
        },
        nodeDuplicated: {
            reducer(state, action) {
                state.elements.push(action.payload)
            },
            prepare(nodeType, x, y, z) {
                return {
                    payload: {
                        id: uuidv4(),
                        type: nodeType,
                        position: {
                            x: x,
                            y: y
                        },
                        data: z
                    }
                }
            }
        },
        edgeAdded: {
            reducer(state, action) {
                if (action.payload.source != action.payload.target) {
                    state.elements.push(action.payload)
                }
            },
            prepare(edgeType, source, target) {
                return {
                    payload: {
                        source: source,
                        target: target,
                        id: uuidv4(),
                        type: edgeType,
                        animated: false,
                        style: {
                            stroke: 'gray',
                            strokeWidth: 2
                        },
                        data: {
                            text: 'empty',
                            fields: filterFields
                        }
                    }
                }
            }
        },
        nodeEdgeAdded: {
            reducer(state, action) {
                action.payload.forEach((item) => state.elements.push(item))
            },
            prepare(nodeType, x, y, edgeType, source, node_id) {
                var nodeID = node_id
                console.log("new node is is slicer... ", node_id)
                return {
                    payload: [
                        {
                            id: nodeID,
                            type: nodeType,
                            position: {
                                x: x,
                                y: y
                            },
                            data: {
                                name: 'Click to Add Action'
                            }
                        },
                        {
                            source: source,
                            target: nodeID,
                            id: uuidv4(),
                            type: edgeType,
                            animated: false,
                            style: {
                                stroke: 'gray',
                                strokeWidth: 2
                            },
                            data: {
                                text: 'empty',
                                fields: filterFields
                            }
                        }
                    ]
                }
            }
        },
        elementUpdated: {
            reducer(state, action) {
            //    console.log('updating node...')
             //   console.log(action.payload.id)
             //   console.log(action.payload.data)
                state.elements = state.elements.map(el => {
                    if (el.id == action.payload.id) {
                        el.data = action.payload.data ?? el.data
                        el.position = action.payload.position ?? el.position
                    }
                    return el
                })
            },
            prepare(id, data, position) {
                return {
                    payload: {
                        id: id,
                        data: data,
                        position: position
                    }
                }
            }
        },
        nameUpdated: {
            reducer(state, action) {
                state.name = action.payload
            },
            prepare(name) {
                return { payload: name }
            }
        },
        notification: {
            reducer(state, action) {
                state.notification = action.payload
            },
            prepare(status, message) {
                return { payload: {
                    status: status,
                    message: message
                } }
            }
        },
        activeEnabled: {
            reducer(state, action) {
                state.activeEnabled = action.payload
            },
            prepare(status) {
                return { payload: status }
            }
        },
        loadingperform: {
            reducer(state, action) {
                state.loadingperform = action.payload
            },
            prepare(status) {
                return { payload: status }
            }
        },
        elementDeleted: {
            reducer(state, action) {
                const elementChecker = (currNode, remNode) => {
                    if (currNode.target == remNode || currNode.source == remNode || currNode.id == remNode) { return false } else { return true }
                }
                state.elements = state.elements.filter(el => elementChecker(el, action.payload))
            },
            prepare(elementID) {
                return { payload: elementID }
            }
        },
        activeUpdated: {
            reducer(state, action) {
                state.active = action.payload
            },
            prepare(active) {
                return { payload: active }
            }
        },
        elementSelected: {
            reducer(state, action) {
                console.log('element selected ' + action.payload)
                state.currentElementID = action.payload
            },
            prepare(id) {
                return { payload: id }
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchWorkflow.pending, (state, action) => {
            state.loading = 'loading'
        })
            .addCase(fetchWorkflow.fulfilled, (state, action) => {
                if (state.loading == 'loading') {
                 //   console.log('loading and fulfilled')
                  //  console.log(state)
                 //   console.log(action.payload)
                    state.loading = 'loaded'
                    const newWorkflow = action.payload
                    state.elements = newWorkflow.elements
                    state.name = newWorkflow.name
                    state.id = newWorkflow.id
                    state.active = newWorkflow.active
                }
            }).addCase(fetchWorkflow.rejected, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('failed')
                    state.loading = 'failed'
                }
            })
            .addCase(saveWorkflow.pending, (state, action) => {
                state.loading = 'saving'
            })
            .addCase(saveWorkflow.fulfilled, (state, action) => {
                state.loading = 'saved'
                console.log('save success')
            })
            .addCase(saveWorkflow.rejected, (state, action) => {
                state.loading = 'saveFailed'
                console.log('save failed')
            })
            .addCase(testNode.pending, (state, action) => {
                state.loading = 'testing'
            })
            .addCase(testNode.fulfilled, (state, action) => {
                state.loading = 'tested'
                //console.log('action dot payload.... ', action.payload)
                const nodeID = action.payload.nodeID
                state.elements = state.elements.map(item => { return item.id == nodeID ? { ...item, data: { ...item.data, testData: action.payload.testData } } : item })
                console.log('test success')
                console.log(action.payload.testData)
            })
             .addCase(testNode.rejected, (state, action) => {
                state.loading = 'testFailed'
                console.log('test failed')
                console.log(action.payload.testData)
                const nodeID = action.payload.nodeID
                state.elements = state.elements.map(item => { return item.id == nodeID ? { ...item, data: { ...item.data, testData: action.payload.testData } } : item })
                
            })
            .addCase(createTrigger.pending, (state, action) => {
                state.loading = 'creatingTrigger'
            })
            .addCase(createTrigger.fulfilled, (state, action) => {
                state.loading = 'createdTrigger'
                const nodeID = action.payload.nodeID
                state.elements = state.elements.map(item => { return item.id == nodeID ? { ...item, data: { ...item.data, triggerMessage: action.payload.triggerMessage } } : item })
                console.log('trigger create success')
            })
            .addCase(createTrigger.rejected, (state, action) => {
                state.loading = 'creatingTriggerFailed'
                console.log('trigger create failed')
                console.log('trigger create fail state: ', state)
                console.log('trigge create fail action: ', action)
            })
            .addCase(deleteTrigger.pending, (state, action) => {
                state.loading = 'deletingTrigger'
            })
            .addCase(deleteTrigger.fulfilled, (state, action) => {
                state.loading = 'deletedTrigger'
                const nodeID = action.payload.nodeID
                state.elements = state.elements.map(item => { return item.id == nodeID ? { ...item, data: { ...item.data, triggerMessage: action.payload.triggerMessage } } : item })
                console.log('trigger delete success')
            })
            .addCase(deleteTrigger.rejected, (state, action) => {
                state.loading = 'deletingTriggerFailed'
                console.log('trigger delete failed')
            })
            .addCase(fetchTriggerChoices.pending, (state, action) => {
                state.loading = 'fetchingTriggerChoices'
                console.log('chocies action....: ', action)
                console.log('choices create pending...')
            })
            .addCase(fetchTriggerChoices.fulfilled, (state, action) => {
                state.loading = 'fetchedTriggerChoices'
                const nodeID = action.payload.nodeID
                state.elements = state.elements.map(item => { return item.id == nodeID ? { ...item, data: { ...item.data, triggerChoices: action.payload.triggerChoices } } : item })
                console.log('trigger fetch choices success')
            })
            .addCase(fetchTriggerChoices.rejected, (state, action) => {
                state.loading = 'fetchTriggerChoicesFailed'
                //console.log('trigger create failed')
                console.log('action for fetch choices create fail: ', action)
            })
            .addCase(createWorkflow.pending, (state, action) => {
                state.loading = 'creatingWorkflow'
            })
            .addCase(createWorkflow.fulfilled, (state, action) => {
                state.loading = 'createdWorkflow'
                state.elements = action.payload.initialElement
                state.id = action.payload.response.id
                console.log('workflow create success')
            })
            .addCase(createWorkflow.rejected, (state, action) => {
                state.loading = 'creatingWorkflowFailed'
                console.log('workflow create failed')
            })
            .addCase(createTemplate1.fulfilled, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('loading and fulfilled')
                  //  console.log(state)
                   // console.log(action.payload)
                    state.loading = 'loaded'
                    const newWorkflow = action.payload
                    state.elements = newWorkflow.elements
                    state.name = newWorkflow.name
                    state.id = newWorkflow.id
                }
            }).addCase(createTemplate1.rejected, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('failed')
                    state.loading = 'failed'
                }
            })
            .addCase(createTemplate0.fulfilled, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('loading and fulfilled')
                  //  console.log(state)
                  //  console.log(action.payload)
                    state.loading = 'loaded'
                    const newWorkflow = action.payload
                    state.elements = newWorkflow.elements
                    state.name = newWorkflow.name
                    state.id = newWorkflow.id
                }
            }).addCase(createTemplate0.rejected, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('failed')
                    state.loading = 'failed'
                }
            })
            .addCase(createTemplate2.fulfilled, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('loading and fulfilled')
                 //   console.log(state)
                   // console.log(action.payload)
                    state.loading = 'loaded'
                    const newWorkflow = action.payload
                    state.elements = newWorkflow.elements
                    state.name = newWorkflow.name
                    state.id = newWorkflow.id
                }
            }).addCase(createTemplate2.rejected, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('failed')
                    state.loading = 'failed'
                }
            })
            .addCase(createTemplate4.fulfilled, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('loading and fulfilled')
                 //   console.log(state)
                   // console.log(action.payload)
                    state.loading = 'loaded'
                    const newWorkflow = action.payload
                    state.elements = newWorkflow.elements
                    state.name = newWorkflow.name
                    state.id = newWorkflow.id
                }
            }).addCase(createTemplate4.rejected, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('failed')
                    state.loading = 'failed'
                }
            })
            .addCase(createTemplate5.fulfilled, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('loading and fulfilled')
                  //  console.log(state)
                  //  console.log(action.payload)
                    state.loading = 'loaded'
                    const newWorkflow = action.payload
                    state.elements = newWorkflow.elements
                    state.name = newWorkflow.name
                    state.id = newWorkflow.id
                }
            }).addCase(createTemplate5.rejected, (state, action) => {
                if (state.loading == 'loading') {
                    console.log('failed')
                    state.loading = 'failed'
                }
            })
    }
})

const convertToBackendNodes = (flowElements) => {
    var output = {}

    // var nodeBuckets = {'isNode':isNode, 'isNotNode':(arg)=>{return !isNode(arg)}}
    // var bucketedItems = separateIntoBuckets(flowElements, nodeBuckets)
    // console.log(bucketedItems)
    const isNode = (node) => { if (node.target && node.source) { return false } else { return true } }

    var nodes = flowElements.filter(item => isNode(item))
    var edges = flowElements.filter(item => !isNode(item))

    nodes.forEach((node) => {
        output[node.id] = {
            id: node.id,
            name: node.data.name,
            type: node.data.type,
            url: node.data.url,
            inputFields: { operation: node.data.action, fields: node.data.fields, credential: node.data.credential },
            outputFields: node.data.testData ?? {},
            renderInfo: {
                x: node.position.x,
                y: node.position.y
            },
            connections: {},
            delay: 0
        }

        //assumes relevant startloops are introduced first
        //also very hacky and assuming, needs to be made robust
        if (node.data.type === "endloop") {
            // console.log(node.id)
            if (node.data.fields) {
                var startRef = node.data.fields[0].value
                // console.log(startRef)
                if (startRef) {
                    var startNode = startRef.split('.')[1]
                    // console.log(startNode)
                    if (output[startNode]) {
                       //  console.log(output[startNode])
                        // console.log('length is: ....',output[startNode]['inputFields'].fields.length)
                        if (output[startNode]['inputFields'].fields.length > 1) {
                           // console.log('is this evaluated?')
                           // output[startNode]['inputFields'][1]['value'] = node.id

                            var obj = output[startNode]['inputFields'].fields[1]
                            var newobj1 = Object.assign({}, obj ,{value : node.id})
                           // console.log('newobj1 is: ', newobj1)
                            var newobj2 = Object.assign({}, output[startNode]['inputFields'].fields[1], newobj1 )
                           // console.log('newobj2 is: ', newobj2)
                            var newobj3 = Object.assign({}, output[startNode]['inputFields'],{fields: [output[startNode]['inputFields'].fields[0], newobj2]} )
                           // console.log('newobj3 is: ', newobj3)
                            var newobj4 = Object.assign({}, output[startNode],{ inputFields: newobj3 } )
                           // console.log('newobj4 is: ', newobj4)
                            var newobj5 = Object.assign({}, output,{[startNode] : newobj4 } )
                          //  console.log('newobj5 is: ', newobj5)
                            output = newobj5
                          //  console.log('newnewnew is: ', output) 
                            
                          
                           //console.log('is this evaluated?')
                            
                          // var newfields = Object.assign(output[startNode], output[startNode]['inputFields'].fields[1],{value: node.id} )
                          // console.log('newfields: ', newfields)
                        }
                    }
                }
            }
        }

        var connectos = edges.filter(item => item.source == node.id)
        connectos.forEach(conn => {
           
            if (conn.data) {
                output[node.id]['connections'][conn.target] = conn.data.fields[1].value ? conn.data.fields[1].value.length > 0 ? conn.data.fields[1].value : [[{ conditionType: 'default' }]] : [[{ conditionType: 'default' }]]
               
            } else {
                output[node.id]['connections'][conn.target] = [[{ conditionType: 'default' }]]
               
        
            }
        })

        var delays = edges.filter(item => item.target == node.id)
        delays.forEach(delay => {
            output[delay.target]['delay'] = delay.data.fields[2] ? parseInt(delay.data.fields[2].value) : 0
        })
        
        
    })

    return output
}


export const { nodeAdded, nodeDuplicated, edgeAdded, notification, activeEnabled, loadingperform, elementUpdated, nameUpdated, elementDeleted, activeUpdated, nodeEdgeAdded, elementSelected } = workflowSlice.actions

export default workflowSlice.reducer

export const selectWorkflowElements = state => state.workflow.elements
export const selectWorkflowName = state => state.workflow.name
export const selectWorkflowId = state => state.workflow.id
export const selectWorkflowActive = state => state.workflow.active
export const selectWorkflowTemplateNodes = state => convertToBackendNodes(state.workflow.elements)
export const selectCurrentElement = state => state.workflow.currentElementID
export const selectNotification = state => state.workflow.notification
export const selectActiveEnabled = state => state.workflow.activeEnabled
export const selectLoadingPerform = state => state.workflow.loadingperform

export const selectElementById = id => state => state.workflow.elements.find(el => el.id == id)

export const selectParentTree = createSelector(
    selectWorkflowElements, els => {
        var output = {}

        els.forEach(item => {
            if (item.source && item.target) {
                var sourceNode = els.find(el => el.id == item.source)
                //this means it's an edge
                //get list of target's children and source's parents
                var parents = output[item.source] ?? {}
                //node already in tree
                //add parents of source and source itself
                output[item.target] = { ...output[item.target], ...parents, [item.source]: sourceNode }
                output[item.id] = { ...output[item.target] }
                Object.entries(output).map(([k, v]) => {
                    if (v.hasOwnProperty(item.target)) {
                        output[k] = { ...output[k], ...parents, [item.source]: sourceNode }
                    }
                })
            }
        })

        return output
    }
)


// export const { useGetWorkflowQuery, useCreateWorkflowQuery, useSaveWorkflowQuery } = workflowApi

