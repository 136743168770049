const NOTIFICATION = 'notification';

export const alertName = (notification) => ({
   
    type: NOTIFICATION,
    notification: notification
    
})

const initialState = {
    notification: {"status": "info", "message":"Editor loaded successfully"},
};

export default (state = initialState, action) => {
  
    switch(action.type) {
        case NOTIFICATION:
            const { notification } = action;
            return {...state, notification: notification };

        default: 
        return state;
    }
}