import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const defState = {
    execution: {},
    loading: 'unloaded',
    workflowID: null,
    currentElementID: null
}

export const fetchExecution = createAsyncThunk('workflow/fetchExecution', async (executionID, { getState }) => {
    console.log('trying to fetch....')
    const loading = getState().execution.loading
    if (loading == 'loading') {

        console.log('fetching...')
        var djangoData = {
            operation: 'getExecution',
            id: executionID
        }

        var config = {
            method: 'post',
            url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
            headers: {
                'Content-Type': 'application/json'
            },
            data: djangoData
        }
        const resp = await axios(config)
        console.log(resp)
        return {
            execution: resp.data.state,
            workflowID: resp.data.workflow
        }
    } else {
        console.log('loading != unloaded')
        console.log(loading)
        return
    }
})

const executionSlice = createSlice({
    name: 'execution',
    initialState: defState,
    reducers: {
        elementSelected: {
            reducer(state, action) {
                console.log('element selected '+action.payload)
                state.currentElementID = action.payload
            },
            prepare(id) {
                return { payload: id }
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchExecution.pending, (state, action) => {
                state.loading = 'loading'
            })
            .addCase(fetchExecution.fulfilled, (state, action) => {
                if (state.loading == 'loading') {
                    state.loading = 'loaded'
                    state.execution = action.payload.execution
                    state.workflowID = action.payload.workflowID
                    console.log(action.payload.execution)
                }
            })
            .addCase(fetchExecution.rejected, (state, action) => {
                state.loading = 'failedLoad'
            })
    }
})

const generateParentTree = (nodes) => {
    var output = {}
    Object.entries(nodes).map(([k, v]) => {
        var parents = output[k] ?? {}

        Object.entries(v.connections).map(([k1, v1]) => {
            output[k1] = { ...output[k1], ...parents, [k]: v }

            Object.entries(output).map(([k2, v2]) => {
                if (v.hasOwnProperty(k1)) {
                    output[k2] = { ...output[k2], ...parents, [k]: v }
                }
            })
        })
    })
    return output
}

export const selectWorkflowID = state => state.execution.workflowID
export const selectExecution = state => state.execution.execution
export const selectCurrentElement = state => state.execution.currentElementID
export const selectExecutionParentTree = createSelector([selectExecution], executionData => {
    return generateParentTree(executionData.nodes)
})
export const selectFlowElements = createSelector([selectExecution], executionData => {
    var elements = []
    if (executionData && executionData.nodes) {
        Object.entries(executionData.nodes).map(([k, v]) => {
            elements.push({
                id: k,
                type: 'action',
                position: {
                    x: v.renderInfo?.x ?? 0,
                    y: v.renderInfo?.y ?? 0
                },
                data: {
                    name: v.name,
                    type: v.type,
                    url: v.url,
                    action: v.inputFields.operation,
                    fields: v.inputFields.fields,
                    credential: v.inputFields.credential,
                    testData: v.outputFields
                }
            })
            Object.entries(v.connections).map(([k2, v2]) => {
                elements.push({
                    source:k,
                    target:k2,
                    id: uuidv4(),
                    type:'filter',
                    animated:false,
                    style: {
                        stroke: 'gray',
                        strokeWidth: 2
                    },
                    data: {
                        text: '',
                        fields: v2
                    }
                })
            })
        })
    }
    return elements
})

export const {elementSelected} = executionSlice.actions

export default executionSlice.reducer
