import React, {useState} from 'react';
import { Paper, Tabs, Box, Tab, CircularProgress, Popper, TextareaAutosize, Input, InputBase, 
    Divider, FormHelperText, Typography, Stepper, Step, StepLabel, StepContent, StepButton, Button, 
    InputLabel, Menu, MenuItem, FormControl, Select, TextField, ListItemIcon, Avatar, ListItemText, Grid, 
    AccordionSummary, InputAdornment, Modal, makeStyles } from '@material-ui/core'


    const useStyles = makeStyles((theme) => ({
      root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
      },
      tabContent: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
      }
    }));
    const TabPanel = (props) => {
      const { children, value, index, ...other } = props;
    
      return (
      
          value === index && <Box p={3}>{children}</Box>
      
      );
  
      }

function DataInspector(data) { 

  const [tabvalue, setTabValue] = useState(0);
  
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const classes = useStyles();
  const keyStyle = {
    padding: '3px 8px',
    backgroundColor: '#dff0ff',
    borderRadius: '3px',
    marginRight: '10px',
    marginLeft: '10px',
    display: 'inline-block',
    width: 'fit-content',
    maxWidth: '50%',
    height: 'fit-content',
    wordWrap: 'break-word',
    verticalAlign: 'top'
   
  };

  const valueStyle = {
    display: 'inline-block',
    padding: '3px 8px',
    width: '70%', // Set value width to the remaining 70%,
    verticalAlign: 'top'
    
    
  };

    const renderKeyValuePairs = (obj) => {
        return Object.entries(obj).map(([key, value]) => {
          // Check if the value is an object
          if (typeof value === 'object' && value !== null) {
            return (
              <li key={key} style={{ listStyleType: 'none', marginBottom: '5px' }}>
                
                <ul style={{ padding: '0 0 0 20px' }}>{renderKeyValuePairs(value)}</ul>
              </li>
            );
          }
         
          return (
           
            <li key={key} style={{ listStyleType: 'none', marginBottom: '5px' }}>
            <div style={{margin: '4px 0px 0px 0px'}}>
              <div style={keyStyle}>{key}</div>
              <div style={valueStyle}>{value?.toString()}</div>
            </div>
          </li>
           
          );
        });
      };

    

return (
  <div className={classes.root}>
  <Tabs
    value={tabvalue}
    onChange={handleChange}
    indicatorColor="primary"
    textColor="primary"
  >
    <Tab label="Data" />
    <Tab label="Raw Console" />
  </Tabs>
  <TabPanel value={tabvalue} index={0}>
    <Box >
     
      <Box style={{border: '1px solid silver'}}>
    
<ul style={{ padding: 0 }}>
      {renderKeyValuePairs(data.data)}
    </ul>
</Box>
    </Box>
  </TabPanel>
  <TabPanel value={tabvalue} index={1}>
    <Box>
    <Box style={{padding: '8px', border: '1px solid silver', whiteSpace: 'pre', overflow: 'scroll'}}>
     {JSON.stringify(data.data, null, 2)}
    </Box>
    </Box>
  </TabPanel>
</div>


)}

export default DataInspector
