// import Inspector from 'react-inspector'
import Form from '@rjsf/material-ui'
import { Fragment, useState } from 'react'
// import authAxios from 'src/modules/shared/axios/authAxios'
import axios from 'axios'
import { Button } from '@material-ui/core'

export default () => {
    const [formValue, setFormValue] = useState(null)
    const [inProgress, setInProgress] = useState(true)
    const formSchema = {
        "type": "object",
        "properties": {
            "body": {
                "type": "object",
                "title": "API Info",
                "properties": {
                    "appSlug":{
                        "type":"string",
                        "title":"App Slug",
                        "description":"Unique (all lowercase) identifier for this app"
                    },
                    "appName": {
                        "type": "string",
                        "title": "App Name",
                        "description":"Human Readable App Name. If left blank, App Slug will be used"
                    },
                    "apiType": {
                        "type": "string",
                        "title": "API Type",
                        "enum": [
                            "basic",
                            "oauth2",
                            "apikey"
                        ],
                        "enumNames": [
                            "Basic Auth",
                            "OAuth2",
                            "API Key"
                        ]
                    }
                },
                "required": ["appSlug", "apiType"],
                "dependencies": {
                    "apiType": {
                        "oneOf": [
                            {
                                "properties": {
                                    "apiType": {
                                        "enum": [
                                            "oauth2"
                                        ]
                                    },
                                    "token_url": {
                                        "type": "string",
                                        "title": "Refresh Token URL",
                                        "description": "The URL where the refresh token is sent to obtain the access token"
                                    },
                                    "client_id": {
                                        "type": "string",
                                        "title": "Client ID"
                                    },
                                    "client_secret": {
                                        "type": "string",
                                        "title": "Client Secret"
                                    },
                                    "auth_url":{
                                        "type":"string",
                                        "title":"Authorization URL"
                                    },
                                    "scopes":{
                                        "type":"string",
                                        "title":"Scopes"
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "apiType": {
                                        "enum": [
                                            "basic"
                                        ]
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "apiType": {
                                        "enum": [
                                            "apikey"
                                        ]
                                    }
                                }
                            }
                        ]
                    }
                }
            }
        }
    }

    return (
        <>
            {
                inProgress ?
                    <Form schema={formSchema} formData={formValue} onChange={e => setFormValue(e.formData)} onSubmit={e => {
                        setInProgress(false)
                        var dbEntry = e.formData.body;
                        if(!Object.keys(dbEntry).includes('appName')){dbEntry.appName=dbEntry.appSlug}
                        axios({
                            method: 'post',
                            url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/authInfo',
                            data: e.formData.body
                        })
                    }} /> :
                    <div>
                        <Button onClick={() => {
                            setFormValue(null)
                            setInProgress(true)
                        }} >
                            Click Here to Submit Another
                        </Button>
                    </div>
            }
        </>
    )
}

