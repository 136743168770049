import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import ReactFlow, { ReactFlowProvider } from "react-flow-renderer"
import { makeStyles } from '@material-ui/core/styles';
import ActionNode from "./CustomNodes/ActionNode"
import axios from "axios"
import TriggerNode from "./CustomNodes/TriggerNode"
import FilterEdge from "./CustomEdges/FilterEdge"
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import Fab from '@material-ui/core/Fab';
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchExecution, selectExecution, selectFlowElements, selectWorkflowID, elementSelected as executionElementSelected} from "../features/execution/executionSlice"
import { fetchWorkflow, selectWorkflowTemplateNodes, elementSelected as workflowElementSelected } from "../features/workflow/workflowSlice"
import { Modal, Box, Dialog, Typography, Grid } from "@material-ui/core"
import Inspector from 'react-json-inspector'
import { FieldsRender } from "./helpers/panel-functions"
import { AppBarHeader } from "./AppBar"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { PlayCircleOutlineOutlined, SettingsRounded } from "@material-ui/icons";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }
    
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      };
    }


const modalheight = window.innerHeight;
const modalwidth = window.innerWidth;
let breakpointPosition = ''

if (modalwidth < 450) {

    var breakpointPositionWidth = modalwidth / 2 - 225
    breakpointPosition = [breakpointPositionWidth, 20]

} else if (modalwidth < 720 && modalwidth > 390) {

    var breakpointPositionWidth = modalwidth / 2 - 425
    breakpointPosition = [breakpointPositionWidth, 20]

} else if (modalwidth > 721) {

    var breakpointPositionWidth = modalwidth / 2 - 625
    breakpointPosition = [breakpointPositionWidth, 20]

}

const nodeTypes = {
    action: ActionNode,
    trigger: TriggerNode
}

const edgeTypes = {
    filter: FilterEdge
}

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
        overflowY: 'scroll'
    },
    fab: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 9
    },
  }));

const flowStyle = { background: 'linear-gradient(0.33turn, white, #E8E8E8, lightgray)', height: '100vh', width: '100vw' }

export default ({ executionID }) => {

    const [selectedElement, setSelectedElement] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const elements = useSelector(selectFlowElements)
    const workflowID = useSelector(selectWorkflowID)
    const workflow = useSelector(selectWorkflowTemplateNodes)
    console.log('workflow is: ',workflowID)
   // console.log(elements)
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [sentData, setSentData] = useState()


    const flattenObj = (ob) => {

        // The object which contains the
        // final result
        let result = {};

        // loop through the object "ob"
        for (const i in ob) {

            // We check the type of the i using
            // typeof() function and recursively
            // call the function again
            
            if ((typeof ob[i]) === 'object' ) {
                const temp = flattenObj(ob[i]);
                for (const j in temp) {

                    // Store temp in result
                    result[i.replace('body.', '') + '.' + j] = temp[j];

                }
            }

            // Else store ob[i] in result directly
            else {
                result[i] = ob[i];
            }
        }
        return result;
    };

    const handleSentData2 = (e) => {
        //console.log('e is: ', e)
        var results = e.filter(obj => {
            return obj.value !== undefined && obj.value !== "" && obj.hidden !== true;
        });


        var checkrefs = flattenObj(results.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {}))

        const widgetRe =  /\${(([^}][^}]?|[^}]}?)*)}/ig;
        

        for (const property in checkrefs) {

            if (checkrefs[property].toString().includes('${')) {
                const matcharray = [...checkrefs[property].match(widgetRe)];
                matcharray.map((item) => {
                    
                  //  console.log('item from map: ', item)
                    var splitprop = item.split(".")
                  //  console.log('split props are: ', splitprop)
                    var nodelook = splitprop[1]
                    var nodekey = splitprop.slice(-1)
                   // console.log('nodekey: ', nodekey)
                    var nodekey_concat = nodekey.join('.')
                    var clean = nodekey_concat.replace("}", "")
                   // console.log('nodekey concat: ', clean)
                  // console.log('nodelooks is: ', nodelook)
                   // console.log('elements is: ', elements)
                    var iso_node = elements.filter(x=> x.id === nodelook)
                    var getvalue = iso_node[0].data.testData
                  // console.log('getvalue is: ', getvalue)
                   const recursiveSearch = (obj, searchKey, results = []) => {
                    const r = results;
                    if (obj) {
                    Object.keys(obj).forEach(key => {
                       const value = obj[key];
                       
                       if(key === searchKey && typeof value !== 'object'){
                        //console.log('key is::: ', key)
                        //console.log('value is::: ', value)
                          r.push(value);
                         // console.log('var r push: ', r)
                       }else if(typeof value === 'object'){
                          recursiveSearch(value, searchKey, r);
                       }
                    })};
                    return r;
                 };
                 const replaced_value = recursiveSearch(getvalue, clean)[0];
               //('replaced value:: ', replaced_value)
                    const cleanref = checkrefs[property].replaceAll(item, replaced_value)
                //    console.log('clrenref is now: ', cleanref)
                    checkrefs[property] = cleanref
                    
                })
             //   console.log('array of widgets: ', matcharray)
             //   console.log('checkrefs properties: ', checkrefs[property])
                
                
              //  console.log('clean ref is: ', cleanref)
               // checkrefs[property] = cleanref

              
            }
            if (property.includes('body.')) {

                var new_prop = property.replace('body.', '')
                checkrefs[new_prop] = checkrefs[property];
                delete checkrefs[property]
            }
        }
        setSentData(checkrefs)

    }
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    const dispatch = useDispatch()
    const uiSchema = {
        "ui:readonly": true
    }

    useEffect(() => {
        dispatch(fetchExecution(executionID))
    }, [])

    useEffect(() => {
        if (workflowID) {
            dispatch(fetchWorkflow(workflowID))
        }
    }, [workflowID])

    useEffect(() => {
        if (selectedElement) {
            handleSentData2(selectedElement.data.fields)
        }
    }, [selectedElement])

    const [running, setRunning] = React.useState(false)
    
    const reRun = async () => {
        setRunning(true)
        var djangoData = {
            operation: 'testNode',
            data:  {
                "workflow_id": workflowID,
                "start_node": selectedElement.id,
                "start_node_data": selectedElement.data.testData
            }
        }
    
        var config = {
            method: 'post',
            url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
            headers: {
                'Content-Type': 'application/json'
            },
            data: djangoData
        }
        let res = await axios(config);
        let { data } = res.data;
        setRunning(false);
    };


    const renderSelectedElement = () => {
        if (selectedElement?.type == 'action') {
            //how to render action nodes
            return (
                <Dialog classes={{paper: classes.dialogPaper}} open={isModalOpen} onBackdropClick={() => { setSelectedElement(null); setValue(0); setIsModalOpen(false);}} maxWidth={'md'} fullWidth>
                    <>
                        <DialogTitle>
                          { workflow && workflow[selectedElement?.id]?.name}
                        </DialogTitle>
                     <DialogContent>
                        <Tabs value={selectedElement.data.type === 'polling' || selectedElement.data.type === 'webhook' ? 1 : value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary" textColor="primary">
          <Tab style={{textTransform: 'none'}} label="Input data" {...a11yProps(0)}   />
          <Tab style={{textTransform: 'none'}} label="Output data" {...a11yProps(1)} />
        </Tabs>
      
      <TabPanel value={selectedElement.data.type === 'polling' || selectedElement.data.type === 'webhook' ? 1 : value} index={0}>
        
      {/* <Grid container>
                            <Grid item xs={6}>
                               
                                <FieldsRender fields={selectedElement.data.fields} formData={selectedElement.data.fields[0].value} disabled={true} />
                            </Grid>
                            </Grid> */}
                               <Box>
                            <Inspector data={sentData} isExpanded={(k, q) => true} />
                        </Box>
      </TabPanel>
      <TabPanel value={selectedElement.data.type === 'polling' || selectedElement.data.type === 'webhook' ? 1 : value} index={1}>
                         
                            <Box>
                            {selectedElement.data.type === 'polling' || selectedElement.data.type === 'webhook' ?
                            <div className={classes.fab}>
                            <Tooltip title="Replay execution" placement="top">
                               <Fab tooltip="Replay execution" color='primary' size="large" onClick={reRun}>{running === true ? <CircularProgress color="secondary" size={30} /> : <PlayCircleOutlineOutlined />}</Fab>
                            </Tooltip>
                        </div> : null }
                            <Inspector data={selectedElement.data.testData} isExpanded={(k, q) => true} />
                        </Box>
                         
      </TabPanel>
      </DialogContent>
                    </>

                </Dialog>
            )
        } else if (selectedElement?.type == 'filter') {
            //how to render filter edges
            return (
                <Dialog open={isModalOpen} onBackdropClick={() => {setIsModalOpen(false); setSelectedElement(null); setValue(0)}}>
                    <Box>
                        <Inspector data={selectedElement.data.fields} isExpanded={(k, q) => true} />
                    </Box>
                </Dialog>
            )
        }
        return null
    }

    return (

        <>
     
            {elements ?
          
            
            <div tabIndex='0' style={{ width: '100vw', height: '100vh' }}>  
              <AppBarHeader style={{ width: '100vw' }}/>
             <ReactFlowProvider>
                
                <ReactFlow
                    elements={elements}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    style={flowStyle}
                    defaultPosition={breakpointPosition}
                    onElementClick={(ev, el) => { 
                        dispatch(workflowElementSelected(el.id)); 
                        dispatch(executionElementSelected(el.id)); 
                        setSelectedElement(null); 
                        setSelectedElement(elements.find(item => item.id == el.id)); 
                        setIsModalOpen(true) }}
                    onPaneClick={() => { setSelectedElement(null); setIsModalOpen(false) }}
                >
                    {renderSelectedElement()}
                    
                </ReactFlow>
               
            </ReactFlowProvider>
            </div> 
            : null}
           
        </>
    )
}
