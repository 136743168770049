import React from "react";
import {Card, Grid } from "@material-ui/core";
import IconTabs from '../components/appTabs';






const dashboard = () => {
    return (
        <div style={{paddingTop:50, paddingLeft:90}}>
       <Grid >
        <Card >
        <div>
        <h3>Workflow Editor</h3>
        <small>Step by Step workflow builder tool goes here.</small>
        </div>
        
        </Card>
        <Grid xs={12}>
            <Card style={{spacing:2, paddingTop:8}}>
            <IconTabs />
            </Card>
        </Grid>
        </Grid>
        </div>
        
    );
};

export default dashboard;