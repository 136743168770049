import React from "react";

const connections = () => {
    return (
        <div>
        <h3>Connections</h3>
        <small>All of your connected apps will be displayed here. Test connections.</small>
        </div>
    );
};

export default connections;