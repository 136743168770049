import React, { useState, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useParams
} from "react-router-dom";
import axios from 'axios';
import { Card, Grid, CircularProgress } from "@material-ui/core";
import ExecViewFlow from '../components/execViewFlow';
import { AppBarHeaderDummy } from "../components/AppBarDummy";


const Executions = () => {
  const {execID} = useParams()

  return (<>
  
    <ExecViewFlow executionID={execID} />
    </>
  )
}

export default Executions;