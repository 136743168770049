import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import styles from '../Panel.module.css';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { Paper, Card, Chip,Divider, Accordion,FormHelperText, FormControlLabel, Checkbox, Modal, CircularProgress, AccordionDetails, Typography, Stepper, Step, StepLabel, StepContent, StepButton, Button, InputLabel, MenuItem, FormControl, Select, TextField, ListItemIcon, Avatar, ListItemText, Grid, AccordionSummary } from '@material-ui/core'
import '../helpers/json-inspector.css';
import '../helpers/workload-tags.css'; //add styling for the tags in input fields
import '../helpers/workload-mode-single-line-style.css';// add .cm-custom-keyword class here
import 'codemirror/addon/search/searchcursor';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


import {
    nodeAdded, edgeAdded, elementUpdated, nameUpdated, elementDeleted, fetchWorkflow, nodeEdgeAdded, saveWorkflow, elementSelected, testNode, notification,
    selectWorkflowElements, selectElementById, selectWorkflowName, selectWorkflowId, selectParentTree, selectWorkflowTemplate, selectWorkflowActive, selectCurrentElement
} from '../../features/workflow/workflowSlice'

const modalheight = window.innerHeight;
const modalwidth = window.innerWidth;
function getModalStyle() {
  const top = 5
  //const left = 50 + rand();

  return {
      top: `${top}%`,
      margin: 'auto'
    //  left: `${left}%`,
    //  transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    removeappbutton: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(60),
    },
    actionsContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        justifyContent: 'center',
        width: '90%',
        backgroundColor: '#0069ea',
        borderRadius: '4px',
        border: 'none',
        height: '50px',
        marginBottom: '20px',
        cursor: 'pointer'
      },

      signinbutton: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        justifyContent: 'center',
        width: '90%',
        backgroundColor: 'white',
        color: '#0069ea',
        borderRadius: '4px',
        border: '2px solid #0069ea',
        height: '50px',
        marginBottom: '20px',
        cursor: 'pointer'
      },
    resetContainer: {
        padding: theme.spacing(1),
    },
    selectInput: {
        '*': {
            display: 'flex'
        }
    },
    chiproot: {
        display: 'flex',
        left: 5,
        marginLeft: '20px',
        width: '99%',
        float: 'left',
        '& > *': {
            margin: '-10px',

        },
        "& > *:hover": {
            border: '2px solid',
            borderColor: "#0069ea"
        }
    },
    accordionroot: {
        width: '90px',
    },
    accordionheading: {
        fontWeight: 700,
    },
    dropdownroot: {
        position: 'relative',
    },
    small: {
        width: '22px',
        height: '22px',
        marginRight: '4px'
    },
    formControl: {
      margin: theme.spacing(1),
      width: '95%'
    },
    dropdown: {
        position: 'absolute',
        width: '500px',
        right: 0,
        left: 10,
        zIndex: 5,

        // borderTop: '1px solid',
        // borderLeft: '1px solid',
        boxShadow: "1px 0px 0px lightgrey",
        borderColor: 'lightgrey',
        borderRadius: '5px',
        padding: '4px',
        backgroundColor: 'white',
        opacity: '1',
        overflowX: "hidden",
        maxHeight: '350px',
        overflowY: "scroll",

    },
    containerHeader: {
        display: 'flex',
        padding: '20px 10px 20px 10px',
        border: '1px solid silver',
        borderRadius: '8px'
    },
    headerChild1: {
        flex: 1
    },
    headerChild2: {
   
        width: '50%',
        marginLeft: '5%'
    },
    codemirror: {
        marginTop: '7px',
        border: '1px solid',
        borderColor: 'lightgrey',
        borderRadius: '4px',
        paddingTop: '4px',
        paddingRight: '4px',
        paddingBottom: '4px',
        justifyContent: 'center',
        width: 'inherit',
        height: '48px',
        display: 'relative',
        //position: 'absolute'
        '&:hover': {
            borderColor: 'black',
            border: '1px solid'
        },
        '&:focus-within': {
            borderColor: '#0069ea',
            border: '2px solid'
        }
    },
    modalpromo: {
      position: 'absolute',
      width: '40%',
      //height: modalheight * .60,
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      borderRadius: '4px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: 'scroll'
  },
  gradientbox: {
   
    color: '#FFF',
    background: '#000',
    backgroundClip: 'padding-box', /* !importanté */
    border: 'solid 5px transparent', /* !importanté */
  
    '&:before': {
      content: '',
      zIndex: -1,
      background: 'linear-gradient(to right, red, orange)',
    }
  }
}));

const muiTheme = createMuiTheme({
    palette: {
        primary: { main: '#0069ea' },
        secondary: { main: '#ff5555' }
    },
    props: {
        style: { borderColor: '#0069ea' },
        MuiTextField: {
            variant: 'outlined'

        }
    }
});


function CreateTriggerPanel({ app, tenant, onPaneClick }) {
    
    const classes = useStyles();
    
 
 
  const [appInfo, setAppInfo] = useState({ actions: {}, triggers: {}, authInfo: {} })

  

  const loadApp = (appSlug) => {

    setAppInfo({ actions: {}, triggers: {}, authInfo: {} })
    axios({
        method: 'get',
        url: `https://editor.workload.co:5000/api/apps/${appSlug}`
    }).then(resp => { setAppInfo(resp.data) ; console.log('app data is: ', resp.data)})
}



useEffect(() => {
  loadApp(app.app)
}, [])

  
 
const [submitted, setSubmitted] = useState(false)
const [submitsuccess, setSubmitSuccess] = useState(false)
  const handleSubmitTicket = () => {
    setSubmitted(true)
   
  //console.log('selectedElement data for Custom Trigger: ', app)
  const email = localStorage.getItem('email')
    mixpanel.track('Support Ticket Created', {
      'Email': email ? email : 'undefined',
        'App': app.app,
        'Title': title,
        'Label': app.label,
        'Decription': description
    });
    
  let config_send = {
      method: 'post',
      url: 'https://editor.workload.co:5000/createTicket',
      headers: { 
          
          'Content-Type': 'application/json'
      },
      data: {
        editor_url: window.location.href,
        tenantId: tenant,
        email: email,
        title: app.label + ' - ' + title,
        content: description
      }
      };

      axios(config_send)
      .then((response) => {
     console.log(response.data);
     setSubmitted(false)
     setSubmitSuccess(true)
})
.catch((error) => {
console.log(error);
setSubmitted(false)
});

  }


  const [title, setTitle] = useState('')
  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  const [description, setDescription] = useState('')
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };


    return (
        <>
            <div className={styles.Panel}>
            
            <div className={classes.containerHeader}>
                <div classname={classes.headerChild1}>
                    <div style={{paddingLeft: '10px'}}><Typography style={{fontSize: '20px', fontWeight: 600}}>Submit Support Ticket</Typography>
                    <Typography style={{marginTop: '20px', color: 'gray', fontWeight: 400}} >
                    Workload's community-driven platform allows you to easily create new triggers for {app.label}.
                    </Typography></div>
                </div>
                <div className={classes.headerChild2}>
                    <div> <a href="https://youtube.com" target="_blank"><img src="https://cdn.workload.co/wp-content/uploads/Build-your-own-custom-trigger-in-minutes.png" width='250px' style={{borderRadius: '8px'}}/></a></div>
                </div>
                
            </div>
            {submitsuccess === true ? 
            <>
            <div style={{marginTop: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            
            <CheckCircleRoundedIcon style={{color: 'green', fontSize: '30px', marginRight: '8px', marginLeft: '25px'}} />
            <Typography style={{color: 'black', fontSize: 18, fontWeight: 600}}>A ticket was successfully created.</Typography>
            </div>
            <div style={{display: 'flex', marginTop: '20px', justifyContent: 'center', alignItems: 'center'}}>
              <Button onClick={onPaneClick} style={{textTransform: 'none'}} variant='text'>Close</Button>
            </div>
            
            
            
            </> : 
            <>
            <div style={{paddingLeft: '10px', marginTop: '20px', paddingRight: '10px'}}>
                            <InputLabel htmlFor={"nountext"}><Typography variant="h6" style={{color: 'black'}}><strong>Event</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"nountext"}><Typography variant="body2">What is the noun, object, item, or resource this event is about? Example: <code style={{border: '1px solid lightgray'}}>New Customer</code></Typography> </FormHelperText>
                            <br/><TextField variant="outlined"
                            fullWidth
                            id="create-ticket-title"
                            label="Trigger/Action Event"
                            value={title}
                            onChange={handleTitle} />
                            <br/><br/>
                </div>
                <div style={{paddingLeft: '10px', paddingRight: '10px'}}>
                            <InputLabel htmlFor={"nountext"}><Typography variant="h6" style={{color: 'black'}}><strong>Message to support team</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"nountext"}><Typography variant="body2">Please share more details about your workflow. Example: <code style={{border: '1px solid lightgray'}}>When a new customer is created in Salesforce, add their information into a Google Sheet.</code></Typography> </FormHelperText>
                            <br/><TextField variant="outlined"
                            fullWidth
                            multiline
                            rows={6}
                            id="create-ticket-description"
                            label="Support ticket details..."
                            value={description}
                            onChange={handleDescription} />
                            <br/><br/>
                            <Button
                                    disabled={ title && description ? 0 : 1 }
                                    style={{width: '100%', textTransform: 'none', fontSize: '18px', fontWeight: 600}}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmitTicket}
                                    className={classes.button}
                                >
                                    {submitted === false ? 'Submit ticket' : <CircularProgress />}
                                </Button>
                   </div>
                   </>
                  }
            </div>
            
        </>
    )
}



export default CreateTriggerPanel

