import React from "react";

const userProfile = () => {
    return (
        <div>
        <h3>User Profile</h3>
        <small>Profile, billing settings, information...</small>
        </div>
    );
};

export default userProfile;