import React, { useEffect, useState, useRef, Fragment } from 'react';
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { useDispatch, useSelector } from 'react-redux';
import 'codemirror/addon/search/searchcursor';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';
import styles from '../Panel.module.css';
import notrigger from '../NoTrigger.module.css';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { Paper, Modal, Dialog, DialogActions,DialogContent, DialogContentText, DialogTitle, FormHelperText,Popper, Snackbar, OutlinedInput, Select, ListItemIcon, Input, InputLabel, CircularProgress, InputAdornment, Divider, Typography, Stepper, Step, StepContent, StepButton, StepIcon, Button, MenuItem, FormControl, TextField, Avatar, Grid, Box, Tooltip, TextareaAutosize } from '@material-ui/core'
import Authorizer from '../Authorizer'
import SettingsEthernetRoundedIcon from '@material-ui/icons/SettingsEthernetRounded';
import '../helpers/json-inspector.css';
import { v4 as uuid } from 'uuid';
import { Alert, AlertTitle } from '@material-ui/lab';
import BoltRoundedIcon from '@material-ui/icons/OfflineBoltOutlined';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/AppsRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import FlashOffRoundedIcon from '@material-ui/icons/FlashOffRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import DataInspector from './DataInspector';

import { alertName } from '../../redux/ducks/notification';
import Form from "@rjsf/material-ui"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
    nodeAdded, edgeAdded, elementUpdated,notification, nameUpdated, elementDeleted, fetchWorkflow, nodeEdgeAdded, saveWorkflow, elementSelected, testNode, createTrigger, fetchTriggerChoices,
    selectWorkflowElements, selectElementById, selectWorkflowName, selectWorkflowId, selectParentTree, selectWorkflowTemplate, selectWorkflowTemplateNodes, selectWorkflowActive, selectCurrentElement, activeUpdated
} from '../../features/workflow/workflowSlice'

import { FieldsRender } from '../helpers/panel-functions'
import { selectAllTriggers } from '../../features/allApps/allAppsSlice'
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { WarningRounded, ErrorRounded, CheckCircleRounded } from '@material-ui/icons';
import { useStore, useZoomPanHelper } from 'react-flow-renderer';



function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  function getModalStyle() {
    const top = 30;
    const left = 30;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

function Carat(props) {
    const isActive = props.active;
    if (isActive) {
      return <KeyboardArrowUpRoundedIcon />;
    }
    return <KeyboardArrowDownRoundedIcon />;
  }

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    removeappbutton: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    selectInput: {
        width: '100%',
        marginTop: '50px',
    },
    paper: {
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    modalstyle: {
        position: 'absolute',
        width: '70%',
        borderRadius: 5,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
      },
    loadbutton: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        justifyContent: 'center',
        width: '90%',
        backgroundColor: 'white',
        borderRadius: '4px',
        border: '1px solid #0069ea',
        height: '40px',
        marginBottom: '20px',
        cursor: 'pointer'
      },
    papermain: {
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5),
        paddingLeft: '25px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    cardblack: {
        position: 'relative',
        width: '100%',
        paddingBottom: '35px',
        cursor: 'pointer',
        height: '100%',
        // paddingTop: '9px',
        borderRadius: '4px'
    },
    triggersContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(1),
        borderRadius: '8px',
        marginTop: '-10px',
        marginBottom: '5px',
    },
    selectInput: {
        '*': {
            display: 'flex'
        }
    },
    popper: {
        border: '1px solid lightgray',
        borderRadius: '4px',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        zIndex: 999,
        height: '400px',
        boxShadow: '0 5px 5px 5px lightgray',
        width: '500px'
    },
    modal: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    headerbox: {
        width: '500px',
        marginLeft: '20px',
        marginBottom: '20px',
        display: 'block'
    }
}));




function TriggerPanel({ selectedNode, deleteNode, sendNode, parentTree, user, tenant, setWFActive, onPaneClick, sendLoggedOutToAuthorizer, breakpoint, createCustomTrigger, createSupportTicket }) {

    const node = useSelector(selectElementById(selectedNode.id))
    const [name, setName] = useState(node.data.name);
    const [app, setApp] = useState(node.data.app);
    const [timestamp, setTimeStamp ]= useState(new Date())
    const [triggerChoiceTime, setTriggerChoiceTime ]= useState(node.data.triggerChoiceTime)
    // console.log('app = ', app)
    const [type, setType] = useState(node.data.type)
    const [label, setLabel] = useState(node.data.label);
    const [icon, setIcon] = useState(node.data.icon);
    const allApps = useSelector(selectAllTriggers)
    const [triggers, setTriggers] = useState([]);
    const [trigger, setTrigger] = useState({ key: node.data.action, label: node.data.action });
    const [triggerlabel, setTriggerLabel] = useState(node.data.triggerlabel)
    const [credentialId, setCredentialId] = useState(node.data.credential)
    const [authname, setAuthname] = useState(node.data.authname)
    const [custombutton, setCustomButton] = useState(node.data.custombutton)
    const [pollingParams, setPollingParams] = useState(node.data.pollingParams)
    const [performList, setPerformList] = useState(node.data.performList)
    const [webhookKey, setWebhookKey] = useState(node.data.webhookKey)
    const [unsubscribe, setUnsubscribe] = useState(node.data.unsubscribe)
    const [loading, setLoading] = useState(true);
    const [executions, setExecutions] = useState([]);
    const [nosave, setNoSave] = useState(0)
    const [triggerMessage, setTriggerMessage] = useState(node.data.triggerMessage)
    const [triggerChoices, setTriggerChoices] = useState(node.data.triggerChoices)
    const [url, setURL] = useState(node.data.url)
    const workflowID = useSelector(selectWorkflowId)
    const dispatch = useDispatch();
    const [awaitingTrigger, setAwaitingTrigger] = useState(false)
    const workflow = useSelector(selectWorkflowTemplateNodes)
    const [modalStyle] = React.useState(getModalStyle);
    const [modalopen, setModalOpen] = React.useState(false);
    const [fields, setFields] = useState(node.data.fields)
    const [hasauth, setHasAuth] = useState(node.data.hasauth)
    const [testData, setTestData] = useState(node.data.testData)
    const [triggerchoice, setChoice] = useState(node.data.triggerchoice)
    const bottomRef = useRef(null);
    const [openalert, setOpen] = React.useState(false);
    const { zoomIn, zoomOut, setCenter } = useZoomPanHelper();
    const store = useStore();

    const colors = [
        'primary',
        'secondary'
    ];

    const [color, setColor] = useState(0);
    const [triggersopen, setTriggersOpen] = useState(false)
    const [loadedTriggers, setLoadedTriggers] = useState(false)

    useEffect(() => {
        if (loading === false) {
            const interval = setInterval(() => {
                setColor((v) => (v === 2 ? 0 : v + 1));
            }, 1500);
        } else {
            return null;
        }
    }, []);

    // console.log('inherited tree')
    // console.log(parentTree)

    //console.log(node)
    // console.log(triggers)
    // console.log(trigger)

    const saveNode = () => {
        // console.log('saving node')
        // console.log(node)
        
        dispatch(elementUpdated(selectedNode.id,
            {
                name: name,
                type: type,
                app: app,
                label: label,
                triggerlabel: triggerlabel,
                icon: icon,
                hasauth: hasauth,
                custombutton: custombutton,
                action: trigger.key,
                triggerResource: Object.keys(triggers).length > 0 ? trigger ? triggers[trigger.key] ? triggers[trigger.key].action : performList : performList : performList,
                credential: credentialId,
                authname: authname,
                fields: fields,
                testData: testData,
                url: url,
                pollingParams: pollingParams,
                triggerMessage: triggerMessage,
                performList: performList,
                unsubscribe: unsubscribe,
                webhookKey: webhookKey,
                triggerChoices: triggerChoices,
                triggerchoice: triggerchoice,
                triggerChoiceTime: triggerChoiceTime
            },
            null
        ));
    }

    //whenever a field is updated, send the change to redux
    useEffect(() => {
        saveNode()
        if (type === 'polling') {
            //console.log('making polling into a performList...')
            setPerformList(node.data.triggerResource)
          
            
        }
    }, [name, app, label, icon, hasauth, trigger, credentialId, authname, fields, testData, performList])

    useEffect(() => {
        var ticketString = localStorage.getItem("tickets")
        var ticketJSON = ticketString ? JSON.parse(ticketString) : []
       // console.log('trigger info: ', trigger?.label)
       if ( ticketJSON.includes(trigger?.label+" Trigger Incomplete_"+label) === false && app !== null && triggerlabel !== null && trigger.pollingParams && Object.keys(trigger.pollingParams).length === 0 && triggers[trigger.key].action !== 'createWebhook') {
        //  console.log('triggers are empty...sending a support ticket now!')
         // console.log('trigger data is: ', trigger)
           handleSubmitTicket(trigger.label+" Trigger Incomplete")
       }
    }, [trigger.pollingParams])


    //refresh a field from redux after making async call (i.e. testNode)
    useEffect(() => {
        setTriggerMessage(node?.data.triggerMessage)
        setTriggerChoices(node?.data.triggerChoices)
        
       // console.log('triggerMessage from UseEffect : ', triggerMessage)
       // console.log('triggerChoices from UseEffect : ', triggerChoices)
    }, [node]) 

    const getExecutions = (timestamp) => {
        return axios.request({
            method: 'post',
            url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                operation: 'getExecutions',
                data: {
                    workflow_id: workflowID,
                    timestamp: timestamp
                }
            }
        })
    }

    useEffect(() => {

        let isMounted = true;
        async function getTriggers() {
           
            setLoadedTriggers(false)
            axios.request({
                method: "GET",
                url: url
            }).then(res => { 
                if (isMounted) {
                     setTriggers(res.data.triggers ?? []); 
                     setLoadedTriggers(true) } });
        }
        if (app) {
            getTriggers()
            setTriggers([])
        }
        return () => { isMounted = false };
    }, [app])

    useEffect(() => {
        
        let isMounted = true;
        //   console.log('updating trigger=========================================')
        if (Object.keys(triggers).length > 0 && isMounted) { setFields(triggers[trigger.key] ? triggers[trigger.key].fields : ''); setType(triggers[trigger.key] ? triggers[trigger.key].type : '') }
        return () => { isMounted = false };
    }, [trigger])

    // useEffect(() => {
    //     console.log(schemaForm)
    // }, [schemaForm])
    
    const setNodeNameAuto = (newApp, newAction) => {
        if (name == 'Click to Add Trigger') {
            setName((newAction ? (newAction + ' in ' + newApp) : ""));
        } else if (name == label) {
            setName((newAction ? (newAction + ' in ' + newApp) : ""));
        } else if (!name) {
            setName((newAction ? (newAction + ' in ' + newApp) : ""));
        } else if (name == (label + ' - ' + trigger.label)) {
            setName((newAction ? (newAction + ' in ' + newApp) : ""));
        } else {
            setName((newAction ? (newAction + ' in ' + newApp) : ""));
        }
    }

    const sendAlert = () => {
        dispatch(alertName({ "severity": "success", "message": node.data.name + " saved successfully" }))
    }

    const sendTriggerData = (data) => {
        mixpanel.track('Trigger Select', {
            'trigger': data
        });
    }

    const sendTriggerEventData = (data, event) => {
        mixpanel.track('Trigger Event Selected', {
            'app': data,
            'event': event
        });
    }

    const handleSaveClick = () => {
        console.log('save clicked')
        dispatch(saveWorkflow(1))
    }

    const resetForm = () => {
        setStep(activeStep - 1)
        setTestData(null);
    }

    const classes = useStyles();


    const handleTriggerClick = (event) => {
        //sendTrigger(event);
        console.log('trigger from node is: ', event)

        if (event === 'schedule') {
            setApp('schedule');
            setLabel('Schedule')
            setURL("https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/apps/schedule")
            setIcon("https://www.workload.co/wp-content/uploads/2021/07/schedule.svg")
            setHasAuth(false)
            saveNode()
            setStep(1)
        }
        else if (event === 'webhook') {
            setApp('webhook');
            setLabel('Webhook')
            setURL("https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/apps/webhook")
            setIcon("https://www.workload.co/wp-content/uploads/2021/09/webhook.png")
            setHasAuth(false)
            saveNode()
            setStep(1)
        }
        else if (event === 'workload-forms') {
            setApp('workload-forms');
            setLabel('Forms by Workload')
            setURL("https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/apps/workload-forms")
            setIcon("https://www.workload.co/wp-content/uploads/2021/09/webhook.png")
            setHasAuth(false)
            saveNode()
            setStep(1)
        }

    }
    function sleep(delay = 0) {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }

    const [appopen, setAppOpen] = useState(false);
    const [appoptions, setAppOptions] = useState([]);
    const [apploading, setAppLoading] = useState()
    useEffect(() => 
    {
        let active = true;

        if (!apploading) {
            return undefined;

        }

        (async () => {
            const response = allApps;
            await sleep(2e3); // For demo purposes.
            const apps = await response;
            if (active) {
                setAppOptions(Object.keys(apps).map((key) => apps[key]));
                //  console.log('done loading apps')
                setAppLoading(false)
            }
        })();

        return () => {
            active = false;
        };
    }, [apploading]);
    useEffect(() => {
        
        if (!appopen) {
            setAppOptions([]);
            setAppLoading(true);
        }
    }, [appopen]);

    const allAppsFilterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 50
    })


    
    const [activeStep, setStep] = useState(0)
    
    const hasDynamicForm = trigger && Object.keys(triggers).length > 0 && triggers[trigger]?.dynamicForm
    const finalStep = (hasauth ? hasDynamicForm ? 99 : 99 : 99)
    const testStep = hasauth ? hasDynamicForm ? 4 : 3 : 2
   
    const [triggerError, setTriggerError] = useState()
    useEffect(() => {
       // console.log('activeStep: ', activeStep)
    }, [activeStep])
    useEffect(() => {
       // console.log('this thing is actually running....')
        // Fetch current workflow nodes
        const { nodes } = store.getState();
        const node_obj = nodes.find(item => item.id === node.id);
    
        if (node_obj) {
            const x = node_obj.__rf.position.x + node_obj.__rf.width;
            const y = node_obj.__rf.position.y + node_obj.__rf.height / 2;
            const zoom = 1.15;
    
            // Center the panel view based on the node's position
            setCenter(x, y, zoom);
        }
       // console.log('node object: ', node_obj)
        // Open to the first step with missing information
    
       /*  if (!app) {
            // If no app is selected, go to the App step
            console.log('app is: ', app)
            setStep(0);
           // console.log('step 0')
        } else if (!trigger) {
            // If no trigger is selected, go to the Event step
            setStep(1);
          //  console.log('step 1')
        } else if (hasauth && !authname) {
            // If auth is required but credentials are missing, go to the Account step
            setStep(2);
        } /* else if (fields && fields.some(field => !field.value)) {
            // If there are fields but any are missing data, go to the Trigger setup step
            setStep(3);
        } else if (!testData ?? testData === '') {
            // If test data hasn't been provided yet, go to the Test step
            setStep(4);
          //  console.log('step 4')
        } else {
            // If everything is filled, go to the final step
            setStep(finalStep);
          //  console.log('final step...')
        } */ 
       // console.log('step is set to: ', activeStep)
    }, [node]);
    
    

    const handleSetUpTrigger = () => {
        setStep(activeStep + 1); saveNode()
    }

    const handlePerformList = (n) => {
       // console.log('performing list getting')
        saveNode()
       // console.log('node dataaaa:', node)
        handleSaveClick()
        dispatch(fetchTriggerChoices(1)).then((response) => {
           // console.log('fetchTriggerChoicesResponse: ', response)
            if (response.error) {
                console.log('there was an error fetching choices')
                setTriggerError(response)
                setStep(88)
                saveNode()
            }
        });
        setStep(n === 1 ? activeStep : activeStep + 1);
    }
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const [loadingchoices, setLoadingChoices] = useState(false)
    const handleLoadMorePerformList = async (event) => {
        setLoadingChoices(true)
        event.stopPropagation()
        
        console.log('performing load more....')
       
        //saveNode()
       // console.log('node dataaaa:', node)
        //handleSaveClick()
        dispatch(fetchTriggerChoices(1)).then((response) => {
           // console.log('fetchTriggerChoicesResponse: ', response)
            setLoadingChoices(false)
            if (response.error) {
                console.log('there was an error fetching choices')
                setTriggerError(response)
                setStep(88)
                saveNode()
                setLoadingChoices(false)
            }
        });
       
       
    }

    const handleSetUpTriggerTest = () => {
       // console.log('handlestuptriggertest')
        if (performList) {
            handlePerformList();
        } else {
        saveNode()
        handleSaveClick()
        triggerMessage ? setStep(activeStep + 1) : dispatch(createTrigger(1)).then((response)=>{
          //  console.log('createTriggerResponse: ', response);
            if (response.error) {
               console.log('there was an error!!!')
               setTriggerError(response)
              setStep(88)
              saveNode()
               
            }
      });

        setStep(activeStep + 1);

      //  console.log('all of these things just ran....')
      //  console.log('handleSetupTriggerTest awaiting trigger is: ', awaitingTrigger)
    }

    }

    useEffect(() => {
       // console.log('trigerMessage is: ', triggerMessage)
        if (type === 'polling' && triggerMessage) {
            // document.getElementById("polling-button").click()
        }
        if (app !== 'schedule' && app !== 'webhook' && type === 'webhook' && triggerMessage) {
            // document.getElementById("webhook-button").click()
            setAwaitingTrigger(true)
        }
    }, [triggerMessage])

    const handleCustomTriggerPanel = (e) => {
        mixpanel.track('Custom Trigger Panel Opened', {
            'trigger': e.app
        });
       // handleModalOpen();
        createCustomTrigger(e)
    }
    const handleSupportTicketPanel = (e) => {
        mixpanel.track('Support Panel Opened', {
            'trigger': e.app
        });
       // handleModalOpen();
        createSupportTicket(e)
    }
    
   const handleModalOpen = () => {
    setModalOpen(true)
   }

   const handleModalClose = () => {
    setModalOpen(false)
   }

     const handleNextNodeOpen = () => {
        var obj = workflow[node.id].connections
        let [firstKey] = Object.keys(obj)
        console.log('the next node is: ', firstKey)
        dispatch(elementSelected(firstKey))
    } 
    const modalbody = (
        <div style={modalStyle} className={classes.modalstyle}>
         
      <DialogTitle>Let's add an event to {label}!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1, borderRight: "2px solid #ccc", padding: "10px", marginRight: '10px' }}>
            <div style={{margin: 'auto', display: 'flex', justifyContent: 'center'}}>
            <img src="https://cdn.workload.co/wp-content/uploads/customer_support.png" style={{marginBottom: '16px'}} />
            </div>
            <div style={{margin: 'auto', display: 'flex', justifyContent: 'center'}}>
                <Typography style={{fontSize: '20px', fontWeight: 700, color: 'black'}}>Do it with me</Typography>
                </div>
              <p>Submit a helpdesk ticket and have the Workload team create a new integration (takes 5-10 business days).</p>
              <Button onClick={() => handleSupportTicketPanel({ label: label, icon: icon, app: app, formDataEndpoint: url, plan: plan, planStatus: planStatus })} variant="contained" color="primary" style={{textTransform: 'none', width: '75%', fontSize: '18px'}}>
                Create ticket
              </Button>
            </div>
            <div style={{ flex: 1, padding: "10px" }}>
            <div style={{margin: 'auto', display: 'flex', justifyContent: 'center'}}>
            <img src="https://cdn.workload.co/wp-content/uploads/api_diy.png" style={{marginBottom: '16px'}} />
            </div>
            <div style={{margin: 'auto', display: 'flex', justifyContent: 'center'}}>
                <Typography style={{fontSize: '20px', fontWeight: 700, color: 'black'}}>I'll do it myself</Typography>
                </div>
              <p>Do it yourself with our step by step wizard that will guide you through the process (takes 3-7 minutes).</p>
              <Button onClick={() => handleCustomTriggerPanel({ label: label, icon: icon, app: app, formDataEndpoint: url, plan: plan, planStatus: planStatus })} variant="contained" color="primary" style={{textTransform: 'none', width: '75%', fontSize: '18px'}}>
                Try it
              </Button>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="primary">
          Close
        </Button>
      </DialogActions>
  
          
        </div>
      );
    

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
       // bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [triggerMessage, testData]);

    const [timeLeft, setTimeLeft] = useState(61);
  

    

    useEffect(() => {
      
       

      //  console.log('top of useEffect awaiting trigger is: ', awaitingTrigger)
       // console.log('top of useEffect test data  is: ', testData)
       // console.log('activeStep is: ', activeStep)
       
        // exit early when we reach 0
        if (timeLeft === 0 || 61 && awaitingTrigger === false) return;

        // save intervalId to clear the interval when the
        // component re-renders
        // var executionLength = executions.length;
        // console.log(executionLength)
        //var timestamp = new Date();
       
        const interval = setInterval(() => {
            setTimeLeft(timeLeft- 1);
          //  console.log('timeleft........')
            getExecutions(timestamp).then(resp => {
                setExecutions(resp.data);
            
             //  console.log('timestamp: ', timestamp)
              //  console.log('timeLeft is: ', timeLeft)
            //    console.log('respdata: ', resp.data)
                if (resp.data.length > 0) {
                    setTestData(resp.data[0].state.nodes[node.id].outputFields)
                   // console.log('useEffect interval testData is: ', testData)
                    setAwaitingTrigger(false)
                    dispatch(activeUpdated(false))
                    dispatch(saveWorkflow(1))
                    handleSaveClick();
                    setTimeLeft(61)
                    clearInterval(interval)

                }
                if (timeLeft === 1) {

                    setAwaitingTrigger(false)
                    dispatch(activeUpdated(false))
                    dispatch(saveWorkflow(1))
                    handleSaveClick();
                    setTimeLeft(61)
                    clearInterval(interval)
                    setStep(testStep)

                }
            })

        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => { clearInterval(interval);
        };
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
        
        // hook into awaitingTrigger instead of timeLeft
    }, [timeLeft]);

    

   /*  useEffect(() => {
      console.log('activeSTep is: ', activeStep)
    }, [activeStep]) */
    
    const [anchorElPop, setAnchorElPop] = React.useState(null);
    const handlePopClick = (event) => {
        setAnchorElPop(anchorElPop ? null : event.currentTarget);
    };
    const handlePopClose = (event) => {
        
        setAnchorElPop(null);
    };
    const openpop = Boolean(anchorElPop);
    const id = openpop ? 'simple-popper' : undefined;
    const [poptext, setPopText] = React.useState();

    const [plan, setPlan] = useState();
    const [planStatus, setPlanStatus] = useState()
    const getTenantPlan = () => {
        var token = localStorage.getItem('tok_')
        axios({
          method: 'get',
          url: 'https://backend.workload.co/api/tenant/'+tenant,
          headers: {
            'Authorization': 'Bearer '+token,
            'Content-Type': 'application/json'
          }
      }).then(resp => { setPlan(resp.data.plan) ; setPlanStatus(resp.data.planStatus); console.log('tenant plan info is: ', resp.data.plan)})
      } 
      useEffect(() => {
        
        getTenantPlan() 
      }, [])
      function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    let form_id = query.get("id")

      const setChoiceTestData = (data, choice) => {
       // console.log('setChoiceTestData: ', data)
       // console.log('setChoiceTestData: ', choice)
        setTestData(data)
        var number = choice + 1
        setChoice(triggerlabel +" "+number)
      }

      const triggeroptions = triggers ? 
      
      Object.entries(triggers).map(([k, v]) => {
                                             
        return {
            key: k,
            label: v.label ?? k ?? "",
            pollingParams: v.pollingParams,
            description: v.description ?? "",
            performList: v.performList,
            unsubscribe: v.unsubscribe,
            webhookKey: v.webhookKey,
            triggerResource: v.triggerResource
        }
    }) : []
    
    const handleSubmitTicket = (reason) => {
       
      //console.log('selectedElement data for Custom Trigger: ', app)
      const email = localStorage.getItem('email')
   
      mixpanel.track('Support Ticket Created', {
          'Email': email ? email : 'undefined',
            'App': app,
            'Title': label + " "+reason,
            'Label': label,
            'Decription': "When I use "+label+" there is a problem: "+reason
        });
         let config_send = {
          method: 'post',
          url: 'https://editor.workload.co:5000/createTicket',
          headers: { 
              
              'Content-Type': 'application/json'
          },
          data: {
            editor_url: window.location.href,
            tenantId: tenant,
            email: email,
            title: label + " "+reason,
            content: "When I use "+label+" there is a problem: "+reason,
            app: label,
            event: trigger ? trigger.label : ""
          }
        }
        
    
          axios(config_send)
          .then((response) => {
        // console.log(response.data);
         var ticketString = localStorage.getItem("tickets")
         var ticketJSON = ticketString ? JSON.parse(ticketString) : []
        // console.log("tickets started with: ", ticketJSON)
         ticketJSON.push(reason+"_"+label)
         function removeDuplicates(arr) { 
            return [...new Set(arr)]; 
        } 
        var newTicketJSON = removeDuplicates(ticketJSON)
        let ticketstring = JSON.stringify(newTicketJSON) 
        localStorage.setItem("tickets", ticketstring) 
         
    
    })
    .catch((error) => {
    console.log(error);
   
    });
    
      }

    useEffect(() => {
        var ticketString = localStorage.getItem("tickets")
         var ticketJSON = ticketString ? JSON.parse(ticketString) : []
        if ( ticketJSON.includes("Trigger Dropdown Empty_"+label) === false && app && triggeroptions && triggeroptions.length === 0 && triggersopen === true && loadedTriggers === true) {
          //  console.log('triggers are empty...sending a support ticket now!')
            handleSubmitTicket("Trigger Dropdown Empty")
        }
      }, [triggersopen, loadedTriggers])

    const PaperComponentCustom = options => {
       // console.log('paperoptions are::: ', options)
        const { containerProps, children } = options;
        return (
            <Paper {...containerProps} style={{ fontWeight: 600, padding: 4 }}>
             {children}
                { hasauth === false ? null : <>
                <div>
                    <br />
                    <Divider /><br />

                  <Typography align='center' style={{color: 'black', fontSize: '18px'}}>Can't find the <span style={{ padding: 4, alignItems: 'baseline', fontWeight: 300, fontSize: '16px', border: '1px solid gray', borderRadius: '4px' }}><div style={{ display: 'inline-flex', alignItems: 'flex-end', marginLeft: '0px', marginTop: '4px', padding: '0px 0px 0px 0px' }} ><img src={icon} style={{ height: '25px', width: '25px', marginBottom: '-6px', paddingRight: '4px', position: 'absolute' }} /></div><div style={{ display: 'inline-flex', marginLeft: '29px' }}>{label}</div></span> trigger you're searching for?</Typography><br />
                </div>
               
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Button variant='contained'
                        style={{ textTransform: 'none', backgroundColor: '#0069ea', color: 'white', fontWeight: 600, marginBottom: '10px' }}
                        onMouseDown={() => {
                            handleModalOpen()
                        }}
                    >
                        + Create a new trigger
                    </Button> 

</div></>
    }

            </Paper>
        );
    };

    try {
        return (
            !app ? <div className={notrigger.Panel}>
                <div id='trigger-empty' className={classes.cardblack}>

                    <div id='new-trigger-banner' style={{ width: '100%', height: '70px', backgroundColor: '#0069ea' }}>
                        <Avatar style={{ display: 'flex', float: 'left', marginTop: '20px', marginLeft: '10px' }}>
                            <BoltRoundedIcon style={{ color: 'blue', width: 40, height: 40 }} />
                        </Avatar>
                        <div style={{ display: 'block', float: 'left', marginLeft: '10px' }}>
                            <Typography style={{ color: 'white', marginTop: '0px' }} ><h4>1. Trigger</h4></Typography>
                            <Typography style={{ fontSize: '13px', color: 'white', marginTop: '-25px' }}>Triggers are events that start your workflow</Typography>

                        </div>
                    </div>
                    <Grid container spacing={0}>
                        <Grid item xs={breakpoint !== 'large' ? 12 : 8}>
                            <Paper className={classes.papermain}>
                                <Avatar style={{ width: 30, height: 30, display: 'flex', float: 'left', marginTop: '20px', marginLeft: '0px', marginRight: '5px' }}>
                                    <AppsIcon style={{ width: 20, height: 20 }} />
                                </Avatar>
                                <Typography style={{ fontSize: '13px', marginTop: '18px', fontWeight: 'bold', color: 'black' }} >Trigger App</Typography>
                                <Typography style={{ fontSize: '11px', color: 'black', marginTop: '-2px' }} >Start your workflow when something happens in a specific app.</Typography>
                                <Autocomplete
                                    style={{ width: '100%', height: '60px' }}

                                    autoComplete
                                    id="trigger-combo-box-demo"
                                    className={classes.selectInput}
                                    options={allApps}
                                    value={label}
                                    open={appopen}
                                    filterOptions={allAppsFilterOptions}
                                    onOpen={() => {
                                        setAppOpen(true);
                                    }}
                                    onClose={() => {
                                        setAppOpen(false);
                                    }}
                                    loading={apploading}
                                    loadingText={"Loading Triggers..."}
                                    noOptionsText={"Request a new trigger via chat (bottom right)"}
                                    getOptionLabel={(option) => option.name ?? label}

                                    onChange={(e, v) => {setCredentialId(null);setAuthname(null); setTrigger(''); setTriggerMessage(''); setTriggerChoices(''); setChoice(''); setTestData('');saveNode(); setNodeNameAuto(v.name, undefined); setApp(v.app);setCustomButton(v.custombutton); sendTriggerData(v.app); setIcon(v.icon); setHasAuth(v.auth); setLabel(v.name); setURL(v.formDataEndpoint); saveNode(); setStep(1) }}
                                    renderOption={(option) => (
                                        <MenuItem style={{ width: '100%' }}>
                                            <span>
                                                <Avatar style={{ padding: breakpoint !== 'large' ? '1px' : '4px', width: breakpoint !== 'large' ? '24px' : '32px', height: breakpoint !== 'large' ? '24px' : '32px', marginRight: '10px' }} src={option.icon} />
                                            </span>
                                            {option.name}
                                        </MenuItem>
                                    )}
                                    renderInput={(params) => (
                                        <div style={{ position: "relative", width: '85%', marginTop: '30px' }}>
                                            {params.inputProps.value && (

                                                <span>
                                                    {/* Write logic to have Icon from params.inputProps.value */}
                                                    {<Avatar style={{
                                                        position: "absolute",
                                                        transform: "translateY(40%)",
                                                        marginLeft: "10px",
                                                        width: '32px', height: '32px', marginRight: '30px'
                                                    }} src={icon} />}
                                                </span>
                                            )}
                                            <TextField
                                                autoFocus={true}
                                                {...params}
                                                label="Search for an App"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "off",
                                                    style: { paddingLeft: "45px" } // disable autocomplete and autofill
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {allApps.length === 0 ? <CircularProgress style={{ marginRight: 20 }} color={colors[color]} size={30} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    )

                                                }}
                                            />
                                        </div>
                                    )}
                                />
                                <FormHelperText>Type the name of the app that you want to connect to.</FormHelperText><br />

                                { /* <Avatar style={{width: 30, height: 30, float: 'left', marginTop: '10px', marginLeft: '0px', marginRight: '5px'}} label={data.name} src="https://cdn.workload.co/wp-content/uploads/todoist.png" alt={data.name}>
              <AppsIcon style={ {width : 20,height:20 } }  />
              </Avatar>
              <Avatar style={{width: 30, height: 30,float: 'left', marginTop: '10px', marginLeft: '0px', marginRight: '5px'}} label={data.name} src="https://cdn.workload.co/wp-content/uploads/2021/07/clio-1.png" alt={data.name}>
              <AppsIcon style={ {width : 20,height:20 } }  />
              </Avatar>
              <Avatar style={{width: 30, height: 30, float: 'left', marginTop: '10px', marginLeft: '0px', marginRight: '5px'}} label={data.name} src="https://cdn.workload.co/wp-content/uploads/2021/07/stripe.png" alt={data.name}>
              <AppsIcon style={ {width : 20,height:20 } }  />
              </Avatar>
              <Avatar style={{width: 30, height: 30, float: 'left', marginTop: '10px', marginLeft: '0px', marginRight: '5px'}} label={data.name} src="https://cdn.workload.co/wp-content/uploads/2021/11/clickup.png" alt={data.name}>
              <AppsIcon style={ {width : 20,height:20 } }  />
              </Avatar>
              <Avatar style={{width: 30, height: 30,float: 'left', marginTop: '10px', marginLeft: '0px', marginRight: '5px'}} label={data.name} src="https://cdn.workload.co/wp-content/uploads/lever.png" alt={data.name}>
              <AppsIcon style={ {width : 20,height:20 } }  />
              </Avatar>
              <Avatar style={{width: 30, height: 30, float: 'left', marginTop: '10px', marginLeft: '0px', marginRight: '5px'}} label={data.name} src="https://cdn.workload.co/wp-content/uploads/2020/12/Google-Sheets-logo.png" alt={data.name}>
              <AppsIcon style={ {width : 20,height:20 } }  />
              </Avatar>
              <Avatar style={{width: 30, height: 30, float: 'left', marginTop: '10px', marginLeft: '0px', marginRight: '5px'}} label={data.name} src="https://cdn.workload.co/wp-content/uploads/2020/12/airtable-logo-1.png" alt={data.name}>
              <AppsIcon style={ {width : 20,height:20 } }  />
              </Avatar>
              <Avatar style={{width: 30, height: 30, marginTop: '10px', marginLeft: '0px', marginRight: '5px'}} label={data.name} src="https://www.workload.co/wp-content/uploads/servicetitan.png" alt={data.name}>
              <AppsIcon style={ {width : 20,height:20 } }  />
        </Avatar> */}

                            </Paper>
                        </Grid>
                        <Grid item xs={breakpoint !== 'large' ? 12 : 4}>
                            <Paper onClick={() => handleTriggerClick('schedule')} className={classes.paper}>
                                <Avatar style={{ width: 30, height: 30, display: 'flex', float: 'left', marginTop: '2px', marginLeft: '0px', marginRight: '5px' }} src="https://cdn.workload.co/wp-content/uploads/2021/07/schedule.svg" >
                                    <AppsIcon style={{ width: 20, height: 20 }} />
                                </Avatar>
                                <Typography style={{ fontSize: '13px', marginTop: '2px', fontWeight: 'bold', color: 'black' }} >Schedule</Typography>
                                <Typography style={{ fontSize: '11px', color: 'black', marginTop: '-1px', marginLeft: '35px' }} >Start your workflow on a custom timer</Typography>
                            </Paper>
                            <Paper style={{ marginRight: 4, marginLeft: 4 }} onClick={() => handleTriggerClick('webhook')} className={classes.paper}>
                                <Avatar style={{ width: 30, height: 30, display: 'flex', float: 'left', marginTop: '2px', marginLeft: '0px', marginRight: '5px' }} src="https://cdn.workload.co/wp-content/uploads/2021/09/webhook-1.png" >
                                    <AppsIcon style={{ width: 20, height: 20 }} />
                                </Avatar>
                                <Typography style={{ fontSize: '13px', marginTop: '2px', fontWeight: 'bold', color: 'black' }} >Webhook</Typography>
                                <Typography style={{ fontSize: '11px', color: 'black', marginTop: '-1px', marginLeft: '35px' }} >Trigger workflow when another app sends a webhook</Typography>
                            </Paper>
                            <Paper style={{ marginRight: 4, marginLeft: 4 }} onClick={() => handleTriggerClick('workload-forms')} className={classes.paper}>
                                <Avatar style={{ width: 30, height: 30, display: 'flex', float: 'left', marginTop: '2px', marginLeft: '0px', marginRight: '5px' }} src="https://cdn.workload.co/wp-content/uploads/workload-forms.png" >
                                    <AppsIcon style={{ width: 20, height: 20 }} />
                                </Avatar>
                                <Typography style={{ fontSize: '13px', marginTop: '2px', fontWeight: 'bold', color: 'black' }} >Forms by Workload</Typography>
                                <Typography style={{ fontSize: '11px', color: 'black', marginTop: '-1px', marginLeft: '35px' }} >Trigger workflow when a form is submitted</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
                :
                <div className={styles.Panel}>
                    <div id="headerbox" className={styles.headerbox} style={{paddingTop: '75px'}}>
                        <IconButton style={{ color: 'black', float: 'right', display: 'block', borderRadius: 50, marginTop: '-10px', marginRight: '5px' }} aria-label="close modal" onClick={() => { onPaneClick(); saveNode(); handleSaveClick(); }}>
                            <HighlightOffRoundedIcon  />
                        </IconButton>
                        {icon ? <img style={{ width: breakpoint !== 'large' ? '36px' : '48px', height: breakpoint !== 'large' ? '36px' : '48px', marginRight: '10px', float: 'left', boxShadow: '-1px 2px 3px 2px silver', borderRadius: '50%', marginLeft: breakpoint !== 'large' ? '5px' : null }} src={icon} /> : <BoltRoundedIcon style={{ color: 'gray', width: '48px', height: '48px', marginRight: '10px', float: 'left' }} />} <Input startAdornment={
                            <InputAdornment position="start">
                                <CreateRoundedIcon style={{ color: 'silver', fontSize: breakpoint !== 'large' ? '14px' : null }} />
                            </InputAdornment>
                        } style={{ marginLeft: '10px', marginTop: '5px', width: breakpoint !== 'large' ? '60%' : '80%', fontSize: breakpoint !== 'large' ? '16px' : '24px', fontWeight: 'bold' }} id="name" onChange={e => { console.log(node); setName(e.target.value); saveNode(); handleSaveClick() }} defaultValue={node.data.name} value={node.data.name === 'Choose a Trigger' ? 'Name this Step' : name} placeholder="Name this step..." />
                    </div>
                    <div style={{ float: 'clear', marginBottom: '20px' }}></div>
                    <Divider />
                    <Stepper style={{ borderRadius: '0px 0px 8px 8px' }} activeStep={activeStep} orientation='vertical' connector={<div style={{height:'20px', borderBottom: '1px solid lightgray', marginBottom:'25px' }} />} >
                        <Step key="App Step" >
                            <StepButton onClick={(e) => { setStep(0) }}><div><Typography style={{ fontSize: '18px', display:'block', float: 'left' }}><strong>App</strong></Typography>{activeStep === 0 ? <Carat active={activeStep === 0 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /> : <Carat active={activeStep === 0 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} />}</div></StepButton>
                            <StepContent>
                                <br />
                                <FormControl variant="outlined" style={{ width: '100%' }} >

                                    <Autocomplete
                                        autoComplete
                                        clearOnBlur
                                        key="app-selection"
                                        id="combo-box-demo"
                                        options={allApps}
                                        value={label}
                                        open={appopen}
                                        filterOptions={allAppsFilterOptions}
                                        onOpen={() => {
                                            setAppOpen(true);
                                        }}
                                        onClose={() => {
                                            setAppOpen(false);
                                        }}
                                        loading={apploading}
                                        getOptionLabel={(option) => option.name ?? label ?? ""}
                                        style={{ width: breakpoint !== 'large' ? '100%' : '100%', height: '60px' }}
                                        onChange={(e, v) => { v === null ? v = "" : setNodeNameAuto(v.name, undefined); setApp(v.app); sendTriggerData(v.app); setTrigger(''); setTriggerMessage(''); setTriggerChoices(''); setChoice(''); setTestData('');saveNode(); setIcon(v.icon); setHasAuth(v.auth); setLabel(v.name);setCustomButton(v.custombutton); setURL(v.formDataEndpoint); saveNode(); setStep(!v ? activeStep : activeStep + 1) }}
                                        groupBy={(option) => option.group.length > 0 ? option.group : option.category}
                                        renderOption={(option) => (
                                            <li style={{ listStyleType: 'none', padding: 2 }} id={option.name} key={option.name}>
                                                    <Box display="flex">
                                                        <Box style={{float: 'left'}}>
                                                        <Avatar style={{ padding: '4px', width: '32px', height: '32px', marginRight: '10px', float: 'left' }} src={option.icon} />
                                                    </Box>
                                                    <Typography style={{marginTop: '5px', fontWeight: 'bold', fontSize: '20px'}}>{option.name}</Typography>
                                                    </Box>
                                                </li>
                                        )}
                                        renderInput={(params) => (
                                            <div style={{ position: "relative" }}>
                                                {params.inputProps.value && (

                                                    <span>
                                                        {/* Write logic to have Icon from params.inputProps.value */}
                                                        {<Avatar style={{
                                                            position: "absolute",
                                                            transform: "translateY(40%)",
                                                            marginLeft: "10px",
                                                            width: '32px', height: '32px', marginRight: '30px'
                                                        }} src={icon} />}
                                                    </span>
                                                )}
                                                <TextField
                                                    {...params}
                                                    label="Type to search for an App"
                                                    variant="outlined"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "off",
                                                        style: { paddingLeft: "45px" } // disable autocomplete and autofill
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {allApps.length === 0 ? <CircularProgress style={{ marginRight: 20 }} color={colors[color]} size={30} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        )

                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                </FormControl>
                                <br /><br />
                                <div className={classes.triggersContainer}>
                                    <div>
                                        <Button style={{ width: breakpoint !== 'large' ? '100%' : '100%', textTransform: 'none', fontWeight: '600', fontSize: breakpoint !== 'large' ? '14px' : '18px' }} disabled={app ? 0 : 1} variant="contained" color="primary" onClick={e => { setStep(activeStep + 1); saveNode() }}>{breakpoint !== 'large' ? 'Continue' : 'Continue'}</Button>
                                    </div>
                                </div>

                            </StepContent>
                        </Step>
                        <Step key='Trigger Step' disabled={app ? 0 : 1}>
                            { !node.data.action ? <StepButton onClick={(e) => { setStep(1) }} icon={<WarningRounded style={{color: 'orange'}}/>} ><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Event</strong></Typography><Carat active={activeStep === 1 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> : <StepButton onClick={(e) => { setStep(1) }}  ><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Event</strong></Typography><Carat active={activeStep === 1 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> }
                            <StepContent>
                                {
                                    triggers ?
                                        <Autocomplete
                                            autoComplete
                                            //clearOnBlur
                                            freeSolo={true}
                                            id="choose-event-trigger"
                                            open={triggersopen}
                                            value={trigger ?? ''}
                                            onOpen={() => {
                                                setTriggersOpen(true);
                                            }}
                                            onClose={() => {
                                                setTriggersOpen(false);
                                            }}
                                            loading={!loadedTriggers}
                                            loadingText="Loading Triggers..."
                                            noOptionsText={<div><Typography color="default">Looks like no one has created a trigger for {label} yet...</Typography>
                                                <Divider /><br />
                                                <Button variant='contained'
                                                    style={{ textTransform: 'none', backgroundColor: 'black', color: 'white', fontWeight: 600 }}
                                                    onMouseDown={() => {
                                                        handleModalOpen();
                                                    }}>
                                                    + Create a new trigger
                                                </Button></div>}
                                            options={triggeroptions.sort((a,b) => a.key.localeCompare(b.key))}
                                            //groupBy={(option) => option.key}
                                            getOptionSelected={(opt, val) => opt.label == val.label}
                                            getOptionLabel={(option) => triggerlabel ?? option.label ?? option.key ?? ""}
                                            onChange={(e, v) => { v === null ? v = "" : v === trigger ? setStep(activeStep +1) :  setTriggerMessage(); setTimeLeft(61); setTestData(''); setNodeNameAuto(label, v.label); setTrigger({ ...v });setTriggerLabel(v.label); setPollingParams(v.pollingParams);setPerformList(v.performList);setUnsubscribe(v.unsubscribe); setWebhookKey(v.webhookKey);sendTriggerEventData(app, v.label); setTriggerChoiceTime(Date.now()); saveNode() }}
                                            style={{ width: '100%' }}
                                            PaperComponent={PaperComponentCustom}
                                            renderOption={(option) => (
                                                <li style={{ listStyleType: 'none', padding: 4 }} id={option.label} key={option.key}>
                                                   <Typography style={{fontWeight: 'bold', fontSize: '20px'}}>{option.label ?? option.key}</Typography>
                                                   <Typography style={{fontWeight: 300, fontSize: '16px'}}>{option.description}</Typography>
                                                </li>

                                            )}
                                            renderInput={(params) => <TextField {...params} label='Type to search for a Trigger' variant="outlined" helperText="This is what starts an automated workflow."
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {!loadedTriggers ? <CircularProgress color={colors[color]} size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }} />}

                                        />
                                        :
                                        null
                                }

                                <div className={classes.triggersContainer}>
                                    <div>
                                        
                                        <Button id='continue-button' style={{ width: breakpoint !== 'large' ? '100%' : '100%', textTransform: 'none', fontWeight: '600', fontSize: breakpoint !== 'large' ? '14px' : '18px' }} disabled={trigger.key ? 0 : 1} variant="contained" color="primary" onClick={e => {
                                            if (hasauth === false) {

                                                if (triggerMessage) {
                                                   // console.log('webhook already exists, not creating new one...')
                                                    dispatch(saveWorkflow(1))
                                                    setStep(activeStep + 1)
                                                    saveNode()
                                                } else {
                                                    if (app === 'schedule') {

                                                        setStep(activeStep + 1);
                                                       // console.log('fields: ', fields)
                                                        saveNode()
                                                    } else {
                                                        console.log('careful here...were making a TRIGGER')
                                                        dispatch(createTrigger(1));
                                                        setStep(activeStep + 1);
                                                       // console.log('fields: ', fields)
                                                        saveNode()
                                                    }

                                                }
                                            } else {
                                                setStep(activeStep + 1)
                                               // console.log('fields: ', fields)
                                            }
                                        }}>{hasauth === true ? 'Continue' : 'Continue'}</Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                        
                        {trigger.key ? hasauth ?
                            <Step key='Auth Step' disabled={trigger ? 0 : 1} >
                               
                              { !credentialId ?? credentialId === '' ? <StepButton onClick={(e) => { setStep(2) }} icon={<WarningRounded style={{color: 'orange'}} />}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Account</strong></Typography><Carat active={activeStep === 2 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> : <StepButton onClick={(e) => { setStep(2) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Account</strong></Typography><Carat active={activeStep === 2 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> }
                              
                                <StepContent>
                                    <Authorizer
                                        breakpoint={breakpoint}
                                        user={user}
                                        sendLoggedOutToAuthorizer={sendLoggedOutToAuthorizer}
                                        tenant={tenant}
                                        node={node}
                                        app={app}
                                        applabel={label}
                                        icon={icon}
                                        sendData={setCredentialId}
                                        sendAuth={setAuthname}
                                        saveNode={saveNode}
                                        value={authname}
                                        custombutton={custombutton}
                                    />
                                    <div className={classes.triggersContainer}>
                                        <div>
                                            
                                            <Button id='trigger-next' style={{ width: breakpoint !== 'large' ? '100%' : '100%', textTransform: 'none', fontWeight: '600', fontSize: breakpoint !== 'large' ? '14px' : '18px' }} disabled={credentialId ? 0 : 1} variant="contained" color="primary" onClick={fields && fields.find(item => item.hidden !== true) ? handleSetUpTrigger : handleSetUpTriggerTest} >{fields && fields.find(item => item.hidden !== true) ? 'Continue' : 'Continue' }</Button>
                                        </div>
                                    </div>
                                </StepContent>
                            </Step> :
                            null : null}
                            
                        {trigger.key ? fields ? fields.find(item => item.hidden !== true) ?
                            <Step key='Set up Trigger Step' disabled={trigger.key && (!hasauth || (hasauth && authname)) ? 0 : 1}>
                               { fields.some(e => e.value) === false ? <StepButton icon={<WarningRounded style={{color: 'orange'}} />} onClick={(e) => { app === 'schedule' ? setStep(2) : setStep(3) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Trigger</strong></Typography><Carat active={activeStep === 3 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> : <StepButton onClick={(e) => {app === 'schedule' ? setStep(2) : setStep(3) }} ><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Trigger</strong></Typography><Carat active={activeStep === 3 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> } 
                                <StepContent>
                                    <div id='trigger-setup' style={{ height: '1px' }}></div>
                                    {
                                        fields ?
                                            <FieldsRender fields={fields} setFields={setFields} parentTree={parentTree} nodeID={node.id} />
                                            : null
                                    }

                                    <div className={classes.triggersContainer}>
                                        <div>
                                           
                                            <Button style={{ width: breakpoint !== 'large' ? '100%' : '100%', textTransform: 'none', fontWeight: '600', fontSize: breakpoint !== 'large' ? '14px' : '18px' }} disabled={trigger ? 0 : 1} variant="contained" color="primary" onClick={e => {
                                                saveNode();
                                              performList ? handlePerformList() : handleSetUpTriggerTest();
                                               
                                            
                                            }}>{'Continue'}</Button>
                                        </div>
                                    </div>
                                </StepContent>
                            </Step> : null : null : null}
                           
                        <Step key='Test Step' disabled={trigger && (!hasauth || (hasauth && authname)) ? 0 : 1}>

                           { !testData && !triggerMessage || !testData && !triggerChoices ? <StepButton disabled={trigger.key && (!hasauth || (hasauth && authname)) ? 0 : 1} onClick={(e) => { app === 'schedule' ? setStep(3) : hasauth ? setStep(testStep) : setStep(testStep) }} icon={<WarningRounded style={{color: 'orange'}} />}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Test</strong></Typography><Carat active={activeStep === 99 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> : <StepButton disabled={trigger.key && (!hasauth || (hasauth && authname)) ? 0 : 1} onClick={(e) => {app === 'schedule' ? setStep(3) : hasauth ? setStep(testStep) : setStep(testStep) }} ><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Test</strong></Typography><Carat active={activeStep === 99 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> } 
                            <StepContent>
                                <div className={classes.triggersContainer}>
                                    {
                                        triggerMessage && app === 'webhook' ? <div style={{ width: breakpoint !== 'large' ? '100%' : '500px', overflowX: 'hidden' }}>
                                            <InputLabel htmlFor="outlined-adornment-password"><Typography variant="h6" style={{ color: 'black' }}><strong>Copy Webhook</strong></Typography></InputLabel>
                                            <FormHelperText id={"descriptionntext"}><Typography variant="body2">Copy this webhook URL and paste into the application you are trying to send events from.</Typography> </FormHelperText>
                                            <br />
                                            <OutlinedInput
                                                disabled
                                                style={{ width: '100%' }}
                                                id={'webhook-copy'}
                                                key={'webhook-copy'}
                                                label={'Webhook URL'}
                                                defaultValue={triggerMessage['Webhook URL']}
                                                helperText={'Copy this URL to paste into other software tools.'}
                                                variant="outlined"
                                                endAdornment={
                                                    <InputAdornment position="end" className={classes.icon}>
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(triggerMessage['Webhook URL']); 
                                                                dispatch(notification({ "status": "info", "message": "Copied to clipboard" }))
                                                            }}

                                                            edge="end"
                                                        >
                                                            <Divider orientation="vertical" flexItem style={{ marginRight: '8px' }} />
                                                            <FileCopyOutlinedIcon />

                                                        </IconButton>
                                                        <Typography style={{ marginTop: '40px', marginLeft: '-25px' }} variant="caption" display="block" gutterBottom>
                                                            copy
                                                        </Typography>
                                                    </InputAdornment>
                                                }
                                                labelWidth={70}
                                            /> </div> :
                                            triggerMessage && app === 'workload-forms' ? <div style={{ width: breakpoint !== 'large' ? '100%' : '500px', overflowX: 'hidden' }}>
                                            <InputLabel htmlFor="outlined-adornment-password"><Typography variant="h6" style={{ color: 'black' }}><strong>Copy Form URL</strong></Typography></InputLabel>
                                            <FormHelperText id={"descriptionntext"}><Typography variant="body2">Copy this URL and paste into a browser or share it with others. On the next step, you'll be asked to send a test submission to verify everything is working.</Typography> </FormHelperText>
                                            <br />
                                            <OutlinedInput
                                                disabled
                                                style={{ width: '100%' }}
                                                id={'webhook-copy'}
                                                key={'webhook-copy'}
                                                label={'Webhook URL'}
                                                defaultValue={'https://editor.workload.co/form/'+form_id}
                                                helperText={'Copy this URL to paste into a browser window to view and submit form entries.'}
                                                variant="outlined"
                                                endAdornment={
                                                    <InputAdornment position="end" className={classes.icon}>
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText('https://editor.workload.co/form/'+form_id);
                                                                dispatch(notification({ "status": "info", "message": "Copied to clipboard" }))
                                                            }}

                                                            edge="end"
                                                        >
                                                            <Divider orientation="vertical" flexItem style={{ marginRight: '8px' }} />
                                                            <FileCopyOutlinedIcon />

                                                        </IconButton>
                                                        <Typography style={{ marginTop: '40px', marginLeft: '-25px' }} variant="caption" display="block" gutterBottom>
                                                            copy
                                                        </Typography>
                                                    </InputAdornment>
                                                }
                                                labelWidth={70}
                                            /> </div> : triggerMessage || triggerChoices ?

                                            <div style={{ width: breakpoint !== 'large' ? '100%' : '100%', overflowX: 'hidden' }}>

                                                <div style={{ margin: 'auto', justifyContent: 'center', alignItems: 'center', alignText: 'center', display: 'flex' }}>
                                                    <img style={{ width: breakpoint === 'small' ? '36px' : '48px', height: breakpoint === 'small' ? '36px' : '48px' }} src={icon} />
                                                    <img style={{ width: breakpoint === 'small' ? '36px' : '48px', height: breakpoint === 'small' ? '36px' : '48px' }} src='https://cdn.workload.co/wp-content/uploads/connection-animated.gif' />
                                                    <img style={{ width: breakpoint === 'small' ? '36px' : '48px', height: breakpoint === 'small' ? '36px' : '48px', marginRight: breakpoint === 'small' ? '4px' : '10px' }} src='https://cdn.workload.co/wp-content/uploads/workload-icon.png' />
                                                    <br />
                                                </div>
                                                <div style={{ margin: 'auto', display: 'flex', alignText: 'center', justifyContent: 'center' }}>
                                                    <Typography style={{ fontWeight: 'bold', fontSize: breakpoint === 'small' ? '18px' : '22px', paddingTop: breakpoint === 'small' ? '10px' : '5px' }}>Test your trigger</Typography>
                                                </div>
                                                <div></div>
                                                <div style={{ margin: 'auto', width: '100%', padding: 1, display: 'flex', alignText: 'center', justifyContent: 'center' }}>
                                                    <Typography align='center' paragraph={true} style={{ fontWeight: 300, color: 'black', fontSize: breakpoint === 'small' ? '16px' : '18px', paddingTop: breakpoint === 'small' ? '10px' : '5px' }}>Find recent data in your <strong>{label}</strong> account to make sure your trigger is set up correctly and connected.</Typography>
                                                </div>

                                            </div>

                                            :
                                            null
                                    }
                                    <div><br /> <br />

                                        {
                                            app == 'schedule' || type == 'polling' ?
                                                triggerMessage || triggerChoices  ? <Button id='polling-button' style={{ width: '100%', textTransform: 'none', fontSize: '18px' }} variant='contained' color='primary' onClick={() => { setStep(99); setTestData(app == 'schedule' ? triggerMessage : triggerChoices[0]);setChoice(triggerlabel +" "+1); saveNode(); dispatch(saveWorkflow(1)) }} className={classes.button} >
                                                    Test Trigger
                                                </Button> : !testData ? <CircularProgress style={{ marginLeft: '30%', marginTop: '20px' }} id='circularprogresscolor' color={colors[color]} size={50} /> : null :
                                                triggerMessage || triggerChoices ? <> <Button id='webhook-button' style={{ width: '100%', textTransform: 'none', fontSize: '18px' }} variant="contained" color="primary" onClick={() => {
                                                    
                                                    setTestData(triggerChoices ? triggerChoices[0] : null)
                                                    setChoice(triggerlabel +" "+1)
                                                    saveNode()
                                                   // console.log('trigger load data button was clicked')
                                                    setAwaitingTrigger(performList ? false : true)
                                                    setTimeLeft(performList ? 61 : timeLeft - 1)
                                                    Promise.all([dispatch(activeUpdated(triggerChoices ? false : true)), dispatch(saveWorkflow(1))])
                                                    setStep(99);
                                                   


                                                }}>
                                                    Test Trigger
                                                </Button> 
                                                <Button id='custom-webhook-button' style={{ width: '100%', textTransform: 'none', fontSize: '16px', marginTop: '25px' }} color="default" onClick={(e) => {
                                                    handlePopClick(e);
                                                   // console.log('custom load data button was clicked')
                                                }}>
                                                    Customize Trigger Data
                                                </Button>
                                                <Popper placement="left" id={id} open={openpop} anchorEl={anchorElPop} style={{ zIndex: 99 }}>
                                            <div className={classes.popper}>
                                                <React.Fragment>
                                                    <Typography color="inherit"><h4>Inject Sample Data</h4></Typography>
                                                    <em>{"Add JSON Object to simulate a webhook payload."}</em> 
                                                    <br /><br />
                                                    <TextareaAutosize defaultValue={poptext} value={poptext} onChange={(e) => setPopText(e.target.value)} style={{ width: '350px', maxWidth: '350px', height: '200px', borderColor: 'lightgray', maxHeight: '250px', overflowY: 'scroll' }} aria-label="minimum height" minRows={40} placeholder='{"data":{&#10;"key1":"value1",&#10;"key2":"value2"&#10; }&#10;}' />
                                                    <br /><br />
                                                    <Button onClick={handlePopClose} style={{ marginRight: '5px' }}>Close</Button><Button onClick={() => { setTestData(JSON.parse(poptext)); handlePopClose(); setStep(99) }} variant="contained" color="primary">Submit</Button>
                                                </React.Fragment>
                                            </div>
                                        </Popper> </>: <CircularProgress style={{ marginLeft: '30%', marginTop: '20px' }} id='circularprogresscolor' color={colors[color]} size={30} />
                                        }
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    </Stepper>
                    <div style={{ width: '90%', height: '1px' }}></div>
                    {activeStep === 88 && triggerError ? (
                        <Paper square elevation={0} className={classes.resetContainer}>
                             <Alert severity="error" variant="outlined" style={{borderLeft: '4px solid red'}}>
                                    <strong>{triggerError.error.name}</strong> - {triggerError.error.message}<br/>Request ID: {triggerError.meta.requestId}
                                </Alert>
                                <div style={{margin:'auto', width: '500px',justifyContent: 'center', alignText: 'center'}}>
                                   <div style={{ width: '100%', alignText: 'center', padding: '10px', marginTop: '25px'}}> <FlashOffRoundedIcon style={{color: 'gray', fontSize: '44px',display: 'flex', justifyContent:'center',margin: 'auto', left: 50,right:50}} /> </div>
                                   <div style={{ width: '100%', alignText: 'center', padding: '10px', marginBottom: '25px'}}> <Typography style={{color: 'gray', fontSize: '32px',display: 'flex', justifyContent:'center',margin: 'auto', left: 50,right:50}}>Nothing found...</Typography> </div>
                                    </div>
                                    <Button style={{width: '100%', textTransform: 'none', marginBottom: '20px'}} disabled={nosave} variant='contained' color='primary' onClick={() => { saveNode(); dispatch(saveWorkflow(1)); sendAlert(); setStep(1) }}>
                                            <Typography style={{fontSize: '20px'}}>Try again</Typography>
                                        </Button>
                                        <Button style={{width: '100%', textTransform: 'none'}} variant='text' onClick={() => { saveNode(); dispatch(saveWorkflow(1)); onPaneClick(); sendAlert(); handleNextNodeOpen() }} >
                                            Skip & continue
                                        </Button>
                                
                                            
                            
                            </Paper>
                    ) : null}
                    {activeStep === 99 ? (

                        <Paper square elevation={0} className={classes.resetContainer}>

                            {
                               !triggerChoices && !triggerMessage && awaitingTrigger === true ? <>
                                    <div style={{ margin: 'auto', justifyContent: 'center', alignItems: 'center', alignText: 'center', display: 'flex' }}>
                                        <img style={{ width: breakpoint === 'small' ? '36px' : '48px', height: breakpoint === 'small' ? '36px' : '48px' }} src={icon} />
                                        <img style={{ width: breakpoint === 'small' ? '36px' : '48px', height: breakpoint === 'small' ? '36px' : '48px' }} src='https://cdn.workload.co/wp-content/uploads/connection-animated.gif' />
                                        <img style={{ width: breakpoint === 'small' ? '36px' : '48px', height: breakpoint === 'small' ? '36px' : '48px', marginRight: breakpoint === 'small' ? '4px' : '10px' }} src='https://cdn.workload.co/wp-content/uploads/workload-icon.png' />
                                        <br />
                                    </div>
                                    <div style={{ margin: 'auto', display: 'flex', alignText: 'center', justifyContent: 'center' }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: breakpoint === 'small' ? '18px' : '22px', paddingTop: breakpoint === 'small' ? '10px' : '5px' }}>We're listening for data</Typography>
                                    </div><br/><br/>
                                    <Button variant="outlined" style={{marginTop: '16px', marginBottom: '16px', textTransform: 'none', margin: 'auto', display: 'flex', alignText: 'center', justifyContent: 'center' }} onClick={()=>  {
                                        setAwaitingTrigger(false);
                                        dispatch(activeUpdated(false));
                                        dispatch(saveWorkflow(1));
                                        handleSaveClick();
                                        setTimeLeft(61);
                                    setStep(testStep)}} className={classes.button}>Cancel</Button><br/><br/>

                                   {/*  <Box position="relative" display="inline-flex" style={{ width: '100%', margin: 'auto', marginTop: '20px', marginBottom: '20px', justifyContent: 'center', alignItems: 'center' }}>
                                        <CircularProgress
                                            // style={{ }} 
                                            variant='determinate'
                                            value={timeLeft / 61 * 100}
                                            id='circularprogresscolor'
                                            color="primary"
                                            size={60} />
                                        <Box
                                            top={18}
                                            left={10}
                                            bottom={0}
                                            width='97%'
                                            position="absolute"
                                            display="flex"
                                            alignnItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography style={{ fontWeight: 'bold', fontSize: '18px' }} component="div" color="black">{timeLeft}s</Typography>
                                        </Box>
                                    </Box> */}
                                    </>
                                    : null
                            }
                            {
                                triggerChoices  ?
                                    <Fragment>

                                        <Alert severity="success" variant="outlined" style={{borderWidth: '0px 0px 0px 6px', borderStyle: 'none none none solid'}}>
                                        <AlertTitle>{triggerlabel} found!</AlertTitle>
                                            {triggerlabel} was found in your {label} account...
                                        </Alert>
                                        {/* <IconButton color="primary" style={{ color: 'black', float: 'right', borderRadius: 50, marginTop: '-10px', marginRight: '15px' }} aria-label="close modal" onClick={handlePopClick}>
                                            <SettingsEthernetRoundedIcon style={{ color: 'black' }} />
                                        </IconButton>
                                        <Popper placement="left" id={id} open={openpop} anchorEl={anchorElPop} style={{ zIndex: 99 }}>
                                            <div className={classes.popper}>
                                                <React.Fragment>
                                                    <Typography color="inherit"><h4>Inject Sample Data</h4></Typography>
                                                    <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                                                    {"It's very engaging. Right?"}<br /><br />
                                                    <TextareaAutosize defaultValue={poptext} value={poptext} onChange={(e) => setPopText(e.target.value)} style={{ width: '350px', maxWidth: '350px', height: '200px', borderColor: 'lightgray', maxHeight: '250px', overflowY: 'scroll' }} aria-label="minimum height" minRows={40} placeholder='{"data":{&#10;"key1":"value1",&#10;"key2":"value2"&#10; }&#10;}' />
                                                    <br /><br />
                                                    <Button onClick={handlePopClose} style={{ marginRight: '5px' }}>Close</Button><Button onClick={() => { setTestData(JSON.parse(poptext)); handlePopClose() }} variant="contained" color="primary">Submit</Button>
                                                </React.Fragment>
                                            </div>
                                        </Popper> */}
                                         <Select
                                            MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                            }}
                                            id='trigger-select'
                                            style={{ width: '100%', padding: 0, marginTop: '12px', marginBottom: '12px' }}
                                            labelId="auth-select-outlined-label"
                                            defaultValue={triggerchoice}
                                            value={triggerchoice ?? triggerlabel+" 1"}
                                            renderValue={(value) => (<div><ListItemIcon style={{ float: 'left', display: 'block'}}>
                                            <Avatar style={{width: breakpoint === 'small' ? '18px' : '26px', height: breakpoint === 'small' ? '18px' : '26px' }} src={icon} />
                                        </ListItemIcon><Typography style={{ fontWeight: 'bold', fontSize: '20px',marginLeft: '8px', float:'left', display: 'block'}} >{value}</Typography></div>)}
                                            variant="outlined"
                                            //onChange={handleTriggerChoiceChange}
                                            placeholder={"Choose another trigger"}
                                            //onFocus={() => {setTimeStamp(Date.now()); console.log('timestamp is: ', timestamp); console.log('triggerChoiceTime: ', triggerChoiceTime)}}
                                            ><Typography style={{padding: '10px', fontWeight: 700}} variant="h6" >Choose another...</Typography>
                                            <Divider style={{marginBottom: '6px'}} />
                                            { triggerChoices.map((item, i) => loadingchoices === true ? <Skeleton animation="wave" height={85} style={{marginTop: '-25px'}} /> : <MenuItem onClick={() => setChoiceTestData(triggerChoices[i], i)} id={i} key={i} value={triggerlabel +" "+(i+1)}> <ListItemIcon style={{ float: 'left', display: 'block'}}>
                                            <Avatar style={{width: breakpoint === 'small' ? '18px' : '26px', height: breakpoint === 'small' ? '18px' : '26px' }} src={icon} />
                                        </ListItemIcon><Typography style={{ fontWeight: 'bold', fontSize: '20px',marginLeft: '8px', float:'left', display: 'block'}} >{triggerlabel} {i + 1}<br/><div style={{fontSize: '12px', fontWeight: 300}}>{"Fetched "+timeSince(triggerChoiceTime)+" ago"}</div></Typography>
                                      </MenuItem>)}
                                      <div>
                                        <br /><button size="small" id='new-auth-button' labelId='new-auth-button-label'
                                        className={classes.loadbutton} color="primary"
                                        variant="outlined"
                                        onClick={(e) => {handleLoadMorePerformList(e); setTriggerChoiceTime(Date.now()); saveNode()}}
                                        >{loadingchoices === true ? <CircularProgress size={18} color={colors[color]} /> : <Typography style={{ color: '#0069ea', fontSize: breakpoint !== 'large' ? '12px' : null }} >Load More</Typography>}</button>
                                        </div>
                                        </Select> 
                                        <div id='inspector-json' style={{ maxHeight: '350px', overflowX: 'hidden', overflowY: 'scroll' }}>
                                        
                                            <DataInspector data={testData}  />
                                        </div>
                                        <br /><br />

                                        <div id='trigger-save' style={{ width: '90%', height: '1px' }}></div>
                                        
                                        <Button style={{width: '100%', textTransform: 'none', marginBottom: '20px'}} disabled={nosave} variant='contained' color='primary' onClick={() => { saveNode(); dispatch(saveWorkflow(1)); sendAlert(); handleNextNodeOpen(); }}>
                                            <Typography style={{fontSize: '20px'}}>Continue</Typography>
                                        </Button>
                                        <Button style={{width: '100%', textTransform: 'none'}} variant='text' onClick={() => { saveNode(); dispatch(saveWorkflow(1)); onPaneClick(); sendAlert(); }} >
                                            Close
                                        </Button>


                                    </Fragment> : testData && !triggerChoices ? 

                                    <Fragment>

                                    <Alert severity="success" variant="outlined" style={{borderLeft: '4px solid green'}}>
                                    <AlertTitle>{triggerlabel} found!</AlertTitle>
                                        {triggerlabel} was found in your {label} account...
                                    </Alert>
                                    {/* <IconButton color="primary" style={{ color: 'black', float: 'right', borderRadius: 50, marginTop: '-10px', marginRight: '15px' }} aria-label="close modal" onClick={handlePopClick}>
                                        <SettingsEthernetRoundedIcon style={{ color: 'black' }} />
                                    </IconButton>
                                    <Popper placement="left" id={id} open={openpop} anchorEl={anchorElPop} style={{ zIndex: 99 }}>
                                        <div className={classes.popper}>
                                            <React.Fragment>
                                                <Typography color="inherit"><h4>Inject Sample Data</h4></Typography>
                                                <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                                                {"It's very engaging. Right?"}<br /><br />
                                                <TextareaAutosize defaultValue={poptext} value={poptext} onChange={(e) => setPopText(e.target.value)} style={{ width: '350px', maxWidth: '350px', height: '200px', borderColor: 'lightgray', maxHeight: '250px', overflowY: 'scroll' }} aria-label="minimum height" minRows={40} placeholder='{"data":{&#10;"key1":"value1",&#10;"key2":"value2"&#10; }&#10;}' />
                                                <br /><br />
                                                <Button onClick={handlePopClose} style={{ marginRight: '5px' }}>Close</Button><Button onClick={() => { setTestData(JSON.parse(poptext)); handlePopClose() }} variant="contained" color="primary">Submit</Button>
                                            </React.Fragment>
                                        </div>
                                    </Popper> */}
                                    
                                    <div id='inspector-json' style={{ maxHeight: '350px', overflowX: 'hidden', overflowY: 'scroll' }}>

                                        <DataInspector data={testData}  />
                                    </div>
                                    <br /><br />

                                    <div id='trigger-save' style={{ width: '90%', height: '1px' }}></div>

                                    <Button style={{width: '100%', textTransform: 'none', marginBottom: '20px'}} disabled={nosave} variant='contained' color='primary' onClick={() => { saveNode(); dispatch(saveWorkflow(1)); sendAlert(); handleNextNodeOpen(); }}>
                                        <Typography style={{fontSize: '20px'}}>Continue</Typography>
                                    </Button>
                                    <Button style={{width: '100%', textTransform: 'none'}} variant='text' onClick={() => { saveNode(); dispatch(saveWorkflow(1)); onPaneClick(); sendAlert(); }} >
                                        Close
                                    </Button>


                                    </Fragment>


                                    :
                                    awaitingTrigger === true ?
                                        <Fragment>
                                            <Alert severity="info">
                                            <Typography variant='h5'>Action required!</Typography><br/>
                                                <AlertTitle>Waiting for data from {triggerlabel}...</AlertTitle>
                                                
                                                {/* Workload is waiting for you to perform a <strong>{triggerlabel}</strong> using <strong>{label}</strong>.<br /> */}
                                                1. Log into your <strong>{label}</strong> account.<br />
                                                2. Once in <strong>{label}</strong>, do something that would trigger a <strong>{triggerlabel}</strong>.<br />
                                                3. Data from the <strong>{triggerlabel}</strong> will appear here.<br />
                                                4. You can map this data in your workflow steps.<br />
                                            </Alert>
                                        </Fragment> : null
                            }
                        </Paper>
                       

                    ) :
                        null}
                    <div ref={bottomRef} />
                    <Modal
        open={modalopen}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalbody}
      </Modal>
                </div>

        )
    } catch (e) {
        var config = {
            method: 'post',
            url: 'https://api.airtable.com/v0/appb76LyMibAJXsfE/FrontendErrors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer key3FrNljA0k5KIGo'
            },
            data: {
                'records': [
                    {
                        'fields': {
                            'Error source': 'Trigger Panel',
                            'Error': e.toString()
                        }
                    }
                ]
            }
        }

        axios(config)

        onPaneClick();
    }
}

export default TriggerPanel

