import Form from '@rjsf/material-ui'
import React, { useState, Fragment, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { Button, Fab,MenuItem, Accordion, AccordionSummary,Tab, AccordionDetails,
     CircularProgress, Tooltip, Divider, List, ListItem, ListItemText, Typography, Grid, Paper,
      Box, TextField, Stepper, Step, StepButton, StepContent, IconButton, Menu, Card, Chip, Tabs,
       Switch, FormControlLabel, Table, TableHead, TableContainer, TableRow, TableCell, TableBody, TablePagination, TableFooter  } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import axios from 'axios';
import { AppBarHeaderDummy } from "../components/AppBarIntegrator";
import { makeStyles } from '@material-ui/core/styles';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Controlled as CodeMirror2 } from 'react-codemirror2';
import 'codemirror/theme/darcula.css';

import { BuildRounded, ExpandMoreRounded, OfflineBoltRounded, SettingsInputAntenna, SettingsRounded, SpellcheckRounded, VpnKeyRounded } from '@material-ui/icons';
require('codemirror/mode/python/python')


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

Function.deserialise = function (key, data) {
    return (data instanceof Array && data[0] == 'window.Function') ?
        new (Function.bind.apply(Function, [Function].concat(data[1], [data[2]]))) :
        data
        ;
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

Function.prototype.toJSON = function () {
    var whitespace = /\s/;
    var pair = /\(\)|\[\]|\{\}/;

    var args = new Array();
    var string = this.toString();

    var fat = (new RegExp(
        '^\s*(' +
        ((this.name) ? this.name + '|' : '') +
        'function' +
        ')[^)]*\\('
    )).test(string);

    var state = 'start';
    var depth = new Array();
    var tmp;

    for (var index = 0; index < string.length; ++index) {
        var ch = string[index];

        switch (state) {
            case 'start':
                if (whitespace.test(ch) || (fat && ch != '('))
                    continue;

                if (ch == '(') {
                    state = 'arg';
                    tmp = index + 1;
                }
                else {
                    state = 'singleArg';
                    tmp = index;
                }
                break;

            case 'arg':
            case 'singleArg':
                var escaped = depth.length > 0 && depth[depth.length - 1] == '\\';
                if (escaped) {
                    depth.pop();
                    continue;
                }
                if (whitespace.test(ch))
                    continue;

                switch (ch) {
                    case '\\':
                        depth.push(ch);
                        break;

                    case ']':
                    case '}':
                    case ')':
                        if (depth.length > 0) {
                            if (pair.test(depth[depth.length - 1] + ch))
                                depth.pop();
                            continue;
                        }
                        if (state == 'singleArg')
                            throw '';
                        args.push(string.substring(tmp, index).trim());
                        state = (fat) ? 'body' : 'arrow';
                        break;

                    case ',':
                        if (depth.length > 0)
                            continue;
                        if (state == 'singleArg')
                            throw '';
                        args.push(string.substring(tmp, index).trim());
                        tmp = index + 1;
                        break;

                    case '>':
                        if (depth.length > 0)
                            continue;
                        if (string[index - 1] != '=')
                            continue;
                        if (state == 'arg')
                            throw '';
                        args.push(string.substring(tmp, index - 1).trim());
                        state = 'body';
                        break;

                    case '{':
                    case '[':
                    case '(':
                        if (
                            depth.length < 1 ||
                            !(depth[depth.length - 1] == '"' || depth[depth.length - 1] == '\'')
                        )
                            depth.push(ch);
                        break;

                    case '"':
                        if (depth.length < 1)
                            depth.push(ch);
                        else if (depth[depth.length - 1] == '"')
                            depth.pop();
                        break;
                    case '\'':
                        if (depth.length < 1)
                            depth.push(ch);
                        else if (depth[depth.length - 1] == '\'')
                            depth.pop();
                        break;
                }
                break;

            case 'arrow':
                if (whitespace.test(ch))
                    continue;
                if (ch != '=')
                    throw '';
                if (string[++index] != '>')
                    throw '';
                state = 'body';
                break;

            case 'body':
                if (whitespace.test(ch))
                    continue;
                string = string.substring(index);

                if (ch == '{')
                    string = string.replace(/^{\s*(.*)\s*}\s*$/, '$1');
                else
                    string = 'return ' + string.trim();

                index = string.length;
                break;

            default:
                throw '';
        }
    }

    return ['window.Function', args, string];
};

const useStyles = makeStyles((theme) => ({
    root: {
       display: 'flex',
       
        paddingRight: '12px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.default,
        paddingRight: 20,
        marginTop: 50
    },
    fab: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 9
    },
}));
const borderlines = ["lightcoral", "blue", "green", "indigo","gold", "fuchsia", "lightblue", "lightgreen","lightcoral", "blue", "green", "indigo","gold", "fuchsia", "lightblue", "lightgreen","lightcoral", "blue", "green", "indigo","gold", "fuchsia", "lightblue", "lightgreen"]
const ObjectFieldTemplate = ({ TitleField, properties, title, description }, props) => {
 
    return (
      <div>
        <TitleField title={title} />
        <div className="row">
          {properties.map(prop => (
            <div
              className="col-lg-2 col-md-4 col-sm-6 col-xs-12"
              key={prop.content.key}>
              {prop.content}
            </div>
          ))}
        </div>
        {description}
      </div>
    );
  }

const ArrayFieldTemplate = (props)=> {
  
   function getTitle(){
    console.log('formcontext:', props.formContext)
   // console.log('prps:', props)
   const body = props.formContext.filter(body => body.name === 'body')[0]
   const arrays = body && body.schema.properties ? body.schema.properties.filter(body => body.schema.items) : []
  // console.log('body === ', body.schema.properties)
  // console.log('arrays ==== ', arrays)
   // const title_obj = arrays.filter(data =>  data.schema.items.properties.includes(props.formData))
   // console.log('title obj === ', title_obj)
   let arrayFilter = props.formData.map(itemY => { return itemY; });
   // console.log('arrayFilter::: ', arrayFilter)
    let compareTwoArrayOfObjects = (
        first_array_of_objects,
        second_array_of_objects
    ) => {
        return (
            first_array_of_objects.length === second_array_of_objects.length &&
            first_array_of_objects.every((element_1) =>
                second_array_of_objects.some(
                    (element_2) =>
                        element_1.key === element_2.key 
                )
            )
        );
    };
   // Use filter and "not" includes to filter the full dataset by the filter dataset's val.
   let filteredX = arrays.filter(array => array.schema.items.properties && array.schema.items.properties.length === arrayFilter.length && compareTwoArrayOfObjects(arrayFilter,array.schema.items.properties) === true);
  
   // Print the result.
  // console.log('title OBJ:: ',filteredX);
    const new_title = filteredX[0]?.title ? filteredX[0].title : "Body"
    return new_title
   }

    return (
        <div style={{border: '1px solid black', borderRadius: '5px', padding: '8px', margin: '8px'}}>
        <Typography variant="h5">{ props.title ?? props.schema.title}</Typography>
        <Typography>{props.schema.description}</Typography>
        {props.items &&
          props.items.map((element, index) => (
            <div key={element.key} className={element.className} style={{border: '2px solid', borderRadius: '5px', borderColor: borderlines[index], padding: '8px', margin: "8px 0px 8px 0px"}}>
              <div>{element.children}</div>
              <div style={{float: "right"}}>
              {element.hasMoveDown && (
                  <IconButton  style={{cursor: "pointer", backgroundColor: 'white', color: 'black', padding: '8px 12px 8px 12px'}}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index + 1
                  )}>
                  <ArrowDownwardRoundedIcon />
                </IconButton>
              )}
              {element.hasMoveUp && (
                  <IconButton  style={{cursor: "pointer", backgroundColor: 'white', color: 'black', padding: '8px 12px 8px 12px'}}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index - 1
                  )}>
                  <ArrowUpwardRoundedIcon />
                </IconButton>
              )}
              <Tooltip title={"Delete"}>
             <IconButton style={{cursor: "pointer", backgroundColor: 'white', color: borderlines[index], padding: '8px 12px 8px 12px', border: '1px solid',borderColor: borderlines[index], borderRadius: '5px', marginBottom: '8px'}}
                onClick={element.onDropIndexClick(element.index)}>
                <ClearRoundedIcon />
              </IconButton></Tooltip>
            </div>
            <hr style={{marginTop: '8px', marginBottom: '8px', width: "100%"}} />
            </div>
          ))}
  
        {props.canAdd  && (
          <div className="row">
            <p className="col-xs-3 col-xs-offset-9 array-item-add text-right">
              <Button onClick={props.onAddClick} type="button" variant="contained" style={{textTransform: "none", color: "white", backgroundColor: "black", float: "right", padding: '4px 8px 4px 8px', margin: '12px 0px 12px 0px'}}>
                + Add to { props.title ?? props.schema.title} 
              </Button>
            </p>
          </div>
        )}
      </div>
    );
  }

const regexErrors = (es) => {
    return es.map(e => {
        if (e.stack.includes('should match pattern \"^[a-zA-Z\\d-_]*$\"')) {
            e.message = 'only letters, numbers, hyphens, and underscores'
        }
        return e
    })
}

const sortObj = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})

const ActionForm = ({ action, onChange, fieldDelete, appInfo, mode, setAppInfo }) => {

   
  

    const actionSettingsSchema = {
        "type": "object",
        "properties": {
            "key": {
                "type": "string",
                "pattern": "^[a-zA-Z\\d-_]*$"
            },
            "title": {
                "type": "string"
            },
            "description": {
                "type": "string"
            },
            "url": {
                "type": "string",
                "description": "Use ${formFields.XXX} when you need to reference data from the Auth section. Otherwise, use {XXX} to reference a path parameter."
            },
            "method": {
                "type": "string",
                "enum": [
                    "get",
                    "post",
                    "put",
                    "patch",
                    "delete"
                ]
            },
            "hidden": {
                "type": "boolean",
                "title": "Is Action Hidden?"
            }
        },
        "$defs": {
            "jsonSchema": {
                "title": "Field Type",
                "type": "object",
                "properties": {
                    "type": {
                        "type": "string",
                        "enum": [
                            "string",
                            "number",
                            "object",
                            "array",
                            "boolean"
                        ]
                    },
                    "dynamic": {
                        "type": "string",
                        "description": "ex: appSlug.Label.getListActionKey.listHead.listValue.listLabel\n\nappSlug = "+appInfo.appSlug+"\n\nLabel = What user sees i.e. \"Choose your Label\"\n\ngetListActionKey = The key of another action in this app to call in order to get a list of items for user to choose from\n\nlistHead = The name of the array which is at the head of the array (before the [ bracket) like this  (e.g. \"SPACES\": [ \"id\": 1234, \"name\": \"Joe's Workspace\")\n\nlistValue = Within the array, what is the label for the id or value to be used by this action you're setting up (e.g.  \"spaces\": [ \"ID\": 1234, \"name\": \"Joe's Workspace\")\n\nlistLabel = What the user sees to help them know what value they're selecting  (e.g.  \"spaces\": [ \"id\": 1234, \"NAME\": \"Joe's Workspace\")"
                    },
                    "params": {
                        "type": "array",
                        "title": "Params",
                        "description": "Insert field names from the dynamic action that need to populated/referenced from other fields in this lookup step. i.e. spreadsheet.",
                        "items": {
                            "type": "object",
                            "properties": {
                                "name": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "perform": {
                        "type": "string",
                        "description": "ex: "+appInfo.appSlug+".Spreadsheets.getFiles.results.id.name (appSlug.DisplayedAs.action.data_head.value.human_readable)"
                    },
                },
                "required": ["type"],
                "dependencies": {
                    "type": {
                        "oneOf": [
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["string"]
                                    },
                                    "Is static list?": {
                                        "type": 
                                            "string",
                                            "enum": ["Yes", "No"]
                                      }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["number"]
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["boolean"]
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["array"]
                                    },
                                    "items": {
                                        "$ref": "#/$defs/jsonSchema"
                                    },
                                    "hidden": {
                                        "type": "boolean",
                                        "title": "Is Hidden?"
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["object"]
                                    },
                                    "properties": {
                                        "type": "array",
                                        "items": {
                                            "type": "object",
                                            "properties": {
                                                "key": {
                                                    "type": "string"
                                                },
                                                "title": {
                                                    "type": "string"
                                                },
                                                "description": {
                                                    "type": "string"
                                                },
                                                "hidden": {
                                                    "type": "boolean",
                                                    "title": "Is Object Property Hidden?"
                                                },
                                                "schema": {
                                                    "$ref": "#/$defs/jsonSchema"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    },
                    "Is static list?" : {
                        "oneOf": [ {
                            "properties": {
                              "Is static list?": {
                                "enum": [
                                  "No"
                                ]
                              }
                            }
                          },
                          {
                            "properties": {
                                "Is static list?": {
                                    "enum": ["Yes"]
                                },
                                "enum": {
                                    "type": "array",
                                    "items": {
                                        "type": "string"
                                    }
                                },
                                "enumNames": {
                                    "type": "array",
                                    "items": {
                                        "type": "string"
                                    }
                                }
                            }
                        }]
                    }
                }
            }
        }
    }

    const actionInputSchema = {
                "required": ["name","title", "schema"],
                    "type": "object",
                    "properties": {
                        "name": {
                            "type": "string",
                            "title": "Key",
                            "description": "What is the main object (noun) in the name above? Use the same key as your API requires for this field item."
                        },
                        "title": {
                            "type": "string",
                            "title": "Label",
                            "description": "Add the name for what users should enter in this field. Example: Ticket Name"

                        },
                        "description": {
                            "type": "string",
                            "title": "Help Text",
                            "description": "Add an additional explanation of what to enter, with Markdown formatting and at least 20 characters."
                        },
                        "hidden": {
                            "type": "boolean",
                            "title": "Is Field Hidden?"
                        },
                        "in": {
                            "type": "string",
                            "enum": [
                                "query",
                                "path",
                                "header",
                                "formData"
                            ]
                        },
                        
                        "required": {
                            "type": "boolean",
                            "title": "Is Field Required?"
                        },
                        "schema": {
                            "$ref": "#/$defs/jsonSchema"
                        },
                        "value": {
                            "type": "string"
                        }
                    },
        "$defs": {
            "jsonSchema": {
                "title": "Field Type",
                "type": "object",
                "properties": {
                    "type": {
                        "type": "string",
                        "title": "Type",
                        "description": "What type of data does your API expect?",
                        "enum": [
                            "string",
                            "number",
                            "datetime",
                            "boolean"
                        ]
                    },
                    "dynamic": {
                        "type": "string",
                        "title": "Dynamic Dropdown Configuration",
                        "description": "ex: appSlug.Label.getListActionKey.listHead.listValue.listLabel\n\nappSlug = "+appInfo.appSlug+"\n\nLabel = What user sees i.e. \"Choose your Label\"\n\ngetListActionKey = The key of another action in this app to call in order to get a list of items for user to choose from\n\nlistHead = The name of the array which is at the head of the array (before the [ bracket) like this  (e.g. \"SPACES\": [ \"id\": 1234, \"name\": \"Joe's Workspace\")\n\nlistValue = Within the array, what is the label for the id or value to be used by this action you're setting up (e.g.  \"spaces\": [ \"ID\": 1234, \"name\": \"Joe's Workspace\")\n\nlistLabel = What the user sees to help them know what value they're selecting  (e.g.  \"spaces\": [ \"id\": 1234, \"NAME\": \"Joe's Workspace\")"
                    },
                    "params": {
                        "type": "array",
                        "title": "Params",
                        "description": "Insert field names from the dynamic action that need to populated/referenced from other fields in this lookup step. i.e. spreadsheet.",
                        "items": {
                            "type": "object",
                            "properties": {
                                "name": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "perform": {
                        "type": "string",
                        "title": "Perform",
                        "description": "ex: "+appInfo.appSlug+".Spreadsheets.getFiles.results.id.name (appSlug.DisplayedAs.action.data_head.value.human_readable)"
                    },
                },
                "required": ["type"],
                "dependencies": {
                    "type": {
                        "oneOf": [
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["string"]
                                    },
                                    "Is static list?": {
                                        "type": 
                                            "string",
                                            "enum": ["Yes", "No"]
                                      }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["number"]
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["datetime"]
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["boolean"]
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["array"]
                                    },
                                    "items": {
                                        "$ref": "#/$defs/jsonSchema"
                                    },
                                    "hidden": {
                                        "type": "boolean",
                                        "title": "Is Hidden?"
                                    }
                                }
                            },
                            {
                                "properties": {
                                    "type": {
                                        "enum": ["object"]
                                    },
                                    "properties": {
                                        "type": "array",
                                        "items": {
                                            "type": "object",
                                            "properties": {
                                                "key": {
                                                    "type": "string"
                                                },
                                                "title": {
                                                    "type": "string"
                                                },
                                                "description": {
                                                    "type": "string"
                                                },
                                                "hidden": {
                                                    "type": "boolean",
                                                    "title": "Is Object Property Hidden?"
                                                },
                                                "schema": {
                                                    "$ref": "#/$defs/jsonSchema"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    },
                    "Is static list?" : {
                        "oneOf": [ {
                            "properties": {
                              "Is static list?": {
                                "enum": [
                                  "No"
                                ]
                              }
                            }
                          },
                          {
                            "properties": {
                                "Is static list?": {
                                    "enum": ["Yes"]
                                },
                                "enum": {
                                    "type": "array",
                                    "items": {
                                        "type": "string"
                                    }
                                },
                                "enumNames": {
                                    "type": "array",
                                    "items": {
                                        "type": "string"
                                    }
                                }
                            }
                        }]
                    }
                }
            }
        }
    }

      //converts the action schema to a form to fill out
      const actionToForm = (actSch, title) => {
        console.log('actSch == ', actSch)
        if (actSch.$ref) {
           
            return actSch
        }
        if (actSch.type == 'object') {
          
            var propsArr = []
            Object.entries(actSch.properties).forEach(([k, v]) => {
              
                propsArr.push({
                    key: k,
                    title: v.title,
                    description: v.description,
                    hidden: v.hidden,
                    schema: actionToForm(v)
                })
            })
            return {
                "type": "object",
                "properties": propsArr,
                "title": title
            }
        } else if (actSch.type == 'array') {
          
            return {
                "type": "array",
                "items": actionToForm(actSch.items, actSch.title),
                "title": actSch.title
            }
        } else {
            
            return actSch
        }
    }

    
    

    //converts the field form value to its corresponding action value
    const formToAction = (formSch) => {
        console.log('forSch ==== ', formSch)
         if (formSch.$ref) {
            console.log('we are up here in nomans land')
            return formSch
        } 

        if (formSch.type == 'object') {
            console.log('it is an object this time...')
            var propsObj = {}
            formSch.properties?.forEach((prop) => {
                
                propsObj[prop.key] = { ...formToAction(prop.schema), title: prop.title, description: prop.description, hidden: prop.hidden }
                console.log('propsbe to: ', propsObj)
            })
            return {
                "type": "object",
                "properties": propsObj
            }
        } else if (formSch.schema?.type == 'array') {
            console.log('it is an array this time...', formSch)
            return {
                "type": "array",
                "items": formToAction(formSch.schema.items)
            }
        } else {
            console.log('we are down here in nomans land')
            return formSch
        }
    }

    //decode dynamic form string to a json schema form
    const dynamicActionToForm = (dynamicDataString) => {
        if (!dynamicDataString) {
            return null
        }

        return JSON.parse(dynamicDataString, Function.deserialise)
    }

    const dynamicFormToAction = (dynamicForm) => {
        if (!dynamicForm) {
            return null
        }

        return JSON.stringify(dynamicForm)
    }

    const customActionToForm = (customDataString) => {
        if (!customDataString) {
            return null
        }

        return  customDataString
    }

    const customFormToAction = (customForm) => {
        if (!customForm) {
            return null
        }

        return customForm
    }

    //convert the true action object value into the form version value
    const actionObj = {
        ...action,
        dynamicForm:  action ? dynamicActionToForm(action.dynamicForm) : {} ,
        customForm: action ? customActionToForm(action.customForm) : {},
        fields:  action ? action.fields.map(field => {
            return {
                ...field,
                schema: actionToForm(field.schema)
            }
        }) : {}
    }
//console.log('actionobj.....: ', actionObj)
   // const [actionbutton, setActionButton] = useState(false);

    const codeMirrorOptions = {
        mode: "javascript",
        lineNumbers: true,
        cursorHeight: 1,
        fullScreen: false,
        scrollbarStyle: null,
        lineWrapping: false,
        theme: 'paraiso-dark',
        readOnly: false,
    }

    var customCode = 
`url = 'https://strong-ant-b14484.gravitydemo.com/wp-json/gf/v2/forms/'+str(inputData['formId'])
method = 'GET'
headers = {}
params = {}
body = {}
response = requests.request(method, url, headers=headers, params=params, json=body auth=(authData['username'], authData['password']))
response.raise_for_status()
results = response.json()
# You can do any parsing you need for results here before returning them`
    const history = useHistory()
    const actionKey = window.location.pathname.split("/")[4]
    const [newKey, setNewKey] = useState()
    const [newField, setNewField] = useState()
    const [saving, setSaving] = useState(false)
    const [alert, setAlert] = useState();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const saveNewFieldToDB = (newField) => {
        setSaving(true)
        console.log('old Info',appInfo)
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        var count = newAppInfo.actions[actionKey].fields.length
        newAppInfo.actions[actionKey].fields[count] = newField
        
        console.log('new app info: ', newAppInfo)
       
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/' + appInfo.appSlug,
            data: JSON.stringify(newAppInfo)
        }).then(resp => { console.log(resp);setAppInfo(newAppInfo); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" });handleClick(); setSaving(false);setEditMode(false); history.push("/develop/"+appInfo.appSlug+"/actions/"+actionObj?.key+"/input/");  })
    }

    const saveNewToDB = (newKey) => {
        setSaving(true)
        console.log('old Info',appInfo)
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        var newAction = {
            key: newKey.key,
            title: newKey.title,
            description: newKey.description,
            url: newKey.url,
            method: newKey.method,
            hidden: newKey.hidden,
            customForm: {},
            fields: []
        }
        newAppInfo.actions[newKey.key] = newAction
        console.log('new app info: ', newAppInfo)
       
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/' + appInfo.appSlug,
            data: JSON.stringify(newAppInfo)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" });handleClick(); setSaving(false); history.push("/develop/"+appInfo.appSlug+"/actions/") })
    }
    const saveToDB = () => {
        console.log(appInfo)
        setSaving(true)
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/' + appInfo.appSlug,
            data: JSON.stringify(appInfo)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" });handleClick(); setSaving(false); })
    }

    const saveCodeToDB = (newAppInfo) => {
        console.log('saving new app info: ', newAppInfo)
        setSaving(true)
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/' + appInfo.appSlug,
            data: JSON.stringify(newAppInfo)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" }); handleClick(); setSaving(false); })
    }
    
    const [codestate, setCodeState] = useState(actionObj.customForm?.apiCode ?? customCode)
    const [useCustomCode, setUseCustomCode] = useState( actionObj.customForm?.custom ?? false)
    const handleChangeUseCustomCode = () => {
        setUseCustomCode(!useCustomCode)
    }
  const uiSchema = {
    "key": {
        "ui:disabled": window.location.pathname.split("/")[4] === "__new__" ? false : true
    }
  }

  const uiSchemaField = {
    "in": {
        "ui:widget" : "hidden"
    }
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const handleChangePage = (event, newPage) => {
    //console.log('new page: ', newPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
   // console.log('rows per page: ', event.target.value)
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [anchorElAuthMenu, setAnchorElAuthMenu] = React.useState(null);
 const [newAction, setNewAction] = useState()
  const [rowKey, setRowKey] = useState()
  const classes = useStyles();
const [editMode, setEditMode] = useState(false)

  const handleAuthClick = (event, i) => {
    setAnchorElAuthMenu(event.currentTarget);
    setRowKey(i)
    
   // console.log('rowkey: ', i)
  };

  const handleCloseAuthMenu = () => {
    setAnchorElAuthMenu(null);
  };
  //console.log('action Obj::::: ', actionObj)
  const singleField = actionObj?.fields[rowKey]
  //console.log('singleField is::: ', singleField)
  console.log('this is the actionseditInput.jsx page')
    return (
        <div style={{whiteSpace: 'pre-line'}} >
             {/* {actionbutton === false && mode !== 'api' && mode !== 'design' ? <Button variant='outlined' color='secondary' onClick={() => { setActionButton(true) }}>Delete Action</Button> : <Button variant='outlined' color='secondary' onClick={() => { actionDelete(action.key) }}><Typography color="secondary" style={{ fontWeight: 'bold' }}>Really Delete?</Typography></Button>} */}
            {mode === 'settings' && 
            
             <Form style={{ outerWidth: '80%vw'  }} liveValidate={true} showErrorList={false} uiSchema={uiSchema} transformErrors={regexErrors} schema={actionSettingsSchema} formData={newKey ? newKey : actionObj} formContext={newKey ? newKey : actionObj} onChange={e => {
                console.log(e.formData)
                if (window.location.pathname.split("/")[4] === "__new__") {
                    var newActionObj = {
                        ...e.formData
                    }
                    setNewKey(newActionObj)
                } else {
                var newActionObj = {
                    ...e.formData,
                    dynamicForm: dynamicFormToAction(e.formData.dynamicForm),
                    customForm: customFormToAction(e.formData.customForm),
                    fields: e.formData.fields?.map(field => {
                        return {
                            ...field,
                            schema: formToAction(field.schema)
                        }
                    })
                }
            }
            if (window.location.pathname.split("/")[4] === "__new__") {
                return
            } else {
                onChange(newActionObj)
            }
              
            }} ><div style={{marginRight: '40px', width: '100%', padding: '20px'}}>
            <Button onClick={()=> {window.location.pathname.split("/")[4] === "__new__" ? saveNewToDB(newKey) : saveToDB(); console.log('the settings save button was clicked...')}} size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
            </div></Form>
            }
            
            {mode === 'design' && editMode === false && <>
                <Grid item xs={12} >
                <Paper className={classes.paper}> 
                <Box component="div" style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                  <Box component="div" style={{float: 'left'}}>
                  <Typography ><h1>Form Editor</h1></Typography>
                    </Box>  
                    <Box component="div" style={{float: 'left', margin: '20px'}}>
                  <Button onClick={()=>  {history.push("/develop/"+appInfo.appSlug+"/actions/"+actionObj?.key+"/input/__new__"); setEditMode(true)}} variant="contained" color="primary" style={{textDecoration: 'none'}} ><Typography>+Add Field</Typography></Button>
                    </Box>  
                </Box>
               
             <TableContainer>
                <Table stickyHeader>
                    <TableHead style={{width: '100%'}}>
                        <TableRow>
                            <TableCell key="title">Name</TableCell>
                            <TableCell key="key">Key</TableCell>
                            <TableCell key="hidden">Visibility in Editor</TableCell>
                            <TableCell key="edit"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {appInfo.appSlug ? actionObj?.fields?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                       // console.log('row is: ', row)
                       // console.log('i is: ', i)
                        return (
                            <TableRow key={row.name}>
                                <TableCell  align="left">
                                   <Typography onClick={()=>   { history.push("/develop/"+appInfo.appSlug+"/actions/"+actionObj?.key+"/input/"+i); setRowKey(i); setEditMode(true)}} style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}>{row.title}</Typography> 
                                    <p>{row.description ?? "No description"}</p>
                                </TableCell>
                                <TableCell align="left">
                                    {row.name}
                                </TableCell>
                                <TableCell  align="left">
                                    {row.hidden === true ? "Hidden" : "Shown"}
                                </TableCell>
                                <TableCell  align="left">
                                   <IconButton onClick={(e) => handleAuthClick(e, i)}>
                    <SettingsRounded />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorElAuthMenu}
                    open={Boolean(anchorElAuthMenu)}
                    onClose={handleCloseAuthMenu}
                    
                    >
                    <MenuItem style={{width: '150px'}} onClick={()=>   { history.push("/develop/"+appInfo.appSlug+"/actions/"+actionObj?.key+"/input/"+rowKey); setEditMode(true)}}>{"Edit"}</MenuItem>
                    <MenuItem style={{width: '150px'}} onClick={()=> {fieldDelete(rowKey); handleCloseAuthMenu()}}>Delete</MenuItem>
                    </Menu>
                                </TableCell>
                            </TableRow>
                        );
                        }) : null}
                    </TableBody>
                </Table>
             </TableContainer>
             <TablePagination
                    rowsPerPageOptions={[10, 25, 100,250]}
                    component="div"
                    count={actionObj.fields?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                </Paper>
                </Grid>
               
            </>
        }

        {mode === 'design' && editMode === true && <>
        {
            <Form style={{ outerWidth: '80%vw'  }} liveValidate={true} showErrorList={false} uiSchema={uiSchemaField} transformErrors={regexErrors} schema={actionInputSchema} ArrayFieldTemplate={ArrayFieldTemplate} ObjectFieldTemplate={ObjectFieldTemplate} formData={newField ? newField : singleField} onChange={e => {
                if (window.location.pathname.split("/")[6] === "__new__") {
                    const newFieldObj = {
                        ...e.formData
                    }
                    console.log('setting new field data...')
                    setNewField(newFieldObj)
                } else {
                console.log('formData is::: ', e.formData)
                var newActionObj = e.formData
                
            } if (window.location.pathname.split("/")[6] === "__new__") {
                return
            } else {
                onChange(newActionObj, 'field', rowKey)
            }
              console.log('newActionObject is... ', newActionObj)
            
            }} ><Fragment /><div style={{marginRight: '40px', width: '100%', padding: '20px'}}>
                
            <Button onClick={window.location.pathname.split("/")[6] === "__new__" ? () => {
            saveNewFieldToDB(newField);handleCloseAuthMenu()}
            : () => {console.log('saving old shit...'); 
            saveToDB();handleCloseAuthMenu();history.push("/develop/"+appInfo.appSlug+"/actions/"+actionObj?.key+"/input/");setEditMode(false); setRowKey()}  
        } size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
            <Button variant="outlined" color="primary" size="large" style={{float: 'right', textDecoration: 'none', marginRight: '20px'}} onClick={() => {history.push("/develop/"+appInfo.appSlug+"/actions/"+actionObj?.key+"/input/");setEditMode(false);handleCloseAuthMenu();setNewField(); setRowKey();}}>Cancel</Button>
            </div></Form>
             }</>}

           
         {mode === 'api' && <><Box style={{border: '1px solid silver', borderRadius: '8px',margin: '16px', padding: '16px'}}>
    
             <CodeMirror2
             defaultValue={codestate }
             value={codestate}
             setSize={{
                 width: '500px',
                 height: '900px'
             }}
             options={{
                 mode: 'python',
                 theme: 'darcula',
                 lineNumbers: true,
                 lineWrapping: false
             }}
            onBeforeChange={(editor, data, value) => {
                    setCodeState(value);
             }} />
             <FormControlLabel
             control={
            <Switch
            checked={useCustomCode}
            onChange={handleChangeUseCustomCode}
            name="useCustomCode"
            color="primary"
            />}
            label="Use Custom Code"
            />
             </Box>
              <div style={{marginRight: '40px', width: '100%', padding: '20px'}}>
              <Button onClick={()=> {var newActionObj = {
                 ...actionObj, customForm:{apiCode: codestate, custom: useCustomCode}
                };
                appInfo.actions[newActionObj.key] = newActionObj
                onChange(newActionObj);
                saveCodeToDB(appInfo);
                
                }} size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
              </div>
              </>
        }  
         <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alert ? alert.severity : null}>
                            {alert ? alert.message : null}
                        </Alert>
                    </Snackbar>
        </div>
    )
}


export default () => {
    const classes = useStyles();

    const [allApps, setAllApps] = useState([])
    const [loadingAllApps, setLoadingAllApps] = useState(false)
    const [alert, setAlert] = useState();
    const [appInfo, setAppInfo] = useState({ actions: {}, triggers: {}, authInfo: {} })
    const [loadingApp, setLoadingApp] = useState(false)

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)
    const [formType, setFormType] = useState(null)
    const [selectedObject, setSelectedObject] = useState(null)

    const statusOptions = [
        "Complete",
        "In Progress"
    ]

    const allAppsFilterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 50
    })

    console.log(appInfo)

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const loadApps = () => {
        setLoadingAllApps(true)
        axios({
            method: "get",
            url: "https://editor.workload.co:5000/api/apps"
        }).then(resp => { console.log(resp); setAllApps(resp.data); setLoadingAllApps(false) })
    }
    const pathname = window.location.pathname.split("/")[2]
    const actionKey = window.location.pathname.split("/")[4]
    useEffect(() => {
        console.log('action key is: ', actionKey)
       if (pathname) {
        loadApp(pathname)
       }
    }, [pathname])

    const loadApp = (appSlug) => {
        setLoadingApp(true)
        setSelectedObject(null)
        setFormType(null)
        setAppInfo({ actions: {}, triggers: {}, authInfo: {} })
        axios({
            method: 'get',
            url: `https://editor.workload.co:5000/api/apps/`+pathname
        }).then(resp => { setAppInfo(resp.data); setLoadingApp(false); setDisabled(false) })
    }

    useEffect(() => {
        loadApps()
    }, [])


    const saveToDB = (e) => {
        console.log(appInfo)
        console.log(e)
        setSaving(true)
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/' + appInfo.appSlug,
            data: JSON.stringify(e)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" }); handleClick(); setSaving(false) })
    }

    const createAppDB = (newInfo) => {
        axios({
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/',
            data: newInfo
        }).then(resp => { console.log(resp) })
    }

    const createApp = (appData) => {
        setAllApps([...allApps, appData])
        setAppInfo(appData)
        createAppDB(appData)
    }

    const makeNewKey = (dictOfKeys, testString, num = 0) => {
        if (Object.keys(dictOfKeys).includes(testString)) {
            return makeNewKey(dictOfKeys, testString.slice(0, testString.length - 1) + num, num + 1)
        } else {
            return testString
        }
    }

    const createAction = () => {
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        var newKey = makeNewKey(newAppInfo.actions, 'newAction0')
        var newAction = {
            key: newKey,
            title: 'New Action',
            fields: []
        }
        newAppInfo.actions[newKey] = newAction
        setFormType('action')
        setSelectedObject(newAppInfo.actions[newKey])
        setAppInfo(newAppInfo)
    }

    const updateAction = (newData, field, i) => {
        if (field === 'field') {
            console.log('field is: ', field)
            console.log('hey it is a field we are editing this one: ', i)
            var newAppInfo = JSON.parse(JSON.stringify(appInfo))
            console.log(newData)
            newAppInfo.actions[actionKey].fields[i] = newData
            console.log('new new new appInfo: ')
            console.log(newAppInfo)
            setAppInfo(newAppInfo) 
            
        } else {
            
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        console.log(newData)
        newAppInfo.actions[newData.key] = newData
        if (newData.key == actionKey) {
            setSelectedObject(newAppInfo.actions[newData.key])
        } else {
            var oldObjKey = actionKey
            setSelectedObject(newAppInfo.actions[newData.key])
            delete newAppInfo.actions[oldObjKey]
        }
    
        setAppInfo(newAppInfo)
        console.log(newAppInfo)
    }
    }

    const deleteAction = (actionKey) => {
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        setFormType(null)
        setSelectedObject(null)
        delete newAppInfo.actions[actionKey]
        setAppInfo(newAppInfo)
    }

    const deleteField = (fieldKey) => {
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
      
       // Remove the element at the specified index from the fields array
    newAppInfo.actions[actionKey].fields.splice(fieldKey, 1);
        setAppInfo(newAppInfo)
        saveToDB(newAppInfo)
    }

   
   
    const history = useHistory()
    console.log(appInfo)
    const [anchorElAuthMenu, setAnchorElAuthMenu] = React.useState(null);
    const tab = window.location.pathname.split("/")[5]
    const [tabValue, setTabValue] = React.useState();
useEffect(() => {
   if ( actionKey && appInfo.actions[actionKey] ) {
    if (tab === 'settings') {
        setTabValue(0)
    }
    if (tab === 'input') {
        setTabValue(1)
    }
    if (tab === 'api') {
        setTabValue(2)
    }
   }
}, [appInfo.actions[actionKey]])
useEffect(() => {
    const close = document.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
    )[0];

    // Add a Click Event Listener to the button
    close.addEventListener("click", () => {
        history.push("/develop")
    });
  }, []); 
  const handleTabChange = (event, newValue) => {
  
    if (newValue === 0) {
        history.push("/develop/"+appInfo.appSlug+"/actions/"+actionKey+"/settings")
    }
    if (newValue === 1) {
        history.push("/develop/"+appInfo.appSlug+"/actions/"+actionKey+"/input")
    }

    if (newValue === 2) {
        history.push("/develop/"+appInfo.appSlug+"/actions/"+actionKey+"/api")
    }
  };


    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <AppBarHeaderDummy />
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid style={{overflow: 'auto'}} item xs={4} >
                        <Paper className={classes.paper} style={{ marginLeft: 15 }}> <Typography ><h1>Apps</h1></Typography>
                            <Button variant='outlined' style={{marginBottom: '10px'}} color='primary' onClick={() => { history.push("/develop/new") }}>Add App</Button>
                            <Autocomplete
                                loading={loadingAllApps}
                                loadingText={"Loading Apps..."}
                                noOptionsText={"No Apps? Something has gone wrong..."}
                                options={allApps}
                                getOptionLabel={(option) => option.appName ?? option.appSlug}
                                onChange={(e, v) => {
                                    setAppInfo({ ...v, actions: {}, triggers: {}, authInfo: {} })
                                    if (v) {
                                        loadApp(v.appSlug);
                                        history.push("/develop/"+v.appSlug)

                                    } else {
                                        setDisabled(true)
                                    }
                                }}
                                value={appInfo ?? { appSlug: '' }}
                                renderInput={(params) => <TextField style={{ width: '100%', padding: '4px', margin: '4px' }} {...params} variant="outlined" placeholder={"Type to search apps"} label={"App"} />}
                                filterOptions={allAppsFilterOptions}
                            />
                            {
                                appInfo.appSlug ?
                                    <>
                                        <Autocomplete
                                            options={statusOptions}
                                            onChange={(e, v) => {
                                                setAppInfo({ ...appInfo, completionStatus: v })
                                            }}
                                            value={appInfo.completionStatus}
                                            renderInput={(params) => <TextField variant="outlined" style={{margin: '4px', width: '100%', padding: '4px' }} {...params} label={"Status"} />}
                                        />
                                        <TextField style={{margin: '4px', width: '100%', padding: '4px' }} label={"API Docs"} value={appInfo.apiDocs} onChange={(e) => { setAppInfo({ ...appInfo, apiDocs: e.target.value }) }} />
                                        <Box style={{width: '100%', height: '700px', overflow: 'scroll' }}>
                                        <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug)} style={{margin: '8px 0px 0px 0px'}}><SettingsRounded style={{marginRight: '10px', padding: '4px'}}/><Typography variant="h6">App Overview</Typography></MenuItem>
                                        <Accordion elevation={0} defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreRounded />}><BuildRounded style={{marginRight: '10px', padding: '4px'}} /><Typography variant="h6">Develop</Typography></AccordionSummary>
                                            <AccordionDetails>
                                                <List>
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/authentication")} style={{margin: '0px 0px 4px 0px'}}>Authentication</MenuItem>
                                                    {appInfo.authInfo.apiType && (
                                                       <MenuItem  onClick={()=> history.push("/develop/"+appInfo.appSlug+"/authentication/edit")}>
                                                        <VpnKeyRounded style={{marginRight: '8px', color: appInfo.authInfo.testResponseStatus === true ? 'green' : appInfo.authInfo.testResponseStatus === false ? 'red' : null}} />
                                                        {appInfo.authInfo.apiType === 'basic' &&
                                                       <Typography variant="body1">Basic Auth</Typography>}
                                                       {appInfo.authInfo.apiType === 'apikey' &&
                                                       <Typography variant="body1">API Key</Typography>}
                                                       {appInfo.authInfo.apiType === 'oauth2' &&
                                                       <Typography variant="body1">OAuth 2.0</Typography>}
                                                       {appInfo.authInfo.apiType === 'customCode' &&
                                                       <Typography variant="body1">Custom</Typography>}</MenuItem>  
                                                    )}
                                                    {appInfo.disableAuth === true && (
                                                       <MenuItem disabled><VpnKeyRounded style={{marginRight: '8px'}} /><Typography variant="body1">{"Passport App"}</Typography></MenuItem>  
                                                    )}
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/triggers")} style={{margin: '12px 0px 4px 0px'}}>Triggers</MenuItem>
                                                    {Object.values(appInfo.triggers).map(trigger => (
                                                       <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/triggers/"+trigger.key+"/settings")}><OfflineBoltRounded style={{marginRight: '8px'}} /><Typography variant="body1">{trigger.label}</Typography></MenuItem> 
                                                    ))}
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/actions")} style={{margin: '12px 0px 4px 0px'}}>Actions</MenuItem>
                                                    {Object.values(appInfo.actions).map(action => (
                                                       <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/actions/"+action.key+"/settings")} style={{ color: actionKey && actionKey === action.key ? '#0069ea': null, backgroundColor: actionKey && actionKey === action.key ? '#f0f1fa' : null}}><SpellcheckRounded style={{marginRight: '8px'}} /><Typography variant="body1">{action.title}</Typography></MenuItem> 
                                                    ))}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        </Box>
                                    </> :
                                    null
                            }
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={8} >
                    <Paper className={classes.paper}> 
                    <div style={{marginTop: '20px', marginBottom: '20px'}}>
                    {actionKey &&
                            <Typography variant="h4">{appInfo.actions[actionKey]?.title ? appInfo.actions[actionKey]?.title + " Action" : "Action"}</Typography>}
                            </div>
                        <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        >
                            <Tab label="Settings" />
                            <Tab label="Form Design" onClick={()=> { } }/>
                            <Tab label="API Configuration" />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                        <ActionForm action={actionKey && appInfo.actions[actionKey]} onChange={updateAction} actionDelete={deleteAction}  appInfo={appInfo} mode="settings" />
                        
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                        <ActionForm action={actionKey && appInfo.actions[actionKey]} onChange={updateAction} actionDelete={deleteAction} fieldDelete={deleteField} setAppInfo={setAppInfo}  appInfo={appInfo} mode="design" />
                        
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                        <Accordion expanded style={{padding: '12px'}}>
                                 <AccordionSummary expandIcon={<ExpandMoreRounded />} ><Chip size="small" color="primary" style={{marginRight: '10px'}} label={"Customize"} /><Typography style={{fontWeight: 700}}>{"Customize API Request (optional)"}</Typography></AccordionSummary>
                                    <AccordionDetails style={{display: 'block'}}>
                                        <Typography>{"Enter the information where Workload will make the request and send the input form data. This request must return an object or array."}</Typography>
                                        <ActionForm action={actionKey && appInfo.actions[actionKey]} onChange={updateAction} actionDelete={deleteAction}  appInfo={appInfo} mode="api" />
                                    </AccordionDetails>
                                </Accordion>
                        </TabPanel>
                          
                    </Paper>
                    </Grid>
                     

                    
                    
                    
                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alert ? alert.severity : null}>
                            {alert ? alert.message : null}
                        </Alert>
                    </Snackbar>
                   
                </Grid>
            </div></div>

    )
}
