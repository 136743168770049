import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import Avatar from '@material-ui/core/Avatar';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';

const GreenSwitch = withStyles({
  switchBase: {
    color: '#ffffff',
    '&$checked': {
      color: '#4caf50',
    },
    '&$checked + $track': {
      backgroundColor: '#4caf50',
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
    
  },
  right: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '2px'

  },
  title: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(16)
  },
  switch: {
    backgroundColor: 'gray',
    border: '1px solid',
    borderRadius: '40px',
    width: '100px',
    marginLeft: theme.spacing(15),
    alignItems: 'center',
  }
}));



export const AppBarHeaderDummy = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <AppBar position="static" style= {{background: 'inherit'}}>
        <Toolbar>
          <IconButton  edge="start" className={classes.menuButton} color="default" aria-label="menu">
            <ChevronLeftRoundedIcon />
            </IconButton>
            <Avatar alt="Workload Canvas Editor" src="https://cdn.workload.co/wp-content/uploads/new-logo-only-512-512.png"  />
          
          <FormControl className={classes.margin}>
        <Input
          id="input-with-icon-adornment" type="WFName" placeholder="Name your workflow" style={{width: '500px', fontWeight: 'bold'}}
          startAdornment={
            <InputAdornment position="start">
              <CreateRoundedIcon style={{color: 'gray'}}/>
            </InputAdornment>
          }
        />
      </FormControl>
      <div className={classes.switch}>
      <FormControlLabel className={classes.right} style={{color: '#000000'}} 
        control={
          <GreenSwitch 
            checked={false}
            name="checkedB"
            
            
          />
        }
        label={<Typography style={{color: 'white', fontWeight: 'bold'}} variant='button' >{'OFF'}</Typography>}
        
      />
      </div>
      
        </Toolbar>
      
      </AppBar>
    </div>
  );
}