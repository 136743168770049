import React from "react";

const notfound = () => {
    return (
        <div>
        <h3>Oh No! Ouch...beep..boop...</h3>
        <small>404 Page Not Found</small>
        </div>
    );
};

export default notfound;