import React, { useEffect, useState, Fragment } from 'react';
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import SecurityRoundedIcon from '@material-ui/icons/SecurityRounded';
import styles from '../Panel.module.css';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { Paper, Card, Chip,Divider, Accordion,FormHelperText, FormControlLabel, Checkbox, Modal, CircularProgress, AccordionDetails, Typography, Stepper, Step, StepLabel, StepContent, StepButton, Button, InputLabel, MenuItem, FormControl, Select, TextField, ListItemIcon, Avatar, ListItemText, Grid, AccordionSummary } from '@material-ui/core'
import { isTemplateSpan, updateFunctionDeclaration } from 'typescript';
import Authorizer from '../Authorizer'
import Inspector from 'react-json-inspector';
import '../helpers/json-inspector.css';
import '../helpers/workload-tags.css'; //add styling for the tags in input fields
import '../helpers/workload-mode-single-line-style.css';// add .cm-custom-keyword class here
import 'codemirror/addon/search/searchcursor';
import { v4 as uuid } from 'uuid';
import { Alert, AlertTitle } from '@material-ui/lab';
import { alertName } from '../../redux/ducks/notification';
import Form from "@rjsf/material-ui"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {FieldsRender} from '../helpers/panel-functions'

import {
    nodeAdded, edgeAdded, elementUpdated, nameUpdated, elementDeleted, fetchWorkflow, nodeEdgeAdded, saveWorkflow, elementSelected, testNode, notification,
    selectWorkflowElements, selectElementById, selectWorkflowName, selectWorkflowId, selectParentTree, selectWorkflowTemplate, selectWorkflowActive, selectCurrentElement
} from '../../features/workflow/workflowSlice'

const modalheight = window.innerHeight;
const modalwidth = window.innerWidth;
function getModalStyle() {
  const top = 5
  //const left = 50 + rand();

  return {
      top: `${top}%`,
      margin: 'auto'
    //  left: `${left}%`,
    //  transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    removeappbutton: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(60),
    },
    actionsContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        justifyContent: 'center',
        width: '90%',
        backgroundColor: '#0069ea',
        borderRadius: '4px',
        border: 'none',
        height: '50px',
        marginBottom: '20px',
        cursor: 'pointer'
      },

      signinbutton: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        justifyContent: 'center',
        width: '90%',
        backgroundColor: 'white',
        color: '#0069ea',
        borderRadius: '4px',
        border: '2px solid #0069ea',
        height: '50px',
        marginBottom: '20px',
        cursor: 'pointer'
      },
    resetContainer: {
        padding: theme.spacing(1),
    },
    selectInput: {
        '*': {
            display: 'flex'
        }
    },
    chiproot: {
        display: 'flex',
        left: 5,
        marginLeft: '20px',
        width: '99%',
        float: 'left',
        '& > *': {
            margin: '-10px',

        },
        "& > *:hover": {
            border: '2px solid',
            borderColor: "#0069ea"
        }
    },
    accordionroot: {
        width: '90px',
    },
    accordionheading: {
        fontWeight: 700,
    },
    dropdownroot: {
        position: 'relative',
    },
    small: {
        width: '22px',
        height: '22px',
        marginRight: '4px'
    },
    formControl: {
      margin: theme.spacing(1),
      width: '95%'
    },
    dropdown: {
        position: 'absolute',
        width: '500px',
        right: 0,
        left: 10,
        zIndex: 5,

        // borderTop: '1px solid',
        // borderLeft: '1px solid',
        boxShadow: "1px 0px 0px lightgrey",
        borderColor: 'lightgrey',
        borderRadius: '5px',
        padding: '4px',
        backgroundColor: 'white',
        opacity: '1',
        overflowX: "hidden",
        maxHeight: '350px',
        overflowY: "scroll",

    },
    containerHeader: {
        display: 'flex',
        padding: '20px 10px 20px 10px',
        border: '1px solid silver',
        borderRadius: '8px'
    },
    headerChild1: {
        flex: 1
    },
    headerChild2: {
   
        width: '50%',
        marginLeft: '5%'
    },
    codemirror: {
        marginTop: '7px',
        border: '1px solid',
        borderColor: 'lightgrey',
        borderRadius: '4px',
        paddingTop: '4px',
        paddingRight: '4px',
        paddingBottom: '4px',
        justifyContent: 'center',
        width: 'inherit',
        height: '48px',
        display: 'relative',
        //position: 'absolute'
        '&:hover': {
            borderColor: 'black',
            border: '1px solid'
        },
        '&:focus-within': {
            borderColor: '#0069ea',
            border: '2px solid'
        }
    },
    modalpromo: {
      position: 'absolute',
      width: '40%',
      //height: modalheight * .60,
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      borderRadius: '4px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: 'scroll'
  },
  gradientbox: {
   
    color: '#FFF',
    background: '#000',
    backgroundClip: 'padding-box', /* !importanté */
    border: 'solid 5px transparent', /* !importanté */
  
    '&:before': {
      content: '',
      zIndex: -1,
      background: 'linear-gradient(to right, red, orange)',
    }
  }
}));

const muiTheme = createMuiTheme({
    palette: {
        primary: { main: '#0069ea' },
        secondary: { main: '#ff5555' }
    },
    props: {
        style: { borderColor: '#0069ea' },
        MuiTextField: {
            variant: 'outlined'

        }
    }
});
function getSteps() {
    return ['Trigger set up 1', 'Trigger set up 2', 'Trigger set up 3'];
  }

function CreateTriggerPanel({  workflowID, onPaneClick, app,  breakpoint, user, tenant  }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const [auths, setAuths] = useState('');
    const [name, setName] = useState(); 
    const [testData, setTestData] = useState()
    const classes = useStyles();
    const [credentialId, setCredentialId] = useState()
    const [modalStyle] = React.useState(getModalStyle);
    const [modalopenpromo, setModalOpenPromo] = useState(false)
    const [loadtest, setLoadTest] = useState(false)
    const dispatch = useDispatch();
 
   
  //  console.log(classes)
  const [newauth, setNewAuth] = useState();
  const [appInfo, setAppInfo] = useState({ actions: {}, triggers: {}, authInfo: {} })

  const handleModalClosePromo = () => {
    setModalOpenPromo(false);
};

  const loadApp = (appSlug) => {

    setAppInfo({ actions: {}, triggers: {}, authInfo: {} })
    axios({
        method: 'get',
        url: `https://editor.workload.co:5000/api/apps/${appSlug}`
    }).then(resp => { setAppInfo(resp.data) ; console.log('app data is: ', resp.data)})
}



useEffect(() => {
  loadApp(app.app)
}, [])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };


  const triggertypes = [
    {
        value: 'new',
        label: 'New',
        active: false
      },
      {
        value: 'updated',
        label: 'Updated - Coming Soon',
        active: true
      }
  ]

  const handleOfferPromo = () => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  //console.log('selectedElement data for Custom Trigger: ', app)
  const email = localStorage.getItem('email')
    mixpanel.track('Create Custom Trigger - Offer Click', {
      'Email': email ? email : 'undefined',
        'App': app,
        'Type': triggertype,
        'Noun': capitalizeFirstLetter(noun),
        'Decription': triggertype === 'new' ? 'Triggers when a new ' + noun.toLowerCase() + ' is created.' : 'Triggers when a ' + noun.toLowerCase() + ' is updated.'
    });
    
  let config_send = {
      method: 'post',
      url: 'https://editor.workload.co:5000/promocheckout',
      headers: { 
          
          'Content-Type': 'application/json'
      },
      data: {
        currentUrl: window.location.href,
        tenantId: tenant,
        email: email
      }
      };

      axios(config_send)
      .then((response) => {
     console.log(response.data);
     window.location.href = response.data.url
})
.catch((error) => {
console.log(error);
});

  }

  const handleOfferCancel = () => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  //console.log('selectedElement data for Custom Trigger: ', app)
  const email = localStorage.getItem('email')
    mixpanel.track('Create Custom Trigger - Offer Cancel', {
      'Email': email ? email : 'undefined',
        'App': app,
        'Type': triggertype,
        'Noun': capitalizeFirstLetter(noun),
        'Decription': triggertype === 'new' ? 'Triggers when a new ' + noun.toLowerCase() + ' is created.' : 'Triggers when a ' + noun.toLowerCase() + ' is updated.'
    });
  }

  const body_promo = (
    <div style={modalStyle} className={classes.modalpromo}>
      
      <div id='share_container' style={{marginLeft: '10%', marginRight: '10%'}}>
      <img style={{display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: breakpoint !== 'large' ? '20px 0px 20px 0px' : '30px 10px 5px 10px', margin: 'auto' }} height={breakpoint !== 'large' ? '25px' : '45px'} src="https://cdn.workload.co/wp-content/uploads/cropped-new-workload-logo-high-res-900-×-193-px.png" />
      <br/><Typography style={{textAlign: 'center'}} variant="h4">Unlock custom triggers with Workload<sup>&reg;</sup> Premium</Typography>  <br/>
      <br/><div style={{border: '8px solid',borderRadius: '8px',borderWidth: '4px', borderImageSlice: 1,borderImageSource: 'linear-gradient(to right, red, #0069ea)', padding: '16px', marginBottom: '25px' }}>
      <Chip style={{display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: '10px', margin: '0% 30% 0% 30%'}}  variant="outlined" label="RECOMMENDED" color="secondary" />
      <br/><div style={{width: '100%', padding: '8px', margin: 'auto'}}><Typography style={{ textAlign: 'center', fontSize: '20px', fontWeight: 400}}><p>1 month of Workload Premium for <del>$25</del> $6</p></Typography></div>
      <Typography><div style={{display: 'flex', justfiyContent: 'center', textAlign: 'center', fontSize: '18px', fontWeight: 300}}>Get more features like custom triggers, actions and special data formatting apps. $6 for 1 month, then $25/month.</div><br/></Typography>
      <br/><Button style={{boxShadow: '2px 2px 5px #303030', display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: '10px', height: '50px', width: '200px', backgroundColor: '#0069ea', borderRadius: '4px', border: 'none', cursor: 'pointer', margin: 'auto' }} onClick={()=> {handleOfferPromo(); }}><div><Typography style={{ textTransform: 'none', alignText: 'center', fontSize: '20px', fontWeight: 'bold', color: 'white', float: 'left', display: 'block' }}>Get offer</Typography></div></Button>
      <div style={{width:'150px', margin: 'auto', justifyContent: 'center'}} onClick={()=> {handleOfferCancel(); handleModalClosePromo();}}><Typography style={{ fontSize: '14px', color: 'dimgray', textAlign: 'center', padding: '25px 15px 15px 15px', cursor: 'pointer', textDecoration: 'underline' }} id="simple-modal-subtitle">No thanks</Typography></div> 
      <Divider />
      <br /><Typography><div style={{textAlign: 'center', fontSize: '16px', fontWeight: 300}}>By continuing, you agree to our <a href="https://www.workload.co/terms" target="_blank">terms of service</a> and <a href="https://www.workload.co/privacy" target="_blank">privacy policy</a>. See <a href="https://www.workload.co/privacy" target="_blank">how Workload handles data</a>.</div></Typography><br/>
      </div>
      </div>
      
    </div>
);


  const [triggertype, setTriggerType] = useState('')
  const handleTriggerType = (event) => {
    setTriggerType(event.target.value);
  };

  const [noun, setNoun] = useState('')
  const handleNoun = (event) => {
    setNoun(event.target.value);
  };

  const [triggeremail, setTriggerEmail] = useState('')
  const handleTriggerEmail = (event) => {
    setTriggerEmail(event.target.value);
  };

  
  const handleTriggerEvent = () => {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
   // console.log('selectedElement data for Custom Trigger: ', app)
    const email = localStorage.getItem('email')
    mixpanel.track('Create Custom Trigger - Step 1', {
      'Email': email ? email : 'undefined',
        'App': app,
        'Type': triggertype,
        'Noun': capitalizeFirstLetter(noun),
        'Decription': triggertype === 'new' ? 'Triggers when a new ' + noun.toLowerCase() + ' is created.' : 'Triggers when a ' + noun.toLowerCase() + ' is updated.'

    });
    
   /*  const data_code = JSON.stringify(
      {
          email: email ? email : 'no-email@workload.co',
          app: app.label,
          event: triggertype + " "+capitalizeFirstLetter(noun)
       }
  )
  let config_send = {
      method: 'post',
      url: 'https://editor.workload.co:5000/sendcustomtrigger',
      headers: { 
          
          'Content-Type': 'application/json'
      },
      data : data_code
      };

      axios(config_send)
      .then((response) => {
     console.log(JSON.stringify(response.data));
})
.catch((error) => {
console.log(error);
}); */

  }

  const [apiEndpoint, setApiEndpoint] = useState('')
  const handleApiEndpoint = (event) => {
    setApiEndpoint(event.target.value);
  }

  const [key, setKey] = useState('')
  const handleInputKey = (event) => {
    setKey(event.target.value);
  }

  const [label, setLabel] = useState('')
  const handleInputLabel = (event) => {
    setLabel(event.target.value);
  }

  const [description, setDescription] = useState('')
  const handleInputDescription = (event) => {
    setDescription(event.target.value);
  }

  const [type, setType] = useState('')
  const handleInputType = (event) => {
    setType(event.target.value);
  }

  const [location, setLocation] = useState('')
  const handleInputLocation = (event) => {
    setLocation(event.target.value);
  }

  const [required, setRequired] = useState(false)
  const handleInputRequired = (event) => {
    setRequired(event.target.checked);
  }

  const [addMode, setAddMode] = useState(false)
  const [previewMode, setPreviewMode] = useState(false)
  const [inputForm, setInputForm] = useState([])
  useEffect(() => {
   console.log('inputForm useEffect: ', inputForm)
  }, [inputForm])
  
  const handleAddInputForm = () => {
    setAddMode(true)
   
    if (inputForm.length === 0) {
        setInputForm(...inputForm,[{
            key: key,
            label: label,
            description: description,
            type: type,
            location: location,
            required: required
        }])
    }
    if (inputForm.length > 0) {
        setInputForm(current =>[...current,{
            key: 'new_new',
            label: 'Label',
            description:'Help text...' ,
            type: 'string',
            location: 'path',
            required: false
        }])
    }
  };

const handleSaveInput = () => {
    console.log('...saving input form...')
    setPreviewMode(true)

    if (inputForm.length === 1) {
        setInputForm([{
            key: key,
            label: label,
            description: description,
            type: type,
            location: location,
            required: required
        }])
    }

    if (inputForm.length > 1) {
        const newState = inputForm.map(element => {
            // 👇️ if id equals 'new_new', update property
            if (element.key === 'new_new') {
              return {...element, 
            key: key,
            label: label,
            description: description,
            type: type,
            location: location,
            required: required
               };
            }
      
            // 👇️ otherwise return object as is
            return element;
          });
          setInputForm(newState)
    }

    setAddMode(false)
    setKey('')
    setLabel('')
    setDescription('')
    setType('')
    setLocation('')
    setRequired(false)
}

const handleStep2 = () => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const email = localStorage.getItem('email')
  mixpanel.track('Create Custom Trigger - Step 2', {
    'Email': email ? email : 'undefined',
      'App': app,
      'Type': triggertype,
      'Noun': capitalizeFirstLetter(noun),
      'Decription': triggertype === 'new' ? 'Triggers when a new ' + noun.toLowerCase() + ' is created.' : 'Triggers when a ' + noun.toLowerCase() + ' is updated.',
      'ApiEndpoint': apiEndpoint
  });
}

useEffect(() => {
  handleLoad();
   console.log('new auth hit from useEffect')
  // console.log('auths are: ', auths)
  
   console.log('number of auths: ',number);
  if (auths.length > 0) {
    var number = auths.length - 1
    
    console.log('newauth now exists')
    setAuths(auths ? auths[number].app + " - " + auths[number].name + '#' + number + 1 : null)
    // node.data.authname = auths ? auths.slice(-1)[0].app + " - " + auths.slice(-1)[0].name + '#' + number + 1 : null;
    console.log('newauth 68: ', auths ? auths[number].app + " - " + auths[number].name + '#' + number + 1 : null)
   
    // saveNode(node);
  }
}, [newauth])

const handleLoad = () => {

  var config = {
    method: 'get',
    url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?tenant=' + tenant + '&app=' + app.label,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  axios(config)
    .then(function (response) {
      console.log('auths is: ', response.data);
      console.log('auths lengths: ', response.data.length)
     tenant === null ? setAuths([]) : setAuths(response.data);

    }, [auths], console.log('auths are: ', auths))

    .catch(function (error) {
      console.log(error);
    });
};

  let windowObjectReference = null;
  let previousUrl = null;

  const addAuth = (url, name) => {
      // remove any existing event listeners
      window.removeEventListener('message', receiveMessage);

      // window features
      const strWindowFeatures =
        'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

      if (windowObjectReference === null || windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
         or if such pointer exists but the window was closed */
        windowObjectReference = window.open(url, name, strWindowFeatures);
      } else if (previousUrl !== url) {
        /* if the resource to load is different,
         then we load it in the already opened secondary window and then
         we bring such window back on top/in front of its parent window. */
        windowObjectReference = window.open(url, name, strWindowFeatures);
        windowObjectReference.focus();
      } else {
        /* else the window reference must exist and the window
         is not closed; therefore, we can bring it back on top of any other
         window with the focus() method. There would be no need to re-create
         the window or to reload the referenced resource. */
        windowObjectReference.focus();
      }

      // add the listener for receiving a message from the popup
      window.addEventListener('message', event => receiveMessage(event), false);

      // assign the previous URL
      previousUrl = url;
  }

  const receiveMessage = event => {
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    /* if (event.origin !== 'https://ec2-35-81-172-203.us-west-2.compute.amazonaws.com') {
      return;
    } */
    const { data } = event;
    // console.log( document.cookie.replace(/(?:(?:^|.*;s*)token*=s*([^;]*).*$)|^.*$/, '$1') )
    // console.log('popup window data is: '+data);
    console.log('newauth 195 is: ',data)
  
    setCredentialId(data)
    
    //set the selected auth connection to the newly created connection

     // console.log('newauth is: '+JSON.stringify(credentialId))
    // setAuths(newauth);
    // if we trust the sender and the source is our popup
    /* if (data.source === 'success') {
      // get the URL params and redirect to our server to use Passport to auth/login
      const { payload } = data;
     const redirectUrl = `/auth/google/login${payload}`;
      window.location.pathname = redirectUrl;
    } */

  };


  const [datahead, setDataHead] = useState()
  const [dedupekey, setDedupeKey] = useState()
  const handleApiTest = () => {
    console.log('...testing api endpoint')
    setLoadTest(true);
    var testAuth = {
      method: 'post',
      url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
      headers: {
          'Content-Type': 'application/json'
      },
      data: {
          "operation": "testAuth",
          "data": {
              "url": app.formDataEndpoint,
              "app": app.app,
              "credential": credentialId,
              "action": "Custom Request",
              "fields": [
                {"value": { "requestType": "GET", "url": apiEndpoint }}
              ]
          }
      }
  };
  axios(testAuth)
      .then(function (response) {
          console.log('status: ',response.status);
          console.log('response: ', response.data)
          Object.keys(response.data).forEach(key => {
            console.log(key, Array.isArray(response.data[key]));
            if (key === '0'){
              setDataHead("0")
              setTestData(response.data[0])
              let [firstKey] = Object.keys(response.data[key][0])
              setDedupeKey(firstKey)
             // console.log('dedupe key: ', firstKey)
             } 
            if (Array.isArray(response.data[key]) === true ) {
              console.log('respdatakey = ', key)
              setDataHead(key)
              setTestData(response.data[key][0]) 
              let [firstKey] = Object.keys(response.data[key][0])
              setDedupeKey(firstKey)
             // console.log('dedupe key: ', firstKey)
            } 
          });
          setLoadTest(false)
      }).catch(function (error) {
        console.log(error);
        setLoadTest(false)
        dispatch(notification({status: 'error', message: 'There was an error with the test...'}))
      });
    
  }

  const handleFinish = () => {
   // console.log('the user has finished...posting data to mongodb...')
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const email = localStorage.getItem('email')
    mixpanel.track('Create Custom Trigger - Step Finish', {
      'Email': email ? email : 'undefined',
        'App': app,
        'Type': triggertype,
        'Noun': capitalizeFirstLetter(noun),
        'Decription': triggertype === 'new' ? 'Triggers when a new ' + noun.toLowerCase() + ' is created.' : 'Triggers when a ' + noun.toLowerCase() + ' is updated.'

    });
    const newAction = {
      "fields": [],
      "dynamicForm": null,
      "key": "list"+capitalizeFirstLetter(noun),
      "title": "List "+capitalizeFirstLetter(noun),
      "url": apiEndpoint,
      "method": "get",
      "hidden": true
  }
  const newTrigger = {
    "pollingParams": {
        "dedupe_key": dedupekey,
        "data_head": datahead
    },
    "key": "new"+capitalizeFirstLetter(noun),
    "fields": [],
    "label": "New "+capitalizeFirstLetter(noun),
    "description": "Triggers when a new "+capitalizeFirstLetter(noun)+" is created",
    "type": "polling",
    "action": "list"+capitalizeFirstLetter(noun)
}
  var newAppInfo = JSON.parse(JSON.stringify(appInfo))
  newAppInfo.actions[newAction.key] = newAction
  newAppInfo.triggers[newTrigger.key] = newTrigger
  console.log('newAppInfo POSTED: ', newAppInfo)
  saveToDB(newAppInfo)
  
    }

  const saveToDB = (newAppInfo) => {
    axios({
        headers: {
            'content-type': 'application/json'
        },
        method: 'post',
        url: 'https://editor.workload.co:5000/api/apps/' + newAppInfo.appSlug,
        data: JSON.stringify(newAppInfo)
    }).then(resp => { console.log(resp);  })
}

    return (
        <>
            <div className={styles.Panel}>
            
            <div className={classes.containerHeader}>
                <div classname={classes.headerChild1}>
                    <div style={{paddingLeft: '10px'}}><Typography style={{fontSize: '20px', fontWeight: 600}}>Build a Workload Trigger</Typography>
                    <Typography style={{marginTop: '20px', color: 'gray', fontWeight: 400}} >
                    Workload's community-driven platform allows you to easily create new triggers for {app.label}.
                    </Typography></div>
                </div>
                <div className={classes.headerChild2}>
                    <div> <a href="https://youtube.com" target="_blank"><img src="https://cdn.workload.co/wp-content/uploads/Build-your-own-custom-trigger-in-minutes.png" width='250px' style={{borderRadius: '8px'}}/></a></div>
                </div>
                
            </div>
         
                <Stepper style={{borderRadius: '0px 0px 8px 8px'}} activeStep={activeStep} orientation='vertical'>
                        <Step key="App Step 1" >
                            <StepButton onClick={(e) => { setActiveStep(0) }}><strong>Settings</strong></StepButton>
                            <StepContent>
                            <InputLabel htmlFor={"nountext"}><Typography variant="h6" style={{color: 'black'}}><strong>Object</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"nountext"}><Typography variant="body2">What is the object or item that this trigger is about? Example: <code style={{border: '1px solid lightgray'}}>Contact</code></Typography> </FormHelperText>
                            <br/><TextField variant="outlined"
                            fullWidth
                            id="create-trigger-noun"
                            label="Object"
                            value={noun}
                            onChange={handleNoun} />
                            <br/><br/>
                            <InputLabel htmlFor={"typetext"}><Typography variant="h6" style={{color: 'black'}}><strong>Type</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"typetext"}><Typography variant="body2">Should this trigger run when a <strong>new</strong> <strong>{noun ?? 'item'}</strong> is found, or when an existing <strong>{noun ?? 'item'}</strong> is <strong>updated</strong>?</Typography> </FormHelperText>
                            <br/><TextField select variant="outlined"
                            id="create-trigger-type"
                            label="Trigger Type"
                            value={triggertype}
                            onChange={handleTriggerType}
                            fullWidth >
                            {triggertypes.map((option)=> (
                                <MenuItem disabled={option.active} key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}</TextField>
                                    <br/><br/>
                            <div className={classes.actionsContainer}>
                                <div>
                               
                                <Button
                                    disabled={ noun && triggertype ? 0 : 1 }
                                    style={{width: '100%', textTransform: 'none', fontSize: '18px', fontWeight: 600}}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {handleTriggerEvent(); app.plan === 'free' && app.planStatus === 'active' ? setModalOpenPromo(true) : handleNext();}}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
                                </Button>
                                </div>
                            </div>
                            </StepContent>
                        </Step>
                        <Step key="App Step 2" >
                            <StepButton onClick={(e) => { setActiveStep(1) }}><strong>API Information</strong></StepButton>
                            <StepContent>
                            <div>
                            <InputLabel htmlFor={"apiendpointtext"}><Typography variant="h6" style={{color: 'black'}}><strong>1. Connect to {app.label}</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"apiendpointtext"}><Typography variant="body2">Workload needs permission to access your {app.label} account.</Typography> </FormHelperText>
                            <br />{auths.length === 0 ? <button size="small" id='new-auth-button' labelId='new-auth-button-label'
                            className={classes.signinbutton} 
                            variant="contained"

                            onClick={() => {console.log('https://editor.workload.co:5000/auth/' + app.app + '?uid=' + user + '&t=' + tenant); addAuth('https://editor.workload.co:5000/auth/' + app.app + '?uid=' + user + '&t=' + tenant)}}
                            startIcon={<SecurityRoundedIcon />}><Avatar style={{ left: 110, position: 'absolute', width: breakpoint === 'small' ? '24px' : null, height: breakpoint === 'small' ? '24px' : null  }} src={app.icon} /><Typography variant='h6' style={{ color: '#0069ea', fontWeight: 'bold', fontSize: breakpoint !== 'large' ? '16px' : null }} >Sign in to {app.label}</Typography></button> 
                            
                            : 
                        <>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">{app.label} account</InputLabel>
        
                            <Select
                              id='trigger-authorize'
                              style={{ width: '100%' }}
                              labelId="auth-select-outlined-label"
                              defaultValue=""
                              //value={auth}
                              //onChange={handleChange}
                              label={app.label +" account"}
                            >
                              {auths && auths.length > 0 ? auths.map((item, i) => <MenuItem onClick={() => setCredentialId(item._id.$oid)} id={item.id} key={i} value={`${item.app}` + ' - ' + `${item.name}` + '#' + i + 1}><Typography style={{fontWeight: 'bold', fontSize: '20px'}} >{item.app} - {item.name} #{i + 1}</Typography></MenuItem>) :
                                <MenuItem id='nodata' key='nodata' disabled>+ Add a new connection</MenuItem>} </Select></FormControl> </>
                                } 
                            <SecurityRoundedIcon style={{ marginLeft: '5px', color: 'gray', height: '15px', float: 'left', display: 'block' }} /><Typography style={{ fontSize: '12px', color: 'gray' }}>{app.label} is a secure partner with Workload. <a style={{ color: '#0069ea' }} href="https://workload.co/privacy" target="_blank">Learn more about encryption and storage.</a> </Typography></div>
                            <br/><br/>
                            <InputLabel htmlFor={"apiendpointtext"}><Typography variant="h6" style={{color: 'black'}}><strong>2.GET All {noun} API Endpoint</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"apiendpointtext"}><Typography variant="body2">What is the API Endpoint URL to retrieve a list of {noun.toLowerCase()}s Example: <code style={{border: '1px solid lightgray'}}>https://api.app.com/v2/{noun.toLowerCase()}</code></Typography> </FormHelperText>
                            <br/><TextField variant="outlined"
                          //  disabled={credentialId ? 1 : 0}
                            fullWidth
                            id="create-trigger-endpoint"
                            label="API Endpoint"
                            value={apiEndpoint}
                            defaultValue={apiEndpoint}
                            onChange={handleApiEndpoint} />
                            <br/><br/>
                            <InputLabel htmlFor={"designinputtext"}><Typography variant="h6" style={{color: 'black'}}><strong>3.Design Input Form</strong></Typography></InputLabel>
                            <FormHelperText id={"designinputtext"}><Typography variant="body2">What are the required fields to retrieve a list of {noun.toLowerCase()}s </Typography> </FormHelperText>
                            <br/>
                            { previewMode === false ? <><Chip color="default" style={{color: 'gray'}} label="WORKLOAD EDITOR PREVIEW" variant="outlined" />
                            <br/><br/>
                            <Typography variant="body2">Preview of your app here as you build it in the Form Designer.</Typography>
                            <br/>
                            <Divider />
                            <br/>
                            <Typography variant="h6">Form Designer</Typography></> : 
                            inputForm.map(
                                (element) => {
                                    return (<>
                            <InputLabel htmlFor={element.key}><Typography variant="body2" style={{color: 'black'}}><strong>{element.label}</strong> {element.required === true ? <><div style={{display: 'inline', color: 'red'}}> (required)</div></> : null }</Typography></InputLabel>
                            <br/><TextField variant="outlined"
                            disabled={true}
                            fullWidth
                            id="create-trigger-endpoint"
                            label={element.label}
                            value="Enter text"
                           
                             />
                            <FormHelperText id={element.key + 'helpertext'}><Typography variant="body2">{element.description}</Typography> </FormHelperText>

                            <br/><br/></>
                                    )
                                }
                            )
                            
                            }
                            <br/>
                            {addMode === true ? <div style={{borderTop: '2px solid gray',paddingTop: '40px', marginBottom: '16px'}}>
                            <InputLabel htmlFor={"keytext"}><Typography variant="body2" style={{color: 'black'}}><strong>Key</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"keyhelpertext"}><Typography variant="body2">Enter the key from the {app.label} API documentation. Example: <code style={{border: '1px solid lightgray'}}>companyName</code></Typography> </FormHelperText>
                            <br/><TextField variant="outlined"
                          //  disabled={credentialId ? 1 : 0}
                            fullWidth
                            id="create-triggerinput-endpoint"
                            label="Key"
                            value={key}
                            defaultValue={key}
                            onChange={handleInputKey} />
                              <br/><br/>
                            <InputLabel htmlFor={"labeltext"}><Typography variant="body2" style={{color: 'black'}}><strong>Label</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"labeltext"}><Typography variant="body2">Enter a user friendly and human readable name for this field that accurately describes what to enter. Example: <code style={{border: '1px solid lightgray'}}>Company Name</code></Typography> </FormHelperText>
                            <br/><TextField variant="outlined"
                          //  disabled={credentialId ? 1 : 0}
                            fullWidth
                            id="create-triggerinput-endpoint"
                            label="Label"
                            value={label}
                            defaultValue={label}
                            onChange={handleInputLabel} />
                              <br/><br/> 
                            <InputLabel htmlFor={"desctext"}><Typography variant="body2" style={{color: 'black'}}><strong>Helper Text</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"desctext"}><Typography variant="body2">Clearly describe the purpose of this field. Example: <code style={{border: '1px solid lightgray'}}>Filter by company name</code></Typography> </FormHelperText>
                            <br/><TextField variant="outlined"
                          //  disabled={credentialId ? 1 : 0}
                            fullWidth
                            id="create-triggerinput-endpoint"
                            label="Helper Text"
                            value={description}
                            defaultValue={description}
                            onChange={handleInputDescription} />
                              <br/><br/>
                            <InputLabel htmlFor={"typetext"}><Typography variant="body2" style={{color: 'black'}}><strong>Type</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"typetext"}><Typography variant="body2">What type of data does this field take?</Typography> </FormHelperText>
                            <br/><TextField variant="outlined"
                          //  disabled={credentialId ? 1 : 0}
                            fullWidth
                            select
                            id="create-triggerinput-endpoint"
                            label="Field Type"
                            value={type}
                            defaultValue={type}
                            onChange={handleInputType} > 
                            <MenuItem key='string' value='string'>
                                String
                                </MenuItem>
                                <MenuItem key='integer' value='integer'>
                                Integer
                                </MenuItem>
                                <MenuItem key='number' value='number'>
                                Number
                                </MenuItem>
                                <MenuItem key='boolean' value='boolean'>
                                Boolean
                                </MenuItem>
                                </TextField>
                            <br/><br/>
                            <InputLabel htmlFor={"loctext"}><Typography variant="body2" style={{color: 'black'}}><strong>Location</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"loctext"}><Typography variant="body2">Is this a query param, path param? Use the {app.label} API documentation to find out.</Typography> </FormHelperText>
                            <br/><TextField variant="outlined"
                          //  disabled={credentialId ? 1 : 0}
                            fullWidth
                            select
                            id="create-triggerinput-endpoint"
                            label="Location"
                            value={location}
                            defaultValue={location}
                            onChange={handleInputLocation} > 
                            <MenuItem key='path' value='path'>
                                Path Variable
                                </MenuItem>
                                <MenuItem key='query' value='query'>
                                Query Param
                                </MenuItem>
                                </TextField>
                            <br/><br/>
                            <InputLabel htmlFor={"desctext"}><Typography variant="body2" style={{color: 'black'}}><strong>Options</strong></Typography></InputLabel>
                            <FormHelperText id={"desctext"}><Typography variant="body2">Optional parameters for this field.</Typography> </FormHelperText>
                            
                            <FormControlLabel
                            control={<Checkbox checked={required} onChange={handleInputRequired} name="checkedA" />}
                            label="Required"
                        /></div>
                            : null }
                            {addMode === true ? 
                            <Button variant="contained"
                            disabled={addMode === true && key && label && description && type && location ? 0 : 1}
                            id="create-ipnut-endpoint"
                            color="primary"
                            onClick={handleSaveInput} >Save</Button>
                                :
                            <Button variant="contained"
                            id="create-ipnut-endpoint"
                            color="primary"
                            onClick={handleAddInputForm} >+ Add User Input Field</Button>
                        }
                            <br/><br/>
                            <div className={classes.actionsContainer}>
                                <div>
                               
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={ apiEndpoint && credentialId && inputForm ? 0 : 1 }
                                    onClick={()=> {handleStep2(); handleNext()}}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                                </div>
                            </div>
                            </StepContent>
                        </Step>
                        <Step key="App Step 3" >
                            <StepButton onClick={(e) => { setActiveStep(2) }}><strong>Test Your Trigger</strong></StepButton>
                            <StepContent>
                            <InputLabel htmlFor={"testapitext"}><Typography variant="h6" style={{color: 'black'}}><strong>3. Test Endpoint</strong> (required)</Typography></InputLabel>
                            <FormHelperText id={"testapitext"}><Typography variant="body2">Send a test request and retrieve a list of {noun.toLowerCase()}s.</Typography> </FormHelperText>
                            <br/><Button variant={apiEndpoint  ? "contained" : "outlined"}
                            color="primary"
                            id="create-trigger-testendpoint"
                            label="Test Endpoint"
                            
                            onClick={handleApiTest} >{loadtest === true ? <CircularProgress color="secondary" size={27} /> : "Test Endpoint"}</Button>
                            <br/><br/>
                            {testData ?  <div id='inspector-json' style={{marginBottom: '20px', maxHeight: '350px', overflowX: 'hidden', overflowY:'scroll' }}>
                            <Inspector data={testData} isExpanded={(keypath, query) => true} />
                        </div> : null }
                            <div className={classes.actionsContainer}>
                                <div>
                          
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleFinish}
                                    className={classes.button}
                                    disabled={testData ? false : true}
                                >
                                   Finish
                                </Button>
                                </div>
                            </div>
                            </StepContent>
                        </Step>
                        <Modal
                            style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                open={modalopenpromo}
                                onClose={handleModalClosePromo}
                                aria-labelledby="simple-modal-title"
                                
                            >
                                {body_promo}
                            </Modal>
                    </Stepper>
                    
            </div>
            
        </>
    )
}



export default CreateTriggerPanel

