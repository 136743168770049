import React, { useEffect, useMemo, useState, useRef, Fragment, useLayoutEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';
import styles from '../Panel.module.css';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Box, Tab, CircularProgress, Popper, TextareaAutosize, Input, InputBase, Divider, FormHelperText, Typography, Stepper, Step, StepLabel, StepContent, StepButton, Button, InputLabel, Menu, MenuItem, FormControl, Select, TextField, ListItemIcon, Avatar, ListItemText, Grid, AccordionSummary, InputAdornment, Modal } from '@material-ui/core'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import Authorizer from '../Authorizer'
import Inspector from 'react-json-inspector';
import '../helpers/json-inspector.css';
import '../helpers/workload-tags.css'; //add styling for the tags in input fields
import '../helpers/workload-mode-single-line-style.css';// add .cm-custom-keyword class here
import 'codemirror/addon/search/searchcursor';
import AppsRoundedIcon from '@material-ui/icons/AppsRounded';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import SettingsEthernetRoundedIcon from '@material-ui/icons/SettingsEthernetRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import { WarningRounded } from '@material-ui/icons';
import { Alert, AlertTitle, Autocomplete, createFilterOptions } from '@material-ui/lab';
import { alertName } from '../../redux/ducks/notification';
// import { ptree } from '../../redux/ducks/pTree';
// import { nodeid } from '../../redux/ducks/nodeID';
import Form from "@rjsf/material-ui";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { FieldsRender, DynamicFieldsRender } from '../helpers/panel-functions'
import { useStore, useZoomPanHelper, useStoreState } from 'react-flow-renderer';
import DataInspector from './DataInspector';


import {
    nodeAdded, edgeAdded, elementUpdated, nameUpdated, elementDeleted, fetchWorkflow, nodeEdgeAdded, saveWorkflow, elementSelected, testNode, selectLoadingPerform,
    selectWorkflowElements, selectElementById, selectWorkflowName, selectWorkflowId, selectParentTree, selectWorkflowTemplate, selectWorkflowTemplateNodes, selectWorkflowActive, selectCurrentElement
} from '../../features/workflow/workflowSlice'

import { selectAllActions } from '../../features/allApps/allAppsSlice'
import { isArray } from 'util';

//import { selectAllPopular } from '../../features/allApps/allAppsSlice'

Function.deserialise = function (key, data) {
    return (data instanceof Array && data[0] == 'window.Function') ?
        new (Function.bind.apply(Function, [Function].concat(data[1], [data[2]]))) :
        data
        ;
};

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function Carat(props) {
    const isActive = props.active;
    if (isActive) {
      return <KeyboardArrowUpRoundedIcon />;
    }
    return <KeyboardArrowDownRoundedIcon />;
  }

function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

function getModalStyle() {
    const top = 25
    //  const left = 50 + rand();

    return {
        top: `${top}%`,
        margin: 'auto'
        // left: `${left}%`,
        // transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    removeappbutton: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    actionsContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        zIndex: 99,
        marginTop: '20px'
    },
    resetContainer: {
        padding: theme.spacing(1),
    },
    selectInput: {
        '*': {
            display: 'flex'
        }
    },
    chiproot: {
        display: 'flex',
        left: 5,
        marginLeft: '20px',
        width: '99%',
        float: 'left',
        '& > *': {
            margin: '-10px',

        },
        "& > *:hover": {
            border: '2px solid',
            borderColor: "#0069ea"
        }
    },
    accordionroot: {
        width: '90px',
    },
    accordionheading: {
        fontWeight: 700,
    },
    dropdownroot: {
        position: 'relative',
    },
    small: {
        width: '22px',
        height: '22px',
        marginRight: '4px'
    },
    dropdown: {
        position: 'absolute',
        width: '500px',
        right: 0,
        left: 10,
        zIndex: 5,

        // borderTop: '1px solid',
        // borderLeft: '1px solid',
        boxShadow: "1px 0px 0px lightgrey",
        borderColor: 'lightgrey',
        borderRadius: '5px',
        padding: '4px',
        backgroundColor: 'white',
        opacity: '1',
        overflowX: "hidden",
        height: 'auto',
        maxHeight: '500px',
        overflowY: "auto"

    },
    codemirror: {
        marginTop: '7px',
        border: '1px solid',
        borderColor: 'lightgrey',
        borderRadius: '4px',
        paddingTop: '4px',
        paddingRight: '4px',
        paddingBottom: '4px',
        justifyContent: 'center',
        width: 'inherit',
        height: '48px',
        display: 'relative',
        //position: 'absolute'
        '&:hover': {
            borderColor: 'black',
            border: '1px solid'
        },
        '&:focus-within': {
            borderColor: '#0069ea',
            border: '2px solid'
        }
    },
    resetContainer: {
        padding: theme.spacing(1),
    },
    modal: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    headerbox: {
        width: '500px',
        marginLeft: '20px',
        marginBottom: '20px',
        display: 'block'
    },
    popper: {
        border: '1px solid lightgray',
        borderRadius: '4px',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        zIndex: 999,
        height: '400px',
        boxShadow: '0 5px 5px 5px lightgray',
        width: '500px'
    },
    tabs: {
        flexGrow: 1
    }
}));

const muiTheme = createMuiTheme({
    palette: {
        primary: { main: '#0069ea' },
        secondary: { main: '#ff5555' }
    },
    props: {
        MuiTextField: {
            variant: 'outlined'
        }
    }
});

const globalHttpRequest = {
    "workload_http": {
        "title": "Custom Request",
        "fields": [
            {
                "name": "HTTP Request",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "object",
                    "properties": {
                        "requestType": {
                            "title": "Request Type",
                            "type": "string",
                            "enum": [
                                "GET",
                                "POST",
                                "PUT",
                                "PATCH",
                                "DELETE"
                            ]
                        },
                        "url": {
                            "title": 'URL',
                            "type": "string"
                        },
                        "params": {
                            "title": "URL Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "title": "Key",
                                        "type": "string"
                                    },
                                    "value": {
                                        "title": "Value",
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "stringBody": {
                            "type": "string",
                            "title": "JSON Body",
                            "description": "Note: this field is ignored for GET requests"
                        }
                    }
                }
            }
        ],
        "url": "",
        "method": ""
    }
}

function ActionPanel({ selectedNode, deleteNode, sendNode, workflowID, parentTree, user, tenant, onPaneClick, sendLoggedOutToAuthorizer, breakpoint }) {
    const node = useSelector(selectElementById(selectedNode.id))
    //console.log('actionpanel node is: ', node)
    const [name, setName] = useState(node.data.name);
    const [app, setApp] = useState(node.data.type);
    const [label, setLabel] = useState(node.data.label);
    const [icon, setIcon] = useState(node.data.icon);
    const [custombutton, setCustomButton] = useState(node.data.custombutton)
    const allApps = useSelector(selectAllActions)
    const [actions, setActions] = useState([]);
    const workflow = useSelector(selectWorkflowTemplateNodes)
    const loadingperform = useSelector(selectLoadingPerform)
    const [loadedActions, setLoadedActions] = useState(false);
    const [action, setAction] = useState(node.data.action);
    const [actionlabel, setActionLabel] = useState(node.data.actionlabel ?? "")
    const [credentialId, setCredentialId] = useState(node.data.credential)
    const [authname, setAuthname] = useState(node.data.authname)
    const [loading, setLoading] = useState(true);
    const [nosave, setNoSave] = useState(0);
    const [url, setURL] = useState(node.data.url)
    const [actionTestTime, setActionTestTime] = useState(node.data.actionTestTime)
    const [showTestData, setShowTestData] = useState(false)
    const [sentData, setSentData] = useState()
    const [activeStep, setStep] = useState(0)
    const [fields, setFields] = useState(node.data.fields)
    const [hasauth, setHasAuth] = useState(node.data.hasauth)
    const [testData, setTestData] = useState(node.data.testData)
   
    const bottomRef = useRef(null);
    const hasDynamicForm = action && Object.keys(actions).length > 0 && actions[action]?.dynamicForm
    const finalStep = (hasauth ? hasDynamicForm ? 6 : 5 : 4)
    const testStep = hasauth ? hasDynamicForm ? 5 : 4 : 3
    const dynamicStep = hasauth ? 4 : 3
    const { zoomIn, zoomOut, setCenter } = useZoomPanHelper();
    const store = useStore();
    const storeState = useStoreState((state) => state);
    useEffect(() => {
        //set initial active step
        const { nodes } = store.getState();
       // console.log('nodes is:::: ', nodes)
      
      

     //  console.log('store: ', storeState)
        const node_obj = nodes.filter(item => item.id === node.id)[0]
        const x = node_obj.__rf.position.x + node_obj.__rf.width ;
        const y = node_obj.__rf.position.y + node_obj.__rf.height / 2;
        const zoom = 1.15
        setCenter(x, y, zoom);
       /*  if (testData) {
            setStep(finalStep)
        } else if (action && (!hasauth || (hasauth && authname))) {
            setStep(testStep)
        } else if (action && hasauth && authname) {
            setStep(3)
        } else if (action) {
            setStep(2)
        } else if (app) {
            setStep(1)
        } else {
            setStep(0)
        } */
    }, [node])




    const dispatch = useDispatch();





    const colors = [
        'primary',
        'secondary'
    ];

    const [color, setColor] = useState(0);

    useEffect(() => {
        if (loading === false) {
            const interval = setInterval(() => {
                setColor((v) => (v === 2 ? 0 : v + 1));
            }, 1500);
        } else {
            return null;
        }
    }, []);



    const saveNode = () => {
        //   console.log('saving node')
        //  console.log(node)
        const newNode = node
        dispatch(elementUpdated(selectedNode.id,
            {
                name: name,
                type: app,
                actionlabel: actionlabel,
                label: label,
                icon: icon,
                hasauth: hasauth,
                custombutton: custombutton,
                action: action,
                credential: credentialId,
                authname: authname,
                fields: fields,
                testData: testData,
                url: url,
                actionTestTime: actionTestTime
            },
            null
        ));
    }

    const testNodeClick = () => {
       // setTestLoading(true);
      //  setTestData("");
       // saveNode(); 
        setStep(activeStep + 1);
        setActionTestTime(Date.now());
      //  console.log('sending this node information:::: ', selectedNode)
        dispatch(testNode(selectedNode.id))
    }


    useEffect(() => {
        let isMounted = true;
        async function getActions() {
            setLoadedActions(false)
            axios.request({
                method: "GET",
                url: url
            }).then(res => {
                if (isMounted) {
                  //  console.log('the actions 328 are: ');
                   // console.log(res);
                    //setActions(Object.assign(globalHttpRequest, res.data.actions) ?? []);
                    setActions(res.data.actions ?? []);
                    setLoadedActions(true);
                }
            });
        }
        if (app) {
            setActions([])
            getActions()
        }
        return () => { isMounted = false };
    }, [app])

    useEffect(() => {
       
        let isMounted = true;
        if (Object.keys(actions).length > 0 && isMounted) { setFields(action === 'Custom Request' ? globalHttpRequest['workload_http'].fields : actions[action] ? actions[action].fields : '') }
        return () => { isMounted = false };
    }, [action])



    //whenever a field is updated, send the change to redux
    useEffect(() => {
        saveNode()

    }, [name, app, label, icon, hasauth, action, credentialId, authname, fields])

    useEffect(() => {
        var ticketString = localStorage.getItem("tickets")
        var ticketJSON = ticketString ? JSON.parse(ticketString) : []

       // console.log('action data has a url?: ', actions[action]?.hasOwnProperty("url"))
       if ( ticketJSON.includes(actions[action]?.title+" Action Incomplete_"+label) === false && app !== null && action !== null && actions[action]?.hasOwnProperty("url") === false) {
          // console.log('triggers are empty...sending a support ticket now!')
           handleSubmitTicket(actions[action]?.title+" Action Incomplete")
       }
    }, [action])

    //refresh a field from redux after making async call (i.e. testNode)
    useEffect(() => {
        if (testLoading === true) {
            setTestData(node?.data.testData)
                setTestLoading(false)
               // console.log('test DATAAAAAAA:::: ', node.data.testData)
        }
      
        
        if (node.data.fields) {
            var res = node.data.fields.find(item => item.name === 'body');

            if (loadingperform === true && res && res.schema && Boolean(Object.keys(res.schema.properties).length === 0) === false) {
                setFields(node.data.fields)

            }
        }
    }, [node])

    const setNodeNameAuto = (newApp, newAction) => {
        // node.data.name = label + ' - ' + e;
        if (name == 'Click to Add Action') {
            setName((newAction ? (newAction + ' in ' + newApp) : ""));
        } 
        else if (newApp === 'Custom Request') {
          //  console.log('is this getting hit????')
            setName('Custom Request in ' + label)
        } else if (name == label) {
            setName((newAction ? (newAction + ' in ' + newApp) : ""));
        } else if (!name) {
            setName((newAction ? (newAction + ' in ' + newApp) : ""));
        } else if (name == (label + ' - ' + action)) {
            setName((newAction ? (newAction + ' in ' + newApp) : ""));
        }  else {
            setName((newAction ? (newAction + ' in ' + newApp) : ""));
        }
       // console.log('new app is: ', newApp)
       // console.log('new action is: ', newAction)
       // console.log('label is: ', label)
        // saveNode(node);
        //  console.log('name is now, ', node.data.name)
    }
const setCustomRequestName = (f) => {
    if (f) {
//console.log('custom fields to parse through are: ', f)
        let method = null
        let noun = null
        if (f[0].value.requestType === 'GET') {
            method = 'Find a'
        }  if (f[0].value.requestType === 'PUT' || f[0].value.requestType === 'PATCH') {
            method = 'Update a'
        } if (f[0].value.requestType === 'POST') {
            method = 'Create new'
        } if (f[0].value.requestType === 'DELETE') {
            method = 'Remove a'
        } 
        const extractResource = (url) => {
            // Create a URL object to easily access the pathname
            const urlObj = new URL(url);
            const pathParts = urlObj.pathname.split('/'); // Split the pathname by '/'
          
            // Filter out empty strings, variables (e.g. {tenant}), and numeric IDs
            const filteredParts = pathParts.filter(part => part && !part.includes('{') && isNaN(part));
            
            // Assume the resource is the last remaining non-ID, non-variable part
            const resource = filteredParts[filteredParts.length - 1];
          
            // Convert plural resource to singular
            return pluralToSingular(resource);
          };
          
          // Basic plural to singular function
          const pluralToSingular = (word) => {
            if (word.endsWith('ies')) {
              return word.slice(0, -3) + 'y';  // e.g. "companies" -> "company"
            } else if (word.endsWith('es')) {
              return word.slice(0, -2);  // e.g. "boxes" -> "box", "watches" -> "watch"
            } else if (word.endsWith('s')) {
              return word.slice(0, -1);  // e.g. "customers" -> "customer"
            }
            return word; // If it's not plural, return as is
          };
          noun = extractResource(f[0].value.url);
        setName(method + ' ' + noun + ' in ' + label)
    }
}
    const classes = useStyles();

    const resetForm = () => {
        setStep(activeStep - 4)
        // setTestData(null);
    }

    const handleSaveClick = () => {
        //   console.log('save clicked')
        dispatch(saveWorkflow(1))
    }

    const sendActionData = (data) => {
        mixpanel.track('Action Select', {
            'action': data
        });
    }

    const sendActionEventData = (data, event) => {
        mixpanel.track('Action Event Selected', {
            'app': data,
            'event': event
        });
    }

    const sendAlert = () => {
        dispatch(alertName({ "severity": "success", "message": node.data.name + " saved successfully" }))
    }
    const [actionsopen, setActionsOpen] = useState(false);


    //format actions for autocomplete search fields
    const actionoptions = actions ?
        Object.entries(actions).map(([k, v]) => {

             // console.log('v equals ', v)
            const words = k.split(' ');
            const firstLetter = words[0].toUpperCase();
            return {
                label: k,
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                title: v.title,
                hidden: v.hidden,
                description: v.description ?? "",
                group: v.method === "post" ? "CREATE" : v.method === "get" ? "SEARCH" : "UPDATE"
            }
        }) :
        []

    //format actions for autocomplete search fields


  

    function sleep(delay = 0) {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }
    const [appopen, setAppOpen] = useState(false);
    const [appoptions, setAppOptions] = useState([]);
    const [apploading, setAppLoading] = useState()

    useEffect(() => {
        let active = true;

        if (!apploading) {
            return undefined;
        }

        (async () => {
            const response = allApps;
            await sleep(23); // For demo purposes.
            const apps = await response;
            if (active) {
                setAppOptions(Object.keys(apps).map((key) => apps[key]));
              //  console.log('done loading apps')
                setAppLoading(false)
            }
        })();

        return () => {
            active = false;
        };
    }, [apploading]);

    useEffect(() => {
        if (!appopen) {
            setAppOptions([]);
            setAppLoading(true);
        }
    }, [appopen]);




    const openDropdown = (event) => {
        setAnchorElMenu(event.currentTarget);
    }

    const closeDropdown = (event) => {
        setAnchorElMenu(null);
    }
    const StyledMenu = withStyles({
        paper: {
            border: '1px solid #d3d4d5',
        },
    })((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ));

    //handling of dropdown menu
    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    //handling of custom data popper
    const [anchorElPop, setAnchorElPop] = React.useState(null);
    const handlePopClick = (event) => {

        setAnchorElPop(anchorElPop ? null : event.currentTarget);
    };
    const handlePopClose = (event) => {
       // console.log(event)
        setAnchorElPop(null);
    };
    const openpop = Boolean(anchorElPop);
    const id = openpop ? 'simple-popper' : undefined;
    const [poptext, setPopText] = React.useState();

    const allAppsFilterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 50
    })

    const [tabvalue, setTabValue] = React.useState(1);
    const handleTabChange = (newValue) => {

        setTabValue(newValue);
    };

    const [tabApps, setTabApps] = React.useState();

    useEffect(() => {
        if (tabvalue === 0) {
            setTabApps(allApps.filter(item => item.source === 'first100'))

        }
        if (tabvalue === 1) {
            setTabApps(allApps)
        }
        if (tabvalue === 2) {
            setTabApps(allApps.filter(item => item.auth === false))
        }
    }, [tabvalue])

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
       // bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [testData]);

    const flattenObj = (ob) => {

        // The object which contains the
        // final result
        let result = {};

        // loop through the object "ob"
        for (const i in ob) {

            // We check the type of the i using
            // typeof() function and recursively
            // call the function again
            if ((typeof ob[i]) === 'object') {
                const temp = flattenObj(ob[i]);
                for (const j in temp) {

                    // Store temp in result
                    result[i.replace('body.', '') + '.' + j] = temp[j];

                }
            }

            // Else store ob[i] in result directly
            else {
                result[i] = ob[i];
            }
        }
        return result;
    };

   
    const [testLoading, setTestLoading] = useState(false)

   
    const handleSentData2 = (e) => {
       // console.log('is this wut fux up??? : ', e)
        let results = e.filter(obj => {
        //    console.log('obj is: ', obj.value);
            return obj.value !== undefined && obj.value !== "" && obj.hidden !== true;
        });
        
       
        var checkrefs = flattenObj(results.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {}))
       // console.log('new results: ', results)
        const widgetRe =  /\${(([^}][^}]?|[^}]}?)*)}/ig;
        

        for (const property in checkrefs) {

            if (checkrefs[property].toString().includes('${')) {
                const matcharray = [...checkrefs[property].match(widgetRe)];
                matcharray.map((item) => {
                    
                  //  console.log('item from map: ', item)
                    var splitprop = item.split(".")
                  //  console.log('split props are: ', splitprop)
                    var nodelook = splitprop[1]
                    var nodekey = splitprop.slice(-1)
                   // console.log('nodekey: ', nodekey)
                    var nodekey_concat = nodekey.join('.')
                    var clean = nodekey_concat.replace("}", "")
                   // console.log('nodekey concat: ', clean)
                    var getvalue = workflow[nodelook].outputFields
                 //  console.log('getvalue is: ', getvalue)
                   
                  const recursiveSearch = (obj, searchKey, results = []) => {
                    const r = results;
                    if (obj) {
                    Object.keys(obj).forEach(key => {
                       const value = obj[key];
                       
                       if(key === searchKey && typeof value !== 'object'){
                        //console.log('key is::: ', key)
                        //console.log('value is::: ', value)
                          r.push(value);
                          //console.log('var r push: ', r)
                       }
                       else if (key === searchKey && Array.isArray(value)) {
                      //  console.log('finally...its an array', searchKey, value)
                        r.push(JSON.stringify(value).substring(0, 250) + "...");
                    } 
                       else if(typeof value === 'object'){
                          recursiveSearch(value, searchKey, r);
                       }
                      
                    })};
                    return r;
                 };
                 const replaced_value = recursiveSearch(getvalue, clean)[0];
               //('replaced value:: ', replaced_value)
                    const cleanref = checkrefs[property].replaceAll(item, replaced_value)
                //    console.log('clrenref is now: ', cleanref)
                    checkrefs[property] = cleanref
                    
                })
             //   console.log('array of widgets: ', matcharray)
             //   console.log('checkrefs properties: ', checkrefs[property])
                
                
              //  console.log('clean ref is: ', cleanref)
               // checkrefs[property] = cleanref

              
            }
            if (property.includes('body.')) {

                var new_prop = property.replace('body.', '')
                checkrefs[new_prop] = checkrefs[property];
                delete checkrefs[property]
            }
        }
       
        setSentData(checkrefs)

    }

    const handleSubmitTicket = (reason,) => {
       
       // console.log('event for mike is:::', actions ? actions[action]?.label : "")
        const email = localStorage.getItem('email')
     
        mixpanel.track('Support Ticket Created', {
            'Email': email ? email : 'undefined',
              'App': app,
              'Title': label + " "+reason,
              'Label': label,
              'Decription': "When I use "+label+" there is a problem: "+reason
          });
           let config_send = {
            method: 'post',
            url: 'https://editor.workload.co:5000/createTicket',
            headers: { 
                
                'Content-Type': 'application/json'
            },
            data: {
              editor_url: window.location.href,
              tenantId: tenant,
              email: email,
              title: label + " "+reason,
              content: "When I use "+label+" there is a problem: "+reason,
              app: label,
              event: actions ? actions[action]?.title : ""
            }
          }
          
      
            axios(config_send)
            .then((response) => {
          // console.log(response.data);
           var ticketString = localStorage.getItem("tickets")
           var ticketJSON = ticketString ? JSON.parse(ticketString) : []
          // console.log("tickets started with: ", ticketJSON)
           ticketJSON.push(reason+"_"+label)
           function removeDuplicates(arr) { 
              return [...new Set(arr)]; 
          } 
          var newTicketJSON = removeDuplicates(ticketJSON)
          let ticketstring = JSON.stringify(newTicketJSON) 
          localStorage.setItem("tickets", ticketstring) 
           
      
      })
      .catch((error) => {
      console.log(error);
     
      });
      
        }


    useEffect(() => {
        var ticketString = localStorage.getItem("tickets")
         var ticketJSON = ticketString ? JSON.parse(ticketString) : []
      
        if ( actionsopen === true  && app && actionoptions && actionoptions.length === 0 && ticketJSON.includes("Action Dropdown Empty_"+label) === false && loadedActions === true ) {
          //  console.log('triggers are empty...sending a support ticket now!')
         // console.log(console.log('action options lenght is: ',actionoptions.length))
            handleSubmitTicket("Action Dropdown Empty")
        }
      }, [actionsopen, loadedActions])

    const PaperComponentCustom = options => {
       // console.log('paperoptions are::: ', options)

        const { containerProps, children } = options;
        return (
            <Paper {...containerProps} style={{ fontWeight: 600, padding: 4 }}>
                {children}

                <div>
                    <br />
                    <Divider /><br />

                    <Typography variant='body2' align='center' style={{ color: 'gray' }}>Can't find the <span style={{ padding: 4, alignItems: 'baseline', fontWeight: 300, fontSize: '16px', border: '1px solid gray', borderRadius: '4px' }}><div style={{ display: 'inline-flex', alignItems: 'flex-end', marginLeft: '0px', marginTop: '4px', padding: '0px 0px 0px 0px' }} ><img src={icon} style={{ height: '25px', width: '25px', marginBottom: '-6px', paddingRight: '4px', position: 'absolute' }} /></div><div style={{ display: 'inline-flex', marginLeft: '29px' }}>{label}</div></span> action you're searching for?</Typography><br />
                </div>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Button variant='contained'
                        style={{ textTransform: 'none', backgroundColor: 'black', color: 'white', fontWeight: 600, marginBottom: '10px' }}
                        onMouseDown={() => {
                            var custom = globalHttpRequest['workload_http']
                            console.log('custom action selected: ', custom);
                            setFields(null); setFields(custom.fields); setNodeNameAuto(custom.title); setAction(custom.title); setActionLabel(custom.title); saveNode(); setStep(activeStep + 1);
                        }}
                    >
                        + Create custom action
                    </Button></div>

            </Paper>
        );
    };



    

    // console.log(fields)
    // console.log(actions)
    // console.log(action)
    try {
        return (
            node ?
                <div className={styles.Panel} style={{paddingTop: '75px'}}>
                    <div id='header-top' style={{ backgroundColor: 'lightgray !important' }}>
                        <div id="headerbox" className={styles.headerbox}>
                            <div style={{ marginLeft: '8px', display: 'block', padding: 0, position: 'absolute', top: 0, right: 0, justifyContent: 'center', alignItems: 'center', marginRight: '12px', marginTop: '75px' }}>
                                <IconButton color="primary" style={{ color: 'black', borderRadius: 50, padding: 2, float: 'right' }} aria-label="close modal" onClick={() => { saveNode(); handleSaveClick(); onPaneClick() }}>
                                    <HighlightOffRoundedIcon fontSize={breakpoint !== 'large' ? 'small' : 'medium'} />
                                </IconButton><br />
                                <IconButton color="primary" style={{ marginTop: breakpoint === 'small' ? '0px' : '8px', color: 'black', borderRadius: 50, padding: 1, float: 'right' }} aria-label="menu-context" onClick={openDropdown}>
                                    <MoreHorizRoundedIcon fontSize={breakpoint !== 'large' ? 'medium' : 'large'} />
                                </IconButton>
                            </div>
                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorElMenu}
                                keepMounted
                                open={Boolean(anchorElMenu)}
                                onClose={closeDropdown}
                            >
                                <MenuItem onClick={() => { onPaneClick(); dispatch(elementDeleted(selectedNode.id)); handleSaveClick() }}>
                                    <ListItemIcon>
                                        <DeleteForeverRoundedIcon fontSize="small" color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText style={{ marginRight: 20 }} primary="Delete Step" />
                                </MenuItem>
                            </StyledMenu>
                            {icon ?
                                <img style={{ width: breakpoint !== 'large' ? '36px' : '48px', height: breakpoint !== 'large' ? '36px' : '48px', float: 'left', boxShadow: '-1px 2px 3px 2px silver', borderRadius: '50%', marginLeft: breakpoint !== 'large' ? '5px' : null }} src={icon} />
                                : <Avatar style={{ marginRight: '10px', float: 'left', }}>
                                    <AppsRoundedIcon />
                                </Avatar>
                            }
                            <Input startAdornment={
                                <InputAdornment position="start">
                                    <CreateRoundedIcon style={{ color: 'silver', fontSize: breakpoint !== 'large' ? '14px' : null }} />
                                </InputAdornment>
                            } style={{ width: breakpoint === 'small' ? '175px' : null, marginTop: '5px', marginLeft: breakpoint !== 'large' ? '8px' : '16px', minWidth: breakpoint !== 'large' ? '50%' : '75%', fontSize: breakpoint !== 'large' ? '16px' : '24px', fontWeight: 'bold' }} id="name" onChange={e => { 
                               // console.log(node); 
                                setName(e.target.value); saveNode(); handleSaveClick(); }} defaultValue={node.data.name} value={node.data.name === 'Choose an Action' ? 'Name this Step' : name} placeholder="Name this step..." />

                        </div>
                        <div style={{ float: 'clear', marginBottom: '20px' }}></div>
                    </div>
                    <Divider />
                    <Stepper style={{ borderRadius: '0px 0px 8px 8px' }} activeStep={activeStep} orientation='vertical' connector={<div style={{height:'20px', borderBottom: '1px solid lightgray', marginBottom:'25px' }} />}>
                        <Step key="App Step">
                           {activeStep === 0 && !app ? <StepButton icon={<WarningRounded style={{color: 'orange'}} />} onClick={(e) => { setStep(0) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>App</strong></Typography><Carat active={activeStep === 0 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> : <StepButton onClick={(e) => { setStep(0) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>App</strong></Typography><Carat active={activeStep === 0 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton>   }
                            <StepContent>
                                <br />
                                <Paper className={classes.tabs}>
                                    <Tabs style={{ marginBottom: 15 }}
                                        value={tabvalue}
                                        onChange={handleTabChange}
                                        variant="fullWidth"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="icon label tabs example"
                                    >
                                        <Tab onClick={() => handleTabChange(0)} icon={<FavoriteRoundedIcon style={{ fontSize: breakpoint === 'small' ? '18px' : null }} />} label={breakpoint === 'small' ? '' : "Popular"} />
                                        <Tab onClick={() => handleTabChange(1)} icon={<AppsRoundedIcon style={{ fontSize: breakpoint === 'small' ? '18px' : null }} />} label={breakpoint === 'small' ? '' : "ALL"} />
                                        <Tab onClick={() => handleTabChange(2)} icon={<BuildRoundedIcon style={{ fontSize: breakpoint === 'small' ? '18px' : null }} />} label={breakpoint === 'small' ? '' : "Tools"} />
                                    </Tabs>

                                    <FormControl variant="outlined" style={{ width: '90%', justifyContent: 'center', alignItems: 'center', display: 'flex', margin: 'auto' }} >
                                        <Autocomplete
                                            autoComplete
                                            id="action-combo-box-demo"
                                            options={tabApps ?? allApps}
                                            value={label}
                                            open={appopen}
                                            filterOptions={allAppsFilterOptions}
                                            onOpen={() => {
                                                setAppOpen(true);
                                            }}
                                            onClose={() => {
                                                setAppOpen(false);
                                            }}
                                            loading={apploading}
                                            getOptionLabel={(option) => option.name ?? label}
                                            groupBy={(option) => option.group.length > 0 ? option.group : option.category}
                                            style={{ width: '100%', height: '60px' }}
                                            onChange={(e, v) => { v === null ? v = "" : setAction('');                                            setActions(''); setActionLabel(''); setTestData('');setFields(''); setNodeNameAuto(v.name, ''); setApp(v.app); sendActionData(v.app); setIcon(v.icon);setCustomButton(v.custombutton); setHasAuth(v.auth); setLabel(v.name); setURL(v.formDataEndpoint); saveNode(); setStep(!v ? activeStep : activeStep + 1) }}
                                            renderOption={(option) => (
                                                <li style={{ listStyleType: 'none', padding: 2 }} id={option.name} key={option.name}>
                                                    <Box display="flex">
                                                        <Box style={{float: 'left'}}>
                                                        <Avatar style={{ padding: '4px', width: '32px', height: '32px', marginRight: '10px', float: 'left' }} src={option.icon} />
                                                    </Box>
                                                    <Typography style={{marginTop: '5px', fontWeight: 'bold', fontSize: '20px'}}>{option.name}</Typography>
                                                    </Box>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <div style={{ position: "relative", width: '100%' }}>
                                                    {params.inputProps.value && (

                                                        <span>
                                                            {/* Write logic to have Icon from params.inputProps.value */}
                                                            {<Avatar style={{
                                                                position: "absolute",
                                                                transform: "translateY(40%)",
                                                                marginLeft: "10px",
                                                                width: '32px', height: '32px', marginRight: '30px'
                                                            }} src={icon} />}
                                                        </span>
                                                    )}
                                                    <TextField
                                                        autoFocus={true}
                                                        {...params}
                                                        label="Type to search for an App"
                                                        variant="outlined"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "off",
                                                            style: { paddingLeft: "45px", width: '100%' } // disable autocomplete and autofill
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {allApps.length === 0 ? <CircularProgress style={{ marginRight: 20 }} color={colors[color]} size={30} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            )

                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                        <FormHelperText>Type the name of the app that you want to connect to.</FormHelperText><br />

                                    </FormControl>
                                </Paper>
                                <br /><br />
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button style={{ width: '100%', textTransform: 'none', fontWeight: '600', fontSize: breakpoint !== 'large' ? '14px' : '18px' }} disabled={app ? 0 : 1} variant="contained" color="primary" onClick={e => { saveNode(); setStep(activeStep + 1); }}>Continue</Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>

                        <Step key='Action Step' disabled={app ? 0 : 1}>

                           {activeStep === 1 && !action ? <StepButton icon={<WarningRounded style={{color: 'orange'}} />} onClick={(e) => { setStep(1) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Event</strong></Typography><Carat active={activeStep === 1 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> : <StepButton onClick={(e) => { setStep(1) }}><div><Typography style={{ fontSize: '18px', display: 'block', float:'left' }}><strong>Event</strong></Typography><Carat active={activeStep === 1 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> }
                            <StepContent>

                                {actions ?

                                    <Autocomplete
                                        autoComplete
                                        freeSolo={true}
                                        id="choose-action"
                                        open={actionsopen}
                                        value={action ?? ''}
                                        onOpen={() => {
                                            setActionsOpen(true);
                                          //  console.log('the actionoptions are: ', actionoptions.filter(item => item.hidden !== true))
                                        }}
                                        onClose={(e, reason) => {
                                        //    console.log('reason is: ', reason)
                                            
                                            setActionsOpen(false);
                                        }}
                                        loading={!loadedActions}
                                        loadingText={"Loading Actions..."}
                                        noOptionsText={"Looks like Workload doesn't have any actions. You can still use this app with Custom Actions (below)."}
                                        options={actionoptions.filter(item => item.hidden !== true).sort((a, b) => a.group.localeCompare(b.group))}
                                        groupBy={(option) => option.group}
                                        getOptionLabel={(option) =>  option.title ?? option.label ?? actionlabel }
                                        getOptionSelected={(opt, val) => opt.label == val.label}
                                        onChange={(e, v) => { v === null ? v = "" : v === action ? setStep(activeStep +1) :  setNodeNameAuto(label, v.title ?? v.label); setAction(v.label); setActionLabel(v.title ?? v.label);sendActionEventData(app, v.label); saveNode(); }}
                                        style={{ width: '100%' }}
                                        PaperComponent={PaperComponentCustom}
                                        renderOption={(option) => (
                                            <li style={{ listStyleType: 'none', padding: 4 }} id={option.title} key={option.label}>
                                                <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>{option.title ?? option.label}</Typography>
                                                <Typography style={{fontWeight: 300, fontSize: '16px' }}>{option.description}</Typography>
                                            </li>
                                        )}
                                        renderInput={(params) => <TextField {...params} label={breakpoint === 'small' ? 'Actions' : 'Click for actions'} variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {!loadedActions ? <CircularProgress color={colors[color]} size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }} />}

                                    /> :
                                    null
                                }

                                <div className={classes.actionsContainer}>
                                    <div><br />
                                        
                                        <Button style={{ width: breakpoint !== 'large' ? '100%' : '100%',  textTransform: 'none', fontWeight: '600', fontSize: breakpoint !== 'large' ? '14px' : '18px' }} disabled={action ? 0 : 1} variant="contained" color="primary" onClick={e => { setStep(activeStep + 1); saveNode() }}>Continue</Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>

                        {hasauth ?
                            <Step key='Auth Step' disabled={action && hasauth ? 0 : 1}>
                              {hasauth === true && !credentialId || hasauth === true && credentialId === "" ? <StepButton icon={<WarningRounded style={{color: 'orange'}}/>} onClick={(e) => { setStep(2) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Account</strong></Typography><Carat active={activeStep === 2 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> : <StepButton onClick={(e) => { setStep(2) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Account</strong></Typography><Carat active={activeStep === 2 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> }
                                <StepContent>
                                    <Authorizer
                                        user={user}
                                        sendLoggedOutToAuthorizer={sendLoggedOutToAuthorizer}
                                        tenant={tenant}
                                        node={node}
                                        app={app}
                                        applabel={label}
                                        icon={icon}
                                        sendData={setCredentialId}
                                        sendAuth={setAuthname}
                                        saveNode={saveNode}
                                        value={authname}
                                        breakpoint={breakpoint}
                                        custombutton={custombutton}
                                    />
                                    <div className={classes.actionsContainer}>
                                        <div><br />
                                            
                                            <Button style={{ width: '100%', textTransform: 'none', fontWeight: '600', fontSize: breakpoint !== 'large' ? '14px' : '18px' }} disabled={credentialId ? 0 : 1} variant="contained" color="primary" onClick={e => {saveNode(); handleSaveClick(); setStep(activeStep + 1) }}>Continue</Button>
                                        </div>
                                    </div>

                                </StepContent>
                            </Step> :
                            null}

                        <Step key='Set-up Step' disabled={action && (!hasauth || (hasauth && authname)) ? 0 : 1}>
                           {fields && fields.some(e => e.value) === false ? <StepButton icon={<WarningRounded style={{color: 'orange'}} />} onClick={(e) => { hasauth ? setStep(3) : setStep(2) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Action</strong></Typography><Carat active={hasauth && activeStep === 3 ? true : !hasauth && activeStep === 2 ? true : false } style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> : <StepButton onClick={(e) => { hasauth ? setStep(3) : setStep(2) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Action</strong></Typography><Carat active={hasauth && activeStep === 3 ? true : !hasauth && activeStep === 2 ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> }
                            <StepContent>
                                <Alert severity='info'>
                                    <Typography style={{ fontSize: '14px' }}>Reference and map data from previous steps using the <strong>Insert Data</strong> menu by clicking into a field.</Typography>
                                </Alert>
                                <FieldsRender breakpoint={breakpoint} fields={fields} setFields={setFields} parentTree={parentTree} nodeID={node.id} disabled={false}  />
                                <div id='action-setup' style={{ width: '450px' }}></div>

                                <div className={classes.actionsContainer}>

                                    <div><br />
                                        
                                        <Button disabled={loadingperform ? 1 : 0 } style={{ width: '100%',  textTransform: 'none', fontWeight: '600', fontSize: breakpoint !== 'large' ? '14px' : '18px' }} variant="contained" color="primary" onClick={e => { setStep(activeStep + 1); 
                                            saveNode(); handleSaveClick(); handleSentData2(fields); setShowTestData(true);  setCustomRequestName(actionlabel === 'Custom Request' ? fields : null) }}>
                                                {loadingperform ? <CircularProgress color={colors[color]} size={25} /> : hasauth === true ? 'Continue' : 'Continue'}</Button>
                                    </div>
                                </div>


                            </StepContent>
                        </Step>
                       
                        <Step key='Test Step' disabled={action && (!hasauth || (hasauth && authname)) ? 0 : 1}>
                           { !testData ?? testData === "" ? <StepButton icon={<WarningRounded style={{color: 'orange'}} />} onClick={(e) => {saveNode(); handleSaveClick(); setStep(testStep); handleSentData2(fields); setShowTestData(true) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Test</strong></Typography><Carat active={activeStep === testStep || activeStep === finalStep ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> : <StepButton onClick={(e) => {saveNode(); handleSaveClick(); setStep(testStep); handleSentData2(fields); setShowTestData(true) }}><div><Typography style={{ fontSize: '18px', display: 'block', float: 'left' }}><strong>Test</strong></Typography><Carat active={activeStep === testStep || activeStep === finalStep ? true : false} style={{display:'block', float: 'left', fontSize: '30px'}} /></div></StepButton> }
                            <StepContent>
                                <br />
                                {icon ?
                                    <div style={{ float: 'left' }}>
                                        <img style={{ width: breakpoint === 'small' ? '36px' : '48px', height: breakpoint === 'small' ? '36px' : '48px', float: 'left' }} src='https://cdn.workload.co/wp-content/uploads/workload-icon.png' />
                                        <img style={{ width: breakpoint === 'small' ? '36px' : '48px', height: breakpoint === 'small' ? '36px' : '48px', float: 'left' }} src='https://cdn.workload.co/wp-content/uploads/connection-animated.gif' />
                                        <img style={{ width: breakpoint === 'small' ? '36px' : '48px', height: breakpoint === 'small' ? '36px' : '48px', marginRight: breakpoint === 'small' ? '4px' : '10px', float: 'left' }} src={icon} />
                                    </div>
                                    : <Avatar style={{ marginRight: '10px', float: 'left', }}>
                                        <AppsRoundedIcon />
                                    </Avatar>
                                }
                                <div style={{ display: 'block', marginBottom: '20px' }}>
                                    <Typography style={{  display: 'block', fontWeight: 'bold', fontSize: breakpoint === 'small' ? '14px' : '18px', marginTop: breakpoint === 'small' ? '10px' : '0px' }}>{actionlabel ?? action} in {label}?</Typography><br />
                                    <Typography style={{ marginTop: '-15px', fontWeight: 300, fontSize: breakpoint === 'small' ? '14px' : '16px', float: 'left' }}>This is the sample data we will send to <strong>{label}</strong>.</Typography>
                                </div>
                                <div style={{ display: 'in-line', marginTop: '20px' }}>
                                    {showTestData === true ? fields ? sentData ? 
                                    <div style={{ float: 'left', width: '100%', maxHeight: '350px', overflowX: 'hidden', overflowY: 'scroll', marginBottom: '25px' }}>
                                        {/* <Inspector data={sentData} isExpanded={(keypath, query) => true} onClick={(e) => { console.log(e) }} /> */}
                                        <DataInspector data={sentData} />
                                        </div> 
                                        : null : null : null
                                    }
                                </div>
                                <div id='actions-test' className={classes.actionsContainer}>
                                    <div>
                                        <Button onClick={e => { sendNode(node); saveNode(); handleSaveClick(); onPaneClick() }} variant="outlined" style={{ textTransform: 'none', fontSize: '18px' }}>Skip test</Button>
                                        <Button style={{marginLeft: '20px', textTransform: 'none', fontSize: '18px' }} variant="contained" color="primary" onClick={() => {setTestLoading(true); testNodeClick()  }}>Test action</Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>

                    </Stepper>

                    <div style={{ width: '90%', height: '1px' }}></div>
                    {activeStep === finalStep && (
                        <Paper square elevation={0} className={classes.resetContainer}>

                            { testLoading === true  ?
                                <Fragment>
                                    <Alert severity="info">
                                        <AlertTitle>Waiting for response data</AlertTitle>
                                        Sending a test to {actionlabel} in <strong>{label}</strong><br /><br />
                                    </Alert><CircularProgress style={{ marginLeft: '40px' }} id='circularprogresscolor' color={colors[color]} size={50} />
                                </Fragment>
                                : testData && testData.hasOwnProperty('error') || testData && testData.status_code >= 400 ? <Fragment>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        An error occurred with — <strong>{label} - {actionlabel}</strong><br /><br />
                                        Error:
                                        <DataInspector data={testData}  />
                                    </Alert>
                                </Fragment> :
                                    <Fragment>
                                        <Alert severity="success" variant="outlined" style={{borderWidth: '0px 0px 0px 6px', borderStyle: 'none none none solid', marginBottom: '16px'}}>
                                            <AlertTitle>Success</AlertTitle>
                                            A request to <strong>{actionlabel}</strong> was done in <strong>{label}</strong> about {timeSince(actionTestTime)} ago<br/> {testData.Message ? "*"+testData.Message+"*" : "" }
                                        </Alert>
                                        {/* <IconButton color="primary" style={{ color: 'black', float: 'right', borderRadius: 50, marginTop: '-10px', marginRight: '15px' }} aria-label="close modal" onClick={handlePopClick}>
                                            <SettingsEthernetRoundedIcon style={{ color: 'black' }} />
                                        </IconButton>
                                        <Popper placement="left" id={id} open={openpop} anchorEl={anchorElPop} style={{ zIndex: 99 }}>
                                            <div className={classes.popper}>
                                                <React.Fragment>
                                                    <Typography color="inherit"><h4>Inject Sample Data</h4></Typography>
                                                    
                                                    {"ADVANCED: Customize the output response (must be JSON formatted)."}<br /><br />
                                                    <TextareaAutosize defaultValue={poptext} value={poptext} onChange={(e) => setPopText(e.target.value)} style={{ width: '350px', maxWidth: '350px', height: '200px', borderColor: 'lightgray', maxHeight: '250px' }} aria-label="minimum height" minRows={40} placeholder='{"data":{&#10;"key1":"value1",&#10;"key2":"value2"&#10; }&#10;}' />
                                                    <br /><br />
                                                    <Button onClick={handlePopClose} style={{ marginRight: '5px' }}>Close</Button><Button onClick={() => { setTestData(JSON.parse(poptext)); handlePopClose() }} variant="contained" color="primary">Submit</Button>
                                                </React.Fragment>
                                            </div>
                                        </Popper> */}
                                        <div id='inspector-json' style={{ maxHeight: '350px', overflowX: 'hidden', overflowY: 'scroll' }}>
                                            <DataInspector data={testData}  />
                                        </div>
                                    </Fragment>

                            }

                            <br /><br />
                            <div id='actions-save' style={{ width: '90%', height: '1px' }}></div>
                            <Button onClick={resetForm} className={classes.button} style={{ textTransform: 'none', fontSize: '18px' }} variant="outlined">
                                Retest action
                            </Button>
                            <Button disabled={nosave} variant='contained' color='primary' onClick={() => { saveNode(); handleSaveClick(); onPaneClick() }} className={classes.button} style={{marginLeft: '20px', textTransform: 'none', fontSize: '18px' }}>
                                Continue
                            </Button>
                        </Paper>
                    )}


                    <div ref={bottomRef} />
                </div> : null
        )
    } catch (e) {
        var config = {
            method: 'post',
            url: 'https://api.airtable.com/v0/appb76LyMibAJXsfE/FrontendErrors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer key3FrNljA0k5KIGo'
            },
            data: {
                'records': [
                    {
                        'fields': {
                            'Error source': 'Action Panel',
                            'Error': e.toString()
                        }
                    }
                ]
            }
        }

        axios(config)

        onPaneClick();
    }
}

const Widget = (props) => {
    return <span>
        <img
            className="tag-img"
            src={props.icon}>
        </img>
        <b>{props.step} - {props.label}: </b><div style={{ display: 'inline', color: 'grey' }}>{props.cvalue}</div>
    </span>;
}

export default ActionPanel

