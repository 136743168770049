import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: '5px',
    display: 'flex'
    
  },
  right: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '2px'

  },
  title: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(16)
  },
  switch: {
    backgroundColor: 'gray',
    border: '1px solid',
    borderRadius: '40px',
    width: '100px',
    marginLeft: theme.spacing(15),
    alignItems: 'center',
  }
}));



export const AppBarHeaderDummy = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <AppBar position="fixed" style={{zIndex: 9, background: '#485578'}}>
        <Toolbar>
       
            <img alt="Workload Canvas Editor" style={{height: '35px',marginBottom: 4,filter: 'grayscale(100%) brightness(250%)'}} src="https://cdn.workload.co/wp-content/uploads/cropped-new-workload-logo-high-res-900-×-193-px.png"  />
        <Typography style={{marginLeft:10, marginTop:4}}>Integration Station</Typography>
          
      
      
        </Toolbar>
      
      </AppBar>
    </div>
  );
}
