import { configureStore } from '@reduxjs/toolkit'

import workflowReducer from '../features/workflow/workflowSlice'
import externalReducer from '../features/externalState/externalSlice'
import executionReducer from '../features/execution/executionSlice'
import allAppsReducer from '../features/allApps/allAppsSlice'
import notificationReducer from '../redux/ducks/notification'

export default configureStore({
  reducer: {
    workflow:workflowReducer,
    external:externalReducer,
    execution:executionReducer,
    allApps:allAppsReducer
  }
})