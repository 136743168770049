import React from "react";

const connections = () => {
    return (
        <div>
        <h3>Settings</h3>
        <small>Not really sure what to include here yet.</small>
        </div>
    );
};

export default connections;