import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const defState = {
    apps:[]
}

export const fetchAllApps = createAsyncThunk('workflow/fetchAllApps', async (id, { getState }) => {
    console.log('trying to fetch....')
    const loading = getState().allApps.loading
    if (loading == 'loading') {

        console.log('fetching list of apps...')

        var config = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'iJFqQQXezM4GAMt1r1bvB51Rbah3GyQsahdG5ePC'
            },

            url: "https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/apps/"
        }
        const resp = await axios(config)
       // console.log(resp)
        return {
            apps:resp.data
        }
    } else {
        console.log('loading != unloaded')
      //  console.log(loading)
        return
    }
})

const allAppsSlice = createSlice({
    name: 'allApps',
    initialState: defState,
    reducers: {

    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllApps.pending, (state, action) => {
                state.loading = 'loading'
            })
            .addCase(fetchAllApps.fulfilled, (state, action) => {
                if (state.loading == 'loading') {
                    state.loading = 'loaded'
                    state.apps = action.payload.apps
                }
            })
            .addCase(fetchAllApps.rejected, (state, action) => {
                state.loading = 'failedLoad'
            })
    }
})

export const selectApps = state => state.allApps.apps
export const selectAllActions = createSelector([selectApps], appList => {
    return appList.filter(item => item.action)
})
export const selectAllTriggers = createSelector([selectApps], appList => {
    return appList.filter(item => item.trigger)
})

export default allAppsSlice.reducer
