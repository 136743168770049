import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { createApi} from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const defaultState = {
    scaffoldToken:''
}

const externalSlice = createSlice({
    name: 'external',
    intialState: defaultState,
    reducers:{
        tokenUpdated:{
            reducer(state, action){
                state.scaffoldToken = action.payload
            },
            prepare(token){
                return {
                    payload:token
                }
            }
        }
    },
    extraReducers: builder => {
        builder.addDefaultCase((state=defaultState, action)=> state)
    }
})

export default externalSlice.reducer

export const { tokenUpdated } = externalSlice.actions

export const selectToken = state => state.external.scaffoldToken
