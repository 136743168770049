import Form from '@rjsf/material-ui'
import React, { useState, Fragment, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Button, Fab,MenuItem, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Tooltip, Divider, List, ListItem, ListItemText, Typography, Grid, Paper, Box, TextField, Stepper, Step, StepButton, StepContent, IconButton, Menu, Select, InputLabel, Chip, Input, FormControl } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import axios from 'axios';
import { AppBarHeaderDummy } from "../components/AppBarIntegrator";
import { makeStyles } from '@material-ui/core/styles';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Controlled as CodeMirror2 } from 'react-codemirror2';
import 'codemirror/theme/darcula.css';

import { BuildRounded, ExpandMoreRounded, OfflineBoltRounded, SettingsRounded, SpellcheckRounded, VpnKeyRounded } from '@material-ui/icons';
require('codemirror/mode/python/python')


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function slugify(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

Function.deserialise = function (key, data) {
    return (data instanceof Array && data[0] == 'window.Function') ?
        new (Function.bind.apply(Function, [Function].concat(data[1], [data[2]]))) :
        data
        ;
};

Function.prototype.toJSON = function () {
    var whitespace = /\s/;
    var pair = /\(\)|\[\]|\{\}/;

    var args = new Array();
    var string = this.toString();

    var fat = (new RegExp(
        '^\s*(' +
        ((this.name) ? this.name + '|' : '') +
        'function' +
        ')[^)]*\\('
    )).test(string);

    var state = 'start';
    var depth = new Array();
    var tmp;

    for (var index = 0; index < string.length; ++index) {
        var ch = string[index];

        switch (state) {
            case 'start':
                if (whitespace.test(ch) || (fat && ch != '('))
                    continue;

                if (ch == '(') {
                    state = 'arg';
                    tmp = index + 1;
                }
                else {
                    state = 'singleArg';
                    tmp = index;
                }
                break;

            case 'arg':
            case 'singleArg':
                var escaped = depth.length > 0 && depth[depth.length - 1] == '\\';
                if (escaped) {
                    depth.pop();
                    continue;
                }
                if (whitespace.test(ch))
                    continue;

                switch (ch) {
                    case '\\':
                        depth.push(ch);
                        break;

                    case ']':
                    case '}':
                    case ')':
                        if (depth.length > 0) {
                            if (pair.test(depth[depth.length - 1] + ch))
                                depth.pop();
                            continue;
                        }
                        if (state == 'singleArg')
                            throw '';
                        args.push(string.substring(tmp, index).trim());
                        state = (fat) ? 'body' : 'arrow';
                        break;

                    case ',':
                        if (depth.length > 0)
                            continue;
                        if (state == 'singleArg')
                            throw '';
                        args.push(string.substring(tmp, index).trim());
                        tmp = index + 1;
                        break;

                    case '>':
                        if (depth.length > 0)
                            continue;
                        if (string[index - 1] != '=')
                            continue;
                        if (state == 'arg')
                            throw '';
                        args.push(string.substring(tmp, index - 1).trim());
                        state = 'body';
                        break;

                    case '{':
                    case '[':
                    case '(':
                        if (
                            depth.length < 1 ||
                            !(depth[depth.length - 1] == '"' || depth[depth.length - 1] == '\'')
                        )
                            depth.push(ch);
                        break;

                    case '"':
                        if (depth.length < 1)
                            depth.push(ch);
                        else if (depth[depth.length - 1] == '"')
                            depth.pop();
                        break;
                    case '\'':
                        if (depth.length < 1)
                            depth.push(ch);
                        else if (depth[depth.length - 1] == '\'')
                            depth.pop();
                        break;
                }
                break;

            case 'arrow':
                if (whitespace.test(ch))
                    continue;
                if (ch != '=')
                    throw '';
                if (string[++index] != '>')
                    throw '';
                state = 'body';
                break;

            case 'body':
                if (whitespace.test(ch))
                    continue;
                string = string.substring(index);

                if (ch == '{')
                    string = string.replace(/^{\s*(.*)\s*}\s*$/, '$1');
                else
                    string = 'return ' + string.trim();

                index = string.length;
                break;

            default:
                throw '';
        }
    }

    return ['window.Function', args, string];
};

const useStyles = makeStyles((theme) => ({
    root: {
       display: 'flex',
        paddingRight: '12px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.default,
        paddingRight: 20,
        marginTop: 50
    },
    fab: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 9
    },
}));



export default () => {
    const classes = useStyles();

    const [allApps, setAllApps] = useState([])
    const [loadingAllApps, setLoadingAllApps] = useState(false)
    const [alert, setAlert] = useState();
    const [appInfo, setAppInfo] = useState({ actions: {}, triggers: {}, authInfo: {} })
    const [loadingApp, setLoadingApp] = useState(false)

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)
    const [formType, setFormType] = useState(null)
    const [selectedObject, setSelectedObject] = useState(null)

   
 
    const createAppDB = (newInfo) => {
        axios({
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/',
            data: newInfo
        }).then(resp => { setSaving(false);history.push("/develop/"+newInfo.appSlug)  })
    }

    const createApp = () => {
         var appData = {
            appSlug: slugify(seoValues.name),
            appName: seoValues.name,
            category: seoValues.category,
            group: "",
            lambdaURL: "https://odkusrnpx8.execute-api.us-west-2.amazonaws.com/production/apps/"+slugify(seoValues.name),
            iconURL: seoValues.icon,
            actions: {},
            triggers: {},
            authInfo: {},
            disableAuth: false,
            apiDocs: "",
            completionStatus: "In Progress"
         }
      
        createAppDB(appData)
    }

    const makeNewKey = (dictOfKeys, testString, num = 0) => {
        if (Object.keys(dictOfKeys).includes(testString)) {
            return makeNewKey(dictOfKeys, testString.slice(0, testString.length - 1) + num, num + 1)
        } else {
            return testString
        }
    }

   
    const history = useHistory()
   
    const [seoApp, setSeoApp] = useState(null)
    const [seoValues, setSeoValues] = useState(seoApp ?? {})

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    
   

    const handleSubmitSeoValues = () => {
        setSaving(true)
        var appData = {
            app: slugify(seoValues.name),
            name: seoValues.name,
            category: seoValues.category,
            formDataEndpoint: "https://odkusrnpx8.execute-api.us-west-2.amazonaws.com/production/apps/"+slugify(seoValues.name),
            icon: seoValues.icon,
            description: seoValues.description,
            status: "Draft",
            group: ""
         }
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/seo',
            data: JSON.stringify(appData)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" }); })
   
    }

    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <AppBarHeaderDummy />
            <div className={classes.root}>
                <Grid container spacing={2}>
                    
                    
                    <Grid item xs={12} >
                    <Paper className={classes.paper}> 
                    <Typography ><h1>Create Integration</h1></Typography>
                    <InputLabel><Typography variant="h6">* Integration Name (required)</Typography></InputLabel>
                    <TextField
                    style={{width: '100%'}}
                        id="outlined-helperText"
                        //label="* Integration Name (required)"
                        defaultValue={seoApp && seoApp.name}
                        value={seoValues.name ?? seoApp?.name}
                        onChange={(e) => setSeoValues({...seoValues, name: e.target.value})}
                        helperText="The name of your integration as it is displayed on the Workload integrations page."
                        variant="outlined"
                        />  <br/><br/><br/>
                     <InputLabel><Typography variant="h6">* Integration Description (required)</Typography></InputLabel>
                    <TextField
                    style={{width: '100%'}}
                        id="outlined-helperText"
                        defaultValue={seoApp && seoApp.description}
                        value={seoValues.description ?? seoApp?.description}
                        onChange={(e) => setSeoValues({...seoValues, description: e.target.value})}
                        helperText="Maximum 160 chars. Ex: 'Trello is a tream collaboration tool to organize tasks and keep projects on track.'"
                        variant="outlined"
                        multiline
                        rows={3}
                        />  <br/><br/><br/>
                    <InputLabel><Typography variant="h6">* Integration homepage URL (required)</Typography></InputLabel>
                     <TextField
                     style={{width: '100%'}}
                        id="outlined-helperText"
                        defaultValue={seoApp && seoApp.homepage}
                        value={seoValues.homepage ?? seoApp?.homepage}
                        onChange={(e) => setSeoValues({...seoValues,homepage: e.target.value})}
                        helperText=""
                        variant="outlined"
                        />  <br/><br/><br/>
                        <Box display="flex">
                            <Box component="img"
                            src={seoApp && seoApp.icon}
                            flex="10%"
                            style={{maxHeight: '75px', maxWidth: '75px', justifyContent: 'center', marginTop: '25px', marginRight: '12px'}}
                             />
                            <Box flex="70%">
                    <InputLabel><Typography variant="h6">* App Logo (required)</Typography></InputLabel>
                     <TextField
                     style={{width: '100%'}}
                        id="outlined-helperText"
                        defaultValue={seoApp && seoApp.icon}
                        value={seoValues.icon ?? seoApp?.icon}
                        onChange={(e) => setSeoValues({...seoValues,icon: e.target.value})}
                        helperText="Upload a square, 256x256px or larger transparent PNG image that does not include the app name. "
                        variant="outlined"
                        /> </Box></Box> <br/><br/><br/>
                     <InputLabel><Typography variant="h6">* App Category (required)</Typography></InputLabel>
                     <TextField
                     style={{width: '100%'}}
                        id="outlined-helperText"
                        
                        defaultValue={seoApp && seoApp.category}
                        value={seoValues.category ?? seoApp?.category}
                        onChange={(e) => setSeoValues({...seoValues,category: e.target.value})}
                        helperText="Choose the most appropriate option for your app’s core features."
                        variant="outlined"
                        />  <br/><br/><br/>
                      
                    <InputLabel><Typography variant="h6">* Publish Status (required)</Typography></InputLabel>
                    <TextField
                    select
                     style={{width: '100%'}}
                        id="outlined-helperText"
                        //defaultValue={seoApp && seoApp.status}
                        //value={seoValues.status ?? seoApp?.status}
                        value={""}
                        onChange={(e) => setSeoValues({...seoValues,status: e.target.value})}
                        helperText="Choose the most appropriate option for your app’s core features."
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                seoValues.status ?? seoApp?.status ?
                              <Chip label={seoValues.status ?? seoApp?.status} color="primary" /> : null
                            ),
                          }}
                        > 
                        <MenuItem key={"published"} value={"Published"} >Published</MenuItem>
                        <MenuItem key={"beta"} value={"Beta"} >Beta</MenuItem>
                        <MenuItem key={"coming-soon"} value={"Coming Soon"} >Coming Soon</MenuItem>
                        </TextField>
                        <br/><br/><br/>
                        <div style={{marginRight: '0px', padding: '20px'}}>
                        <Button onClick={()=> { handleSubmitSeoValues(); createApp()}} size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
                        <Button style={{float: 'right', marginRight: '24px'}} onClick={() => history.push("/develop")} size="large" variant="outlined" color="primary" >Cancel</Button>
            </div><div style={{marginRight: '40px', width: '100%', padding: '20px'}}></div>
                    </Paper>
                    </Grid>
                     

                    
                    
                    
                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alert ? alert.severity : null}>
                            {alert ? alert.message : null}
                        </Alert>
                    </Snackbar>
                   
                </Grid>
            </div></div>

    )
}
