import React from "react";

const workflows = () => {
    return (
        <div>
        <h3>Workflows</h3>
        <small>See a list of workflows. Edit existing Workflows.</small>
        </div>
    );
};

export default workflows;