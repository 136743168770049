import React from "react";

const support = () => {
    return (
        <div>
        <h3>Workflow Editor</h3>
        <small>Step by Step workflow builder tool goes here.</small>
        </div>
    );
};

export default support;