import React from 'react';
import { useDispatch } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { getBezierPath, getMarkerEnd, getEdgeCenter, getSmoothStepPath, EdgeText } from 'react-flow-renderer';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';

import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import LinkOffRoundedIcon from '@material-ui/icons/LinkOffRounded';
import TimerIcon from '@material-ui/icons/Timer';
import {
  elementDeleted, elementSelected
} from '../../features/workflow/workflowSlice'
import { Tooltip, ClickAwayListener, Popper, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  popper: {
    border: '1px solid lightgray',
    borderRadius: '4px',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    zIndex: 10,
 
    boxShadow: '0 5px 5px 5px lightgray',
    width: '200px'
}
}));



export default function CustomEdge({
  id,
  onElementClick,
  onPaneClick,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  borderRadius = 5,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [nodrag, setNoDrag] = React.useState(false)
  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    
    <Popper className={classes.popper}
    elevation={0}
    placement='right-start'
    {...props}
  />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  const showFilter = (id) => {
    closeDropdown();
    dispatch(elementSelected(id));
  }

  /* const showDelay = (id) => {
    closeDropdown();
    dispatch(elementSelected(id));
  } */

  const deleteEdge = (id) => {
    closeDropdown();
    dispatch(elementDeleted(id))
  }

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true)
    setNoDrag(true)
  };

  const closeDropdown = () => {
    setAnchorEl(null);
    setOpen(false)
    setNoDrag(false)
  };
  const edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, borderRadius });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const onEdgeClick = (evt, id) => {
   // evt.stopPropagation();
    // alert(`remove ${id}`);
   // console.log('evt is: ', evt)
   // console.log('id is: ', id)
  //  dispatch(filterclick(id));
  };

//   const handleElementClick = (event, el) => {
//     // handlePaneClick();
//     //  console.log('element ' + JSON.stringify(el.id) + ' clicked')
//     setSelectedElement(null)
//     setSelectedElement(elements.find(item => item.id == el.id))
//     dispatch(elementSelected(el.id))
//     mixpanel.track('Node Selected', {
//         'app': el.app,
//         'user': user
//     });
// }
  return (
    <>
      <path id={id} className="react-flow__edge-path nodrag" style={style} d={edgePath} markerEnd={markerEnd}/>
      
      <EdgeText
        x={edgeCenterX}
        y={edgeCenterY - 28}
        label={data.fields[0].value === 'empty' ? '' : data.fields[0].value}
        labelStyle={{ fill: 'white' }}
        labelShowBg
        labelBgStyle={{ fill: 'black' }}
        labelBgPadding={[4, 4]}
        labelBgBorderRadius={2}
/>;
      
      <Tooltip title="Tools" placement="top">
      <>
      <ClickAwayListener onClickAway={closeDropdown}>
      <foreignObject
        id='toolbox'
        
        style={{ cursor: 'pointer', backgroundColor: 'whitesmoke', borderRadius: '50px', border: '1px solid black', padding: '0px 0px 2px 0px' }}
        width={25}
        height={25}
        x={edgeCenterX - 26 / 2}
        y={edgeCenterY - 15 / 1}
        className={nodrag === true ? "nodrag" : "edgebutton-foreignobject"}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
      
        <div>
        
        <BuildRoundedIcon className="nodrag" style={{fontSize: '16px', padding: '3px 0px 0px 3px'}} onClick={handleClick} />
        <StyledMenu 
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeDropdown}
       
      >
        <StyledMenuItem className="nodrag" onClick={() => showFilter(id)} >
          <ListItemIcon>
            <TuneRoundedIcon fontSize="small" color='primary'  />
          </ListItemIcon>
          <ListItemText primary="Filter/Delay" />
        </StyledMenuItem>
        {/* <StyledMenuItem className="nodrag" onClick={() => showDelay(id)} >
          <ListItemIcon>
            <TimerIcon fontSize="small" color='primary'  />
          </ListItemIcon>
          <ListItemText primary="Delay" />
        </StyledMenuItem> */}
        <StyledMenuItem className="nodrag" onClick={() => deleteEdge(id)}>
          <ListItemIcon >
            <LinkOffRoundedIcon  fontSize="small" color='primary' />
          </ListItemIcon>
          <ListItemText primary="Unlink" />
        </StyledMenuItem>
      </StyledMenu>
        </div>
      </foreignObject>
      </ClickAwayListener>
      </>
      </Tooltip>
    </>
  );
}